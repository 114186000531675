export enum CompanyType {
  Customer = 'customer',
  Owner = 'owner'
}

export type Company = {
  id: number
  account_number?: string
  address: string
  agreement_number: string
  color?: string
  company_code?: string
  name: string
  type: CompanyType
  vat_number: string
  created_at: string
  from_service: boolean
}

export type CompanyRepresentative = {
  name: string
  position: string
}

export type CompaniesForSelectResponse = Array<{
  id: number
  name: string
}>
