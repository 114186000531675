import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { ColumnProps } from 'antd/lib/table'
import { Tag } from 'antd'
import { TableFiltersType } from '@src/types/tableFilters'
import { mailLogsApi } from '@src/store/services/mailLogs-service'
import { MailLogsType, MAIL_LOGS_STATUS, DOCUMENT_TYPE } from '@src/types/mailLogs'
import moment from 'moment'
import { PageTable } from '@src/components/Table/PageTable'

const statusColor: any = {
  [MAIL_LOGS_STATUS.SENDING]: 'yellow',
  [MAIL_LOGS_STATUS.SUCCESS]: 'green',
  [MAIL_LOGS_STATUS.FAILED]: 'red',
}

export const MailLogs = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : undefined,
  })
  const { data, isLoading } = mailLogsApi.useGetMailLogsListQuery({
    ...paramsFilters, filters: paramsFilters.filters && JSON.stringify(paramsFilters.filters),
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const columns = useMemo<ColumnProps<MailLogsType>[]>(() => [
    {
      title: t('invoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 80,
      align: 'center',
      render: (_, record, index) => (index + 1 + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
    },
    {
      title: t('mailLogs.columns.documentType'),
      dataIndex: 'document_type',
      key: 'document_type',
      width: 260,
      align: 'center',
    },
    {
      title: t('mailLogs.columns.customer'),
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 260,
      align: 'center',
    },
    {
      title: t('mailLogs.columns.customerEmail'),
      dataIndex: 'customer_email',
      key: 'customer_email',
      width: 260,
      align: 'center',
    },
    {
      title: t('mailLogs.columns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 260,
      align: 'center',
      render: (status) => (
        <Tag color={statusColor[status]}>{t(`mailLogs.status.${status}`)}</Tag>
      ),
    },
    {
      title: t('mailLogs.columns.date'),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 260,
      align: 'center',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD'),
    },
  ], [data?.meta.pageSize, paramsFilters.page, t])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'document_type',
      placeholder: t('mailLogs.columns.documentType'),
      width: '200px',
      options: [
        {
          value: DOCUMENT_TYPE.AGREEMENT,
          label: t(`mailLogs.documentType.${DOCUMENT_TYPE.AGREEMENT}`),
        },
        {
          value: DOCUMENT_TYPE.AGREEMENT_INVOICE,
          label: t(`mailLogs.documentType.${DOCUMENT_TYPE.AGREEMENT_INVOICE}`),
        },
        {
          value: DOCUMENT_TYPE.AGREEMENT_BILL,
          label: t(`mailLogs.documentType.${DOCUMENT_TYPE.AGREEMENT_BILL}`),
        },
        {
          value: DOCUMENT_TYPE.AGREEMENT_CREATED,
          label: t(`mailLogs.documentType.${DOCUMENT_TYPE.AGREEMENT_CREATED}`),
        },
        {
          value: DOCUMENT_TYPE.BUSINESS_INVOICE,
          label: t(`mailLogs.documentType.${DOCUMENT_TYPE.BUSINESS_INVOICE}`),
        },
        {
          value: DOCUMENT_TYPE.DEBT,
          label: t(`mailLogs.documentType.${DOCUMENT_TYPE.DEBT}`),
        },
        {
          value: DOCUMENT_TYPE.TRANSPORTATION_BILL,
          label: t(`mailLogs.documentType.${DOCUMENT_TYPE.TRANSPORTATION_BILL}`),
        },
      ],
    },
    {
      type: 'input',
      dataIndex: 'customer_name',
      placeholder: t('mailLogs.columns.customer'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'status',
      placeholder: t('mailLogs.columns.status'),
      width: '200px',
      options: [
        {
          value: MAIL_LOGS_STATUS.FAILED,
          label: t(`mailLogs.status.${MAIL_LOGS_STATUS.FAILED}`),
        },
        {
          value: MAIL_LOGS_STATUS.SUCCESS,
          label: t(`mailLogs.status.${MAIL_LOGS_STATUS.SUCCESS}`),
        },
        {
          value: MAIL_LOGS_STATUS.SENDING,
          label: t(`mailLogs.status.${MAIL_LOGS_STATUS.SENDING}`),
        },
      ],
    },
  ], [t])

  return (
    <div>
      <PageTable
        title={t('mailLogs.title')}
        setParamsFilters={setParamsFilters}
        filtersList={filtersList}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
    </div>
  )
}
