import { FC, useMemo } from 'react'
import { Avatar, Button, Layout, Popover, theme } from 'antd'
import { MenuFoldOutlined, MenuOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { setCookie } from '@src/lib/cookie'
import { useWindowSize } from '@src/hooks/useWindowSize'
import { authApi } from '@src/store/services/auth-service'
import style from './Header.module.scss'
import ltLogo from '../../assets/lt.png'
import ukLogo from '../../assets/uk.png'

type HeaderProps = {
  setCollapsed: (value: boolean) => void
  collapsed: boolean
  setIsOpenMenu: () => void
}

export const Header: FC<HeaderProps> = ({ setCollapsed, collapsed, setIsOpenMenu }) => {
  const { width } = useWindowSize()
  const { token: { colorBgContainer } } = theme.useToken()
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [logout] = authApi.useLogoutMutation()

  function onLangChange(lang: string) {
    return () => {
      i18n.changeLanguage(lang)
      setCookie('lang', lang)
    }
  }

  const icon = useMemo(() => {
    if (width > 1024) {
      return collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
    }

    return <MenuOutlined />
  }, [collapsed, width])

  const onClickIcon = () => {
    if (width > 1024) {
      setCollapsed(!collapsed)
    }

    setIsOpenMenu()
  }

  const handleLogout = async () => {
    await logout()
    localStorage.removeItem('token')
    dispatch(authApi.util.resetApiState())
  }

  return (
    <Layout.Header style={{ background: colorBgContainer }} className={style.header}>
      <Button
        type="text"
        icon={icon}
        onClick={onClickIcon}
        style={{
          fontSize: '16px',
          width: 64,
          height: 64,
        }}
      />
      <div className={style.actions}>
        <Popover
          content={(
            <div className={style.lang}>
              <Button className={style.langBtn} type="text" onClick={onLangChange('lt')}>
                <img className={style.logoLang} src={ltLogo} alt="ltLang" />
                {t('lang.lt')}
              </Button>
              <Button className={style.langBtn} type="text" onClick={onLangChange('en')}>
                <img className={style.logoLang} src={ukLogo} alt="ukLang" />
                {t('lang.en')}
              </Button>
            </div>
          )}
          trigger="click"
        >
          <Avatar size={24} src={<img src={i18n.language === 'lt' ? ltLogo : ukLogo} alt="avatar" />} />
        </Popover>
        <Popover
          content={(
            <div>
              <Button type="text" onClick={handleLogout}>{t('menu.logout')}</Button>
            </div>
          )}
          trigger="click"
        >
          <Avatar size={40}>USER</Avatar>
        </Popover>
      </div>
    </Layout.Header>
  )
}
