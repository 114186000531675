import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { debtsApi } from '@src/store/services/debts-service'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { DebtsType } from '@src/types/debts'
import { ColumnProps } from 'antd/lib/table'
import { Popconfirm, Tag } from 'antd'
import { DeleteOutlined, MailOutlined } from '@ant-design/icons'
import { TableFiltersType } from '@src/types/tableFilters'
import cn from 'classnames'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { PageTable } from '@src/components/Table/PageTable'
import { isSelectionText } from '@src/lib/utils'
import style from './debts.module.scss'

type DebtsActionsProps = {
  record: DebtsType
}

export const DebtsActions: FC<DebtsActionsProps> = ({ record }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [remove, { isLoading: isLoadingRemove }] = debtsApi.useRemoveMutation()

  return (
    <div className={style.actions}>
      <button
        type="button"
        onClick={() => {
          dispatch(setSendEmail({
            type: SendEmailType.Debts,
            companyId: record.company_id,
            id: record.id,
          }))
        }}
        className={style.actions_item}
      >
        <MailOutlined />
      </button>

      <Popconfirm
        placement="left"
        title={t('popconfirm.deleteTitle')}
        onConfirm={() => {
          remove(record.id)
        }}
        okText={t('popconfirm.okText')}
        cancelText={t('popconfirm.cancelText')}
        disabled={isLoadingRemove}
      >
        <button
          type="button"
          className={cn(style.actions_item, style.actions_item__delete)}
          disabled={isLoadingRemove}
        >
          <DeleteOutlined />
        </button>
      </Popconfirm>
    </div>
  )
}

export const Debts = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const { data: customerNamesList } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : undefined,
  })
  const { data, isLoading } = debtsApi.useGetListQuery({
    ...paramsFilters, filters: paramsFilters.filters && JSON.stringify(paramsFilters.filters),
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCellMailHandler = useCallback((record: DebtsType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        dispatch(setSendEmail({
          type: SendEmailType.Debts,
          companyId: record.company_id,
          id: record.id,
        }))
      }
    },
  }), [dispatch])

  const onCell = useCallback((record: DebtsType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/debts/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const columns = useMemo<ColumnProps<DebtsType>[]>(() => [
    {
      title: t('invoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1 + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('invoices.columns.company'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('invoices.columns.sum'),
      dataIndex: 'sum',
      key: 'sum',
      render: (sum) => `${sum}€`,
      onCell,
    },
    {
      title: t('invoices.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      className: style.sentCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: onCellMailHandler,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <DebtsActions record={record} />
      ),
    },
  ], [data?.meta.pageSize, onCell, onCellMailHandler, paramsFilters.page, t])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'customer_id',
      placeholder: t('agreements.columns.customer'),
      width: '200px',
      options: customerNamesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'isSentCustomer',
      placeholder: t('debts.columns.sentCustomer'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
  ], [customerNamesList, t])

  return (
    <div>
      <PageTable
        title={t('debts.title')}
        createLink="/debts/create"
        setParamsFilters={setParamsFilters}
        filtersList={filtersList}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
        }}
      />
    </div>
  )
}
