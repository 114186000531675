import React, { FC, useState, forwardRef } from 'react'
import { Modal } from 'antd'
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons'
import cx from 'classnames'
import styles from '@src/components/ImagesPreview/Preview.module.scss'

type ImageProps = {
  url: string
  thumb: string
  style?: React.CSSProperties
  className?: any
  attributes?: any
  listeners?: any
  onImageDelete?: () => void
  ref?: any
}

export const Image: FC<ImageProps> = forwardRef(({ url, thumb, style, className, attributes, listeners, onImageDelete }, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  const title = url.substring(url.lastIndexOf('/') + 1)

  return (
    <>
      <div ref={ref} style={style} className={cx(styles.imgContainer, className)} {...attributes} {...listeners}>
        <div className={styles.actions}>
          <button type="button" onClick={() => setIsOpen(true)}>
            <EyeOutlined className={styles.icon} />
          </button>
          {onImageDelete && (
            <button type="button" onClick={onImageDelete}>
              <DeleteOutlined className={styles.icon} />
            </button>
          )}
        </div>
        <img src={thumb} alt="img" className={styles.img} />
      </div>
      <Modal
        open={isOpen}
        title={title}
        footer={null}
        width="70vw"
        centered
        onCancel={() => {
          setIsOpen(false)
        }}
      >
        <img src={url} alt="example" style={{ width: '100%' }} />
      </Modal>
    </>
  )
})
