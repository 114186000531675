import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from '@src/types/users'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['User'],
  endpoints: (build) => ({
    login: build.mutation<{token: string}, {email: string, password: string}>({
      query: (body) => ({
        url: '/auth/login',
        method: 'POST',
        body,
      }),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/logout',
        method: 'POST',
      }),
    }),
    getMe: build.query<User, void>({
      query: () => ({
        url: '/me',
      }),
      providesTags: () => ['User'],
    }),
  }),
})
