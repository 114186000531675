import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { DetailShortType, DetailTransferRequest, DetailType, UserDetailType } from '@src/types/details'
import { formatFilters } from '@src/lib/formatFilters'

export const detailsApi = createApi({
  reducerPath: 'detailsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Details'],
  endpoints: (build) => ({
    getDetailsList: build.query<GetItemsType<DetailType>, { page: number, filters?: Record<string, any> }>({
      query: (params) => ({
        url: `/service/details?${formatFilters(params)}`,
      }),
      providesTags: () => ['Details'],
    }),
    getDetailsListForSelect: build.query<DetailShortType[], void>({
      query: () => ({
        url: '/details/short',
      }),
      providesTags: () => ['Details'],
    }),
    deleteDetail: build.mutation<void, number>({
      query: (id) => ({
        url: `/service/details/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Details'],
    }),
    exportDetails: build.mutation<void, number[]>({
      query: (detailsIds) => ({
        url: '/service/details/excel-export',
        method: 'POST',
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        body: {
          detailsIds,
        },
      }),
    }),
    getOneDetail: build.query<DetailType, number | undefined>({
      query: (id) => ({
        url: `/service/details/${id}`,
      }),
      providesTags: () => ['Details'],
    }),
    createDetail: build.mutation<DetailType, any>({
      query: (body) => ({
        url: '/service/details',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    editDetail: build.mutation<DetailType, any>({
      query: ({ id, formData }) => ({
        url: `/service/details/${id}?_method=PUT`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Details'],
    }),
    createDetailTransferRequest: build.mutation<DetailType, any>({
      query: (body) => ({
        url: '/service/details-storage/requests',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    returnDetail: build.mutation<DetailType, any>({
      query: (body) => ({
        url: '/service/details-storage/put',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    takeDetail: build.mutation<DetailType, any>({
      query: (body) => ({
        url: '/service/details-storage/take',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    putDetail: build.mutation<DetailType, any>({
      query: (body) => ({
        url: '/service/details-storage/put',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    changeDetailCount: build.mutation<DetailType, any>({
      query: (body) => ({
        url: '/service/details-storage/change-count',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    acceptDetailTransferRequest: build.mutation<DetailType, any>({
      query: (id) => ({
        url: `/service/details-storage/requests/${id}/accept?_method=PUT`,
        method: 'POST',
      }),
      invalidatesTags: ['Details'],
    }),
    rejectDetailTransferRequest: build.mutation<DetailType, any>({
      query: (id) => ({
        url: `/service/details-storage/requests/${id}/reject?_method=PUT`,
        method: 'POST',
      }),
      invalidatesTags: ['Details'],
    }),
    getDetailsRequests: build.query<GetItemsType<DetailTransferRequest>, { page: number }>({
      query: (params) => ({
        url: `/service/details-storage/requests?${formatFilters(params)}`,
      }),
    }),
    getUserDetailsList: build.query<GetItemsType<UserDetailType>, { id: number | undefined, page: number, filters?: Record<string, any> }>({
      query: ({ id, ...params }) => ({
        url: `/service/details-storage/user/${id}/get-details?${formatFilters(params)}`,
      }),
      providesTags: () => ['Details'],
    }),
    getUserDetailsListForSelect: build.query<UserDetailType[], { id: number | undefined }>({
      query: ({ id }) => ({
        url: `/service/details-storage/user/${id}/get-details-short`,
      }),
      providesTags: () => ['Details'],
    }),
    deleteDetailsImage: build.mutation<void, number>({
      query: (id) => ({
        url: `/service/detail-images/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Details'],
    }),

    // todo add types
    getDetailInvoiceList: build.query<any, any>({
      query: () => ({
        url: '/detail-invoice',
      }),
      providesTags: () => ['Details'],
    }),
    getOneDetailInvoice: build.query<any, number>({
      query: (id) => ({
        url: `/detail-invoice/${id}`,
      }),
      providesTags: () => ['Details'],
    }),
    createDetailInvoice: build.mutation<void, any>({
      query: (body) => ({
        url: '/detail-invoice',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    updateDetailInvoice: build.mutation<void, any>({
      query: (body) => ({
        url: `/detail-invoice/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Details'],
    }),
    deleteDetailInvoice: build.mutation<void, number>({
      query: (id) => ({
        url: `/detail-invoice/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Details'],
    }),
    generateDocumentDetailInvoice: build.mutation<void, number>({
      query: (id) => ({
        url: `/generate/detail-invoice/${id}`,
        method: 'GET',
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
      }),
    }),
  }),
})
