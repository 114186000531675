import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions, Drawer } from 'antd'
import { companiesApi } from '@src/store/services/companies-service'
import { Preloader } from '@src/components/Preloader/Preloader'

type CustomerInfoProps = {
  isOpen: boolean,
  onClose: ()=>void,
  customerId:number
}
// todo Добавить список рабочих
export const CustomerInfo:FC<CustomerInfoProps> = ({
  isOpen,
  onClose,
  customerId,
}) => {
  const { t } = useTranslation()
  const { data: customer, isLoading: isLoadingGetOne } = companiesApi.useGetOneCompanyQuery(customerId || 0, { skip: !customerId || !isOpen })

  return (
    <Drawer
      title={t('customerCreate.close')}
      open={isOpen}
      onClose={onClose}
      width={1000}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {customer && (
        <Preloader loading={isLoadingGetOne}>
          <Descriptions title={t('customerCreate.info')}>
            <Descriptions.Item label={t('customerCreate.name')}>{customer?.name}</Descriptions.Item>
            <Descriptions.Item label={t('customerCreate.code')}>{customer?.company_code}</Descriptions.Item>
            <Descriptions.Item label={t('customerCreate.vatNumber')}>{customer?.vat_number}</Descriptions.Item>
            <Descriptions.Item label={t('customerCreate.address')}>{customer?.address}</Descriptions.Item>
          </Descriptions>
        </Preloader>
      )}
    </Drawer>
  )
}
