import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'antd'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import * as Yup from 'yup'
import moment from 'moment'
import { agreementsApi } from '@src/store/services/agreements-service'

type AgreementsPausesCreateProps = {
  onClose: () => void
  agreementId: number
  editId?: number | null
}

export const AgreementsPausesCreate: FC<AgreementsPausesCreateProps> = ({ onClose, agreementId, editId }) => {
  const { t } = useTranslation()
  const [create, { isLoading: isLoadingCreate }] = agreementsApi.useCreateAgreementPauseMutation()
  const [update, { isLoading: isLoadingUpdate }] = agreementsApi.useEditAgreementPauseMutation()
  const { data } = agreementsApi.useGetOneAgreementPauseQuery(editId!, { skip: !editId })

  const validationSchema = useMemo(() => Yup.object().shape({
    date_from: Yup.string().required(t('form.errors.required') ?? ''),
    date_to: Yup.string().required(t('form.errors.required') ?? ''),
  }), [t])

  const initialValues = useMemo(() => ({
    agreement_id: agreementId,
    date_from: data?.date_from ? moment(data.date_from) : moment(),
    date_to: data?.date_to ? moment(data.date_to) : '',
  }), [agreementId, data])

  const onSubmit = async (values: any) => {
    let result

    if (editId) {
      result = await update({
        ...values,
        id: editId,
        date_from: values.date_from.format('YYYY-MM-DD'),
        date_to: values.date_to.format('YYYY-MM-DD'),
      })
    } else {
      result = await create({
        ...values,
        date_from: values.date_from.format('YYYY-MM-DD'),
        date_to: values.date_to.format('YYYY-MM-DD'),
      })
    }

    if ('data' in result) {
      onClose()
    }
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        <Form>
          <Row>
            <Col span={24}>
              <DatePickerFormik name="date_from" placeholder={t('agreementsPauses.dateStart')} />
            </Col>
            <Col span={24}>
              <DatePickerFormik name="date_to" placeholder={t('agreementsPauses.dateEnd')} />
            </Col>
          </Row>
          <Button type="primary" htmlType="submit" loading={isLoadingCreate || isLoadingUpdate}>
            {t('agreementsPauses.save')}
          </Button>
        </Form>
      </Formik>
    </div>
  )
}
