import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { PageTable } from '@src/components/Table/PageTable'
import useQuery from '@src/hooks/useQuery'
import style from '@src/Pages/Cars/cars.module.scss'
import { TaskType } from '@src/types/tasks'
import { trucksApi } from '@src/store/services/trucks-service'
import { isSelectionText } from '@src/lib/utils'

export const Trucks = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const { data, isLoading } = trucksApi.useGetTrucksListQuery(paramsFilters)
  const [deleteTrawl, { isLoading: isLoadingDeleteTrawl }] = trucksApi.useDeleteTruckMutation()
  const [exportDetails, { isLoading: isLoadingExport }] = trucksApi.useExportTrucksMutation()

  const onCell = useCallback((record: TaskType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/trucks/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<TaskType>[]>(() => [
    {
      title: t('trucks.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('trucks.columns.make'),
      dataIndex: 'make',
      key: 'make',
      onCell,
    },
    {
      title: t('trucks.columns.model'),
      dataIndex: 'model',
      key: 'model',
      onCell,
    },
    {
      title: t('trucks.columns.truckPlate'),
      dataIndex: 'truck_plate',
      key: 'truck_plate',
      onCell,
    },
    {
      title: t('trucks.columns.trailerPlate'),
      dataIndex: 'trailer_plate',
      key: 'trailer_plate',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteTrawl(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDeleteTrawl}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDeleteTrawl}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteTrawl, isLoadingDeleteTrawl, onCell, paramsFilters.page, t])

  return (
    <PageTable
      title={t('trucks.title')!}
      createLink="/trucks/create"
      // filtersList={filtersList}
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      onExport={exportDetails}
      isLoadingExport={isLoadingExport}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
    />
  )
}
