import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Col, Descriptions, Row } from 'antd'
import { useParams } from 'react-router-dom'
import { carsApi } from '@src/store/services/cars-service'
import {
  CarsMechanicViewContent,
} from '@src/Pages/Test/CarsMechanicView/CarsMechanicViewContent/CarsMechanicViewContent'

export const CarsMechanicView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const [activeTabKey, setActiveTabKey] = useState<string>('history')
  const { data, isLoading } = carsApi.useGetOneCarQuery(+params.id)

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col lg={24} xl={8}>
          <Card
            loading={isLoading}
          >
            {data && (
              <Descriptions
                title={`${data.make}/${data.model}`}
                column={1}
                items={[
                  { label: t('cars.columns.serialNumber'), children: data.serial_number },
                  { label: t('cars.columns.serialNumber'), children: data.category?.name || '-' },
                  { label: t('cars.columns.year'), children: data.year },
                  { label: t('cars.columns.engineHours'), children: data.engine_hours },
                ]}
              />
            )}
          </Card>
        </Col>
        <Col lg={24} xl={16}>
          <Card
            loading={isLoading}
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={[
              {
                key: 'history',
                label: t('cars.columns.history'),
              },
              {
                key: 'gallery',
                label: t('cars.columns.gallery'),
              },
            ]}
            tabProps={{
              size: 'middle',
            }}
          >
            {data && (
              <CarsMechanicViewContent
                activeTabKey={activeTabKey}
                carId={data.id}
                images={data.images}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}
