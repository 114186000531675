import { Fragment, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Button, Col, Divider, Image, Input, Row } from 'antd'
import * as Yup from 'yup'
import { Preloader } from '@src/components/Preloader/Preloader'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import moment from 'moment'
import { SignatureFormik } from '@src/components/SignatureFormik/SignatureFormik'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { UserRoles } from '@src/types/users'
import { AddressFormik } from '@src/components/AddressFormik/AddressFormik'
import { companiesApi } from '@src/store/services/companies-service'
import { carsApi } from '@src/store/services/cars-service'
import { transportationApi } from '@src/store/services/transportation-service'
import { authApi } from '@src/store/services/auth-service'
import { trucksApi } from '@src/store/services/trucks-service'
import { EditOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { displayLocation } from '@src/lib/getLocation'
import style from './transportationCreate.module.scss'

const coordinateStringRegex = /^lat:-?\d+(\.\d+)?,lng:-?\d+(\.\d+)?$/

export const TransportationCreate = () => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const { data: user } = authApi.useGetMeQuery()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const [companyTrigger] = companiesApi.useLazyGetOneCompanyQuery()
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery({ agreements_id: params.id ? +params.id : undefined })
  const { data: info, isLoading: isLoadingGetOne } = transportationApi.useGetOneTransportationQuery(+params.id!, { skip: !params.id })
  const [createTransportation, { isLoading: isLoadingCreate }] = transportationApi.useCreateTransportationMutation()
  const [editTransportation, { isLoading: isLoadingEdit }] = transportationApi.useEditTransportationMutation()
  const [storeTransportationImage, { isLoading: isLoadingStoreImage }] = transportationApi.useStoreTransportationImageMutation()
  const { data: trucksSelect } = trucksApi.useGetTrucksListForSelectQuery()

  const calculateDistance = useCallback(async (addressFrom: any, addressTo: any, index: number) => {
    if (window.google && window.google.maps && addressFrom && addressTo) {
      const directionsService = new window.google.maps.DirectionsService()
      const geocoder = new window.google.maps.Geocoder()

      let origin
      if (typeof addressFrom === 'string') {
        if (coordinateStringRegex.test(addressFrom)) {
          const parts = addressFrom.split(',')
          const latPart = parts[0].split(':')[1]
          const lat = parseFloat(latPart)
          const lngPart = parts[1].split(':')[1]
          const lng = parseFloat(lngPart)

          origin = new window.google.maps.LatLng(+lat, +lng)
        } else {
          const res: any = await geocoder.geocode({ address: addressFrom })
          origin = res?.results?.[0]?.geometry?.location
        }
      } else {
        origin = addressFrom.geometry.location
      }

      let destination
      if (typeof addressTo === 'string') {
        if (coordinateStringRegex.test(addressTo)) {
          const parts = addressTo.split(',')
          const latPart = parts[0].split(':')[1]
          const lat = parseFloat(latPart)
          const lngPart = parts[1].split(':')[1]
          const lng = parseFloat(lngPart)

          destination = new window.google.maps.LatLng(+lat, +lng)
        } else {
          const res: any = await geocoder.geocode({ address: addressTo })
          destination = res?.results?.[0]?.geometry?.location
        }
      } else {
        destination = addressTo.geometry.location
      }

      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            const route = response?.routes[0]
            let totalDistance = 0

            if (route) {
              // eslint-disable-next-line no-restricted-syntax
              for (const leg of route.legs) {
                totalDistance += leg.distance?.value || 0
              }
            }

            const distanceInKilometers = Math.ceil(totalDistance / 1000)
            formRef.current.setFieldValue(`points[${index}].distance`, distanceInKilometers)
          } else {
            console.error(`Directions request failed: ${status}`)
          }
        },
      )
    }
  }, [])

  /* useEffect(() => {
    if (addressFrom && addressTo) {
      calculateDistance()
    }
  }, [addressFrom, addressTo, calculateDistance]) */

  const updateCompanyInfo = useCallback(async (id: number, type: 'from' | 'to') => {
    const result: any = await companyTrigger(id)

    if ('data' in result) {
      if (type === 'from') {
        formRef.current.setFieldValue('fromCompanyCode', result.data.company_code)
        formRef.current.setFieldValue('fromCompanyName', result.data.name)
      } else {
        formRef.current.setFieldValue('toCompanyCode', result.data.company_code)
        formRef.current.setFieldValue('toCompanyName', result.data.name)
      }
    }
  }, [companyTrigger])

  const successCallback = useCallback(() => {
    history.goBack()
  }, [history])

  const onSubmit = useCallback(async (data: any) => {
    let result: any

    const body = {
      ...data,
      date: data.date ? data.date.format('YYYY-MM-DD') : '',
      cargo: JSON.stringify(data.cargo),
      plate: `${data.truck_plate}/${data.trailer_plate}`,
      points: data.points.map((point: any) => ({
        address_from: point.address_from,
        address_to: point.address_to,
        distance: point.distance,
        date: point.date,
      })),
    }

    if (params.id) {
      result = await editTransportation({
        id: +params.id,
        body,
      })
    } else {
      result = await createTransportation(body)
    }

    if ('data' in result) {
      const filesPromise = data.fileList.map((item: any) => {
        const formData = new FormData()
        formData.append('image', item.originFileObj)

        return storeTransportationImage({
          transportation_id: params.id || result.data.id,
          body: formData,
        })
      })
      await Promise.all(filesPromise)

      successCallback()
    }
  }, [createTransportation, editTransportation, params.id, storeTransportationImage, successCallback])

  const validationSchema = useMemo(() => Yup.object().shape({
    from_id: Yup.string().required(t('form.errors.required') ?? ''),
    to_id: Yup.string().required(t('form.errors.required') ?? ''),
    cargo_issued: Yup.string().required(t('form.errors.required') ?? ''),
    cargo_deliverer: Yup.string().required(t('form.errors.required') ?? ''),
    cargo_recipient: Yup.string().required(t('form.errors.required') ?? ''),
    date: Yup.string().required(t('form.errors.required') ?? ''),
    cargo: Yup.array().of(Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      unit: Yup.string().required(t('form.errors.required') ?? ''),
      amount: Yup.string().required(t('form.errors.required') ?? ''),
      weight: Yup.string().required(t('form.errors.required') ?? ''),
    })),
    points: Yup.array().of(Yup.object().shape({
      address_from: Yup.string().required(t('form.errors.required') ?? ''),
      address_to: Yup.string().required(t('form.errors.required') ?? ''),
      distance: Yup.string().required(t('form.errors.required') ?? ''),
    })),
    truck_id: Yup.string().required(t('form.errors.required') ?? ''),
    truck_plate: Yup.string().required(t('form.errors.required') ?? ''),
    trailer_plate: Yup.string().required(t('form.errors.required') ?? ''),
  }), [t])

  const initialValues = useMemo(() => ({
    from_id: info?.from_id ?? '', // @ts-ignore todo
    fromCompanyCode: info?.from?.company_code || '',
    fromCompanyName: info?.from?.name || '',
    to_id: info?.to_id ?? '', // @ts-ignore todo
    toCompanyCode: info?.to?.company_code || '',
    toCompanyName: info?.to?.name || '',
    address_from: info?.address_from ?? '',
    address_to: info?.address_to ?? '',
    distance: info?.distance ?? '',
    date: info?.date ? moment(info.date) : moment(),
    driver: info?.driver ?? `${user!.name} ${user!.surname}`,
    truck_id: info ? info.truck_id : user!.truck?.id || '',
    truck_plate: info?.plate ? info.plate.split('/')[0] : user!.truck?.truck_plate,
    trailer_plate: info?.plate ? info.plate.split('/')[1] : user!.truck?.trailer_plate,
    cargo_issued: info?.cargo_issued ?? '',
    cargo_deliverer: info?.cargo_deliverer ?? '',
    cargo_recipient: info?.cargo_recipient ?? '',
    customer_name: info?.customer_name ?? '',
    customer_surname: info?.customer_surname ?? '',
    signature: info?.signature ?? '',
    comment: info?.comment ?? '',
    owner_signature: info?.owner_signature ?? '',
    cargo: info?.cargo ? JSON.parse(info.cargo) : ([{ name: '', unit: '', amount: '', weight: '' }] as any),
    fileList: [],
    points: info?.points ?? [{
      id: new Date().getTime(),
      address_from: '',
      address_to: '',
      distance: '',
      date: '',
    }],
  }), [info, user])

  const handleMyLocation = async (type: 'address_from' | 'address_to', index: number) => {
    const location = await displayLocation()

    if (location) {
      // const geocoder = new window.google.maps.Geocoder()
      const { latitude, longitude } = location
      // const latlng = { lat: latitude, lng: longitude }

      // const result = await geocoder.geocode({ location: latlng })
      const address = `lat:${latitude},lng:${longitude}`
      formRef.current.setFieldValue(`points[${index}].${type}`, address)

      if (type === 'address_to') {
        calculateDistance(formRef.current.values.points[index].address_from, address, index)
      } else {
        calculateDistance(address, formRef.current.values.points[index].address_to, index)
      }
    }
  }

  return (
    <div>
      <h1 className="page_title">
        {params.id ? t('transportationCreate.titleEdit') : t('transportationCreate.title')}
      </h1>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          innerRef={formRef}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className={style.form}>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <h2>{t('transportationCreate.fromInfoBlock')}</h2>
                </Col>
                <Col xs={24} md={8}>
                  <div style={{ display: 'flex' }}>
                    <SelectFormik
                      name="from_id"
                      showSearch
                      placeholder={t('transportationCreate.from') ?? ''}
                      options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                      onChange={(value) => updateCompanyInfo(+value, 'from')}
                    />
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <FieldFormik
                    disabled
                    name="fromCompanyCode"
                    placeholder={t('transportationCreate.fromCompanyCode') ?? ''}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <FieldFormik
                    disabled
                    name="fromCompanyName"
                    placeholder={t('transportationCreate.fromCompanyName') ?? ''}
                  />
                </Col>
                <Col span={24}>
                  <h2>{t('transportationCreate.toInfoBlock')}</h2>
                </Col>
                <Col xs={24} md={8}>
                  <div style={{ display: 'flex' }}>
                    <SelectFormik
                      name="to_id"
                      showSearch
                      placeholder={t('transportationCreate.to') ?? ''}
                      options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                      onChange={(value) => updateCompanyInfo(+value, 'to')}
                    />
                  </div>
                </Col>
                <Col xs={24} md={8}>
                  <FieldFormik
                    disabled
                    name="toCompanyCode"
                    placeholder={t('transportationCreate.toCompanyCode') ?? ''}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <FieldFormik
                    disabled
                    name="toCompanyName"
                    placeholder={t('transportationCreate.toCompanyName') ?? ''}
                  />
                </Col>
                <Col span={24}>
                  <h2>{t('transportationCreate.cargoInfoBlock')}</h2>
                </Col>
                <Col xs={24} md={8}>
                  <FieldFormik
                    name="cargo_issued"
                    placeholder={t('transportationCreate.cargoIssued') ?? ''}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <FieldFormik
                    name="cargo_deliverer"
                    placeholder={t('transportationCreate.cargoDeliverer') ?? ''}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <FieldFormik
                    name="cargo_recipient"
                    placeholder={t('transportationCreate.cargoRecipient') ?? ''}
                  />
                </Col>
                {values.cargo.map((item: any, i: any) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={i}>
                    <Col xs={24} md={5}>
                      <SelectFormik
                        showSearch
                        placeholder={t('transportationCreate.customCargoName') ?? ''}
                        name={`cargo[${i}].name`}
                        options={carsSelect?.map((car) => ({ value: car.name, label: car.name })) || []}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />

                            <Input
                              placeholder={t('transportationCreate.customCargoName') ?? ''}
                              value={values.cargo[i].name}
                              onChange={(e) => setFieldValue(`cargo[${i}].name`, e.target.value)}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                          </>
                        )}
                      />
                    </Col>
                    <Col xs={24} md={5}>
                      <FieldFormik
                        name={`cargo[${i}].unit`}
                        type="number"
                        placeholder={t('transportationCreate.customCargoUnit') ?? ''}
                      />
                    </Col>
                    <Col xs={24} md={5}>
                      <FieldFormik
                        name={`cargo[${i}].amount`}
                        type="number"
                        placeholder={t('transportationCreate.customCargoAmount') ?? ''}
                      />
                    </Col>
                    <Col xs={24} md={5}>
                      <FieldFormik
                        name={`cargo[${i}].weight`}
                        placeholder={t('transportationCreate.customCargoWeight') ?? ''}
                      />
                    </Col>
                    <Col xs={24} md={2}>
                      {values.cargo.length > 1 && (
                        <Button
                          size="large"
                          onClick={() => {
                            setFieldValue('cargo', values.cargo.filter((_: any, filterI: number) => filterI !== i))
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Col>
                  </Fragment>
                ))}
                <Col span={24}>
                  <Button
                    onClick={() => {
                      setFieldValue('cargo', [...values.cargo, { name: '', unit: '', amount: '', weight: '' }])
                    }}
                  >
                    {t('transportationCreate.addCargo')}
                  </Button>
                </Col>

                <Col span={24}>
                  <h2>{t('transportationCreate.addressInfoBlock')}</h2>
                </Col>
                {values.points.map((point, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={point.id + point.address_from + point.address_to + index}>
                    <Col xs={24} md={12}>
                      {point.address_from ? (
                        <div style={{ display: 'flex' }}>
                          <FieldFormik
                            disabled
                            name={`points[${index}].address_from`}
                            placeholder={t('transportationCreate.addressFrom') ?? ''}
                          />
                          <Button size="large" onClick={() => { setFieldValue(`points[${index}].address_from`, '') }}>
                            <EditOutlined />
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <AddressFormik
                            onChange={(place) => {
                              calculateDistance(place, values.points[index].address_to, index)
                            }}
                            name={`points[${index}].address_from`}
                            placeholder={t('transportationCreate.addressFrom') ?? ''}
                          />
                          <Button size="large" onClick={() => handleMyLocation('address_from', index)}>
                            <EnvironmentOutlined />
                          </Button>
                        </div>
                      )}
                    </Col>
                    <Col xs={24} md={12}>
                      {point.address_to ? (
                        <div style={{ display: 'flex' }}>
                          <FieldFormik
                            disabled
                            name={`points[${index}].address_to`}
                            placeholder={t('transportationCreate.addressTo') ?? ''}
                          />
                          <Button size="large" onClick={() => { setFieldValue(`points[${index}].address_to`, '') }}>
                            <EditOutlined />
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: 'flex' }}>
                          <AddressFormik
                            onChange={(place) => {
                              calculateDistance(values.points[index].address_from, place, index)
                            }}
                            name={`points[${index}].address_to`}
                            placeholder={t('transportationCreate.addressTo') ?? ''}
                          />
                          <Button size="large" onClick={() => handleMyLocation('address_to', index)}>
                            <EnvironmentOutlined />
                          </Button>
                        </div>
                      )}
                    </Col>
                    <Col span={20}>
                      <FieldFormik
                        name={`points[${index}].distance`}
                        type="number"
                        placeholder={t('transportationCreate.distance') ?? ''}
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        size="large"
                        onClick={() => {
                          const arr = [...values.points]
                          arr.splice(index + 1, 0, {
                            address_to: '',
                            address_from: point.address_to,
                            distance: '',
                            id: new Date().getTime(),
                            date: '2024-07-01' } as any)
                          setFieldValue('points', arr)
                        }}
                      >
                        +
                      </Button>
                    </Col>
                    <Col span={2}>
                      {values.points.length > 1 && (
                        <Button
                          size="large"
                          onClick={() => {
                            setFieldValue('points', values.points.filter((equipment: any, filterI: number) => filterI !== index))
                          }}
                        >
                          -
                        </Button>
                      )}
                    </Col>
                  </Fragment>
                ))}

                <Col xs={24} md={12}>
                  <FieldFormik
                    name="driver"
                    placeholder={t('transportationCreate.driver') ?? ''}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="truck_id"
                    options={trucksSelect?.map((item) => ({
                      ...item,
                      value: item.id,
                      label: item.name,
                    })) || []}
                    onChange={(_, current) => {
                      setFieldValue('truck_plate', current.truck_plate)
                      setFieldValue('trailer_plate', current.trailer_plate)
                    }}
                    placeholder={t('transportationCreate.truck') ?? ''}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="truck_plate"
                    placeholder={t('transportationCreate.truckPlate') ?? ''}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="trailer_plate"
                    placeholder={t('transportationCreate.trailerPlate') ?? ''}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <FieldFormik
                    type="textarea"
                    name="comment"
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    placeholder={t('transportationBills.columns.comment') ?? ''}
                  />
                </Col>
                <Col xs={24} md={4}>
                  <DatePickerFormik name="date" placeholder={t('transportationCreate.date') ?? ''} />
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <h2>{t('transportationCreate.customerSign')}</h2>
                </Col>
                <Col xs={24} xl={12}>
                  <FieldFormik
                    name="customer_name"
                    placeholder={t('transportationCreate.customerName') ?? ''}
                  />
                  {info?.owner_signature ? (
                    <Image
                      width={200}
                      src={`${process.env.REACT_APP_API_URL}/${info?.owner_signature}`}
                    />
                  ) : (
                    <div>
                      <SignatureFormik
                        name="owner_signature"
                        placeholder={t('transportationCreate.ownerSignature') ?? ''}
                      />
                    </div>
                  )}
                </Col>
                <Col xs={24} xl={12}>
                  <FieldFormik
                    name="customer_surname"
                    placeholder={t('transportationCreate.customerSurname') ?? ''}
                  />
                  {info?.signature ? (
                    <Image
                      width={200}
                      src={`${process.env.REACT_APP_API_URL}/${info?.signature}`}
                    />
                  ) : (
                    <div>
                      <SignatureFormik name="signature" placeholder={t('transportationCreate.signature') ?? ''} />
                    </div>
                  )}
                </Col>
                {info?.images && (
                  <Col span={24}>
                    <UploadImages
                      fileList={info.images.map((el) => ({
                        url: `${process.env.REACT_APP_API_URL}/${el.image}`,
                        thumbUrl: `${process.env.REACT_APP_API_URL}/${el.thumb}`,
                        id: el.id,
                        key: el.id,
                      }))}
                      isUploadButton={false}
                      //  onRemove={(data) => { dispatch(deleteImage(data.id, data.key)) }}
                    />
                  </Col>
                )}

                <Col span={24}>
                  <div>
                    <UploadImages
                      fileList={values.fileList}
                      setFileList={(data) => {
                        setFieldValue('fileList', data)
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Button htmlType="submit" type="primary" loading={isLoadingCreate || isLoadingEdit || isLoadingStoreImage}>
                {params.id ? t('transportationCreate.save') : t('transportationCreate.submit')}
              </Button>
              {(user?.role === UserRoles.Admin || user?.role === UserRoles.Driver)
                && (
                <NavLink
                  to={`/transportation-bills/edit/${(info?.bill as unknown as { id: string })?.id}`}
                  className={style.link}
                >
                  {t('transportationCreate.openTransportationBill')}
                </NavLink>
                )}
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
