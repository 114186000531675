import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState = {
  isLoadingCreate: false,
}

const carsSlice = createSlice({
  name: 'cars',
  initialState,
  reducers: {
    setIsLoadingCreate(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingCreate = payload
    },
  },
})

export type CarsStateType = typeof initialState

export const {
  setIsLoadingCreate,
} = carsSlice.actions

export const carsReducer = carsSlice.reducer
