import { TransportationCompanyType } from '@src/types/transportation'

export type InvoicesType = {
  id: number
  pay_by: string
  sum: string
  isCredit: number
  bill_number: string
  date_closed: string
  status: InvoicesStatus
  company_id: number
  services: string
  company: TransportationCompanyType
  with_pvm: boolean
}

export enum InvoicesStatus {
  notProcessed = 1,
  processed = 2,
  archived = 3,
}
