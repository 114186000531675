import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Descriptions, Row } from 'antd'
import { Form, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import useQuery from '@src/hooks/useQuery'
import { OPERATION_TYPES } from '@src/Pages/Test/DetailTransfer/DetailTransfer'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { usersApi } from '@src/store/services/users-service'
import type { UserDetailType } from '@src/types/details'

import style from '../detail-transfer.module.scss'

type Props = {
  details: UserDetailType[]
  loading: boolean
}

export function DetailTransferForm({ details, loading }: Props) {
  const { t } = useTranslation()
  const id = useQuery('id')
  const { data: workers, isLoading: isLoadingWorkers } = usersApi.useGetWorkersForSelectQuery()

  const [selectedDetail, setSelectedDetail] = useState<UserDetailType | undefined>()

  const { values: { detail_id, operation }, setFieldValue } = useFormikContext<{ detail_id: number; operation:(typeof OPERATION_TYPES)[keyof typeof OPERATION_TYPES] }>()

  useEffect(() => {
    if (id && details && !selectedDetail) {
      setSelectedDetail(details.find((detail) => detail.id === +id))

      setFieldValue('detail_id', id)
    }
  }, [id, details, selectedDetail, setFieldValue])

  const selectedDetailCount = useMemo(() => {
    if (!selectedDetail) return undefined

    return selectedDetail.storages.reduce((acc, curr) => acc + curr.count, 0)
  }, [selectedDetail])

  const detailDescription = useMemo(() => (
    [
      {
        key: '1',
        label: t('detailTransfer.description.title') ?? '-',
        children: selectedDetail?.name ?? '-',
      },
      {
        key: '2',
        label: t('detailTransfer.description.serialNumber') ?? '-',
        children: selectedDetail?.serial_number ?? '-',
      },
      {
        key: '3',
        label: t('detailTransfer.description.count') ?? '-',
        children: selectedDetailCount ?? '-',
      },
    ]
  ), [selectedDetail, selectedDetailCount, t])

  return (
    <Form className={style.form}>
      <Row>
        <Col xs={24} md={8}>
          <SelectFormik
            name="detail_id"
            showSearch
            placeholder={t('detailTransfer.select') ?? ''}
            options={details?.map((item) => ({ label: item.name, value: item.id })) || []}
            onChange={(detailId) => {
              if (details) {
                setSelectedDetail(details.find((detail) => detail.id === detailId)!)
              }
            }}
          />
        </Col>
      </Row>

      {detail_id && (
        <>
          <Row>
            <Col span={24}>
              <Descriptions items={detailDescription} />
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <SelectFormik
                name="operation"
                options={[
                  { label: t('detailTransfer.form.operation.mechanic'), value: OPERATION_TYPES.Mechanic },
                  { label: t('detailTransfer.form.operation.storage'), value: OPERATION_TYPES.Storage },
                ]}
              />
            </Col>
            {operation === OPERATION_TYPES.Mechanic && (
              <Col xs={24} md={8}>
                <SelectFormik
                  name="worker_id"
                  showSearch
                  placeholder={t('detailTransfer.form.worker') ?? ''}
                  options={workers?.map((item) => ({ label: item.name, value: item.id })) || []}
                />
              </Col>
            )}
            <Col xs={24} md={4}>
              <FieldFormik
                name="count"
                type="number"
              />
            </Col>
          </Row>
        </>
      )}
      <Button
        htmlType="submit"
        type="primary"
        disabled={isLoadingWorkers || loading}
      >
        {t('detailTransfer.transfer')}
      </Button>
    </Form>
  )
}
