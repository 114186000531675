import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { theme } from '@src/theme'
import { ConfigProvider, App as AppAntd } from 'antd'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './store/store'
import './locales'
import './style/index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <BrowserRouter>
    <ConfigProvider theme={theme}>
      <Provider store={store}>
        <AppAntd>
          <App />
        </AppAntd>
      </Provider>
    </ConfigProvider>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
