import React, { ReactNode, useMemo } from 'react'
import { PageTable } from '@src/components/Table/PageTable'
import { useAgreementsTable } from '@src/components/pages/agreements/AgreementsTable.hook'
import { useAgreementInvoicesTableHook } from '@src/components/pages/agreementInvoices/AgreementInvoicesTable.hook'
import { useAgreementBillsTable } from '@src/components/pages/agreementBills/AgreementBillsTable.hook'

export const useCarsViewContentListHook = (serialNumber: string): Record<string, ReactNode> => {
  const {
    columns: columnsAgreements,
    data: dataAgreements,
    paramsFilters: paramsFiltersAgreements,
    setParamsFilters: setParamsFiltersAgreements,
    AgreementsComponents,
    isLoading: isLoadingAgreements,
  } = useAgreementsTable({
    initParamsFilters: {
      page: 1,
      filters: {
        filters: [{ columns: 'serial_number', value: serialNumber }],
      },
    },
  })

  const {
    columns: columnsAgreementInvoices,
    paramsFilters: paramsFiltersAgreementInvoices,
    setParamsFilters: setParamsFiltersAgreementInvoices,
    isLoading: isLoadingAgreementInvoices,
    data: dataAgreementInvoices,
  } = useAgreementInvoicesTableHook({
    initParamsFilters: {
      page: 1,
      filters: {
        filters: [{ columns: 'serial_number', value: serialNumber }],
      },
    },
  })

  const {
    data: dataAgreementBills,
    isLoading: isLoadingAgreementBills,
    paramsFilters: paramsFiltersAgreementBills,
    setParamsFilters: setParamsFiltersAgreementBills,
    columns: columnsAgreementBills,
  } = useAgreementBillsTable({
    initParamsFilters: {
      page: 1,
      filters: {
        filters: [{ columns: 'serial_number', value: serialNumber }],
      },
    },
  })

  return useMemo(() => ({
    agreements: (
      <div>
        <PageTable
          isTableOnly
          setParamsFilters={setParamsFiltersAgreements}
          paramsFilters={paramsFiltersAgreements}
          tableData={{
            ...dataAgreements,
            isLoading: isLoadingAgreements,
            columns: columnsAgreements,
            components: {
              body: {
                // eslint-disable-next-line max-len
                row: (props: any) => <tr {...props} style={{ background: dataAgreements?.data.find((item) => item.id === props['data-row-key'])?.owner.color }} />,
              },
            },
          }}
        />
        {AgreementsComponents}
      </div>
    ),
    agreementInvoices: (
      <PageTable
        isTableOnly
        setParamsFilters={setParamsFiltersAgreementInvoices}
        paramsFilters={paramsFiltersAgreementInvoices}
        tableData={{
          ...dataAgreementInvoices,
          isLoading: isLoadingAgreementInvoices,
          columns: columnsAgreementInvoices,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => <tr {...props} style={{ background: dataAgreementInvoices?.data.find((item) => item.id === props['data-row-key'])?.agreement.owner.color }} />,
            },
          },
        }}
      />
    ),
    agreementBills: (
      <PageTable
        isTableOnly
        setParamsFilters={setParamsFiltersAgreementBills}
        paramsFilters={paramsFiltersAgreementBills}
        tableData={{
          ...dataAgreementBills,
          isLoading: isLoadingAgreementBills,
          columns: columnsAgreementBills,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => <tr {...props} style={{ background: dataAgreementBills?.data.find((item) => item.id === props['data-row-key'])?.agreement.owner.color }} />,
            },
          },
        }}
      />
    ),
  }), [
    AgreementsComponents, columnsAgreementBills, columnsAgreementInvoices, columnsAgreements, dataAgreementBills,
    dataAgreementInvoices, dataAgreements, isLoadingAgreementBills, isLoadingAgreementInvoices, isLoadingAgreements,
    paramsFiltersAgreementBills, paramsFiltersAgreementInvoices, paramsFiltersAgreements,
    setParamsFiltersAgreementBills, setParamsFiltersAgreementInvoices, setParamsFiltersAgreements,
  ])
}
