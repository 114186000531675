import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { TableFiltersType } from '@src/types/tableFilters'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { carsApi } from '@src/store/services/cars-service'
import { companiesApi } from '@src/store/services/companies-service'
import { PageTable } from '@src/components/Table/PageTable'
import { useTransportationTable } from '@src/components/pages/transportation/TransportationTable.hook'
import { usersApi } from '@src/store/services/users-service'
import { UserRoles } from '@src/types/users'

// todo убрать серые инпуты и сделать глазики
export const Transportation = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: companiesList } = companiesApi.useGetSelectCompaniesQuery()
  const { data: carsList } = carsApi.useGetCarsModelsQuery()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const { data: usersSelect } = usersApi.useGetDriversForSelectQuery()

  const { paramsFilters, setParamsFilters, columns, data, isLoading, TransportationsComponents } = useTransportationTable({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: filtersQuery ? JSON.parse(filtersQuery) : null,
    },
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'cargo',
      placeholder: t('transportation.columns.cargo'),
      width: '200px',
      options: carsList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'from_id',
      placeholder: t('transportation.columns.from'),
      width: '200px',
      options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'to_id',
      placeholder: t('transportation.columns.to'),
      width: '200px',
      options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'address_from',
      placeholder: t('transportation.columns.addressFrom'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'address_to',
      placeholder: t('transportation.columns.addressTo'),
      width: '200px',
    },
    {
      type: 'rangePicker',
      dataIndex: 'date',
      placeholder: [t('filters.dateFrom'), t('filters.dateTo')],
    },
    {
      type: 'select',
      dataIndex: 'user_id',
      placeholder: t('transportation.columns.driver'),
      width: '200px',
      options: usersSelect?.map((item) => ({ value: item.id, label: item.name })) || [],
      roles: [UserRoles.Admin],
    },
  ], [t, carsList, companiesList, usersSelect])

  return (
    <div>
      <PageTable
        title={t('transportation.title')!}
        createLink="/transportation/create"
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      {TransportationsComponents}
    </div>
  )
}
