import React, { useCallback } from 'react'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { AppDispatch } from '@src/store/store'
import { carsApi } from '@src/store/services/cars-service'
import { agreementsApi } from '@src/store/services/agreements-service'
import { Image } from '@src/components/ImagesPreview/Image'
import styles from './Preview.module.scss'

type PreviewPropsType = {
  url: string
  thumb: string
  id: number
}

const Preview = ({ url, id, thumb }: PreviewPropsType) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })
  const [deleteImage] = carsApi.useDeleteImageMutation()

  const onImageDelete = useCallback(() => {
    const { confirm } = Modal

    return new Promise((resolve, reject) => {
      confirm({
        title: t('confirmDeleteFileTitle'),
        onOk: async () => {
          resolve(true)
          await deleteImage(id)
          dispatch(agreementsApi.util?.invalidateTags(['Agreements']))
        },
        onCancel: () => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(true)
        },
      })
    })
  }, [deleteImage, dispatch, id, t])

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  }

  const className = isDragging && styles.dragging

  return (
    <Image
      ref={setNodeRef}
      url={url}
      thumb={thumb}
      style={style}
      className={className}
      attributes={attributes}
      listeners={listeners}
      onImageDelete={onImageDelete}
    />
  )
}

export default Preview
