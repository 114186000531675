import { useMemo } from 'react'
import { Button, Col, Row } from 'antd'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import style from '@src/Pages/CarsCreate/carsCreate.module.scss'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { trucksApi } from '@src/store/services/trucks-service'
import { usersApi } from '@src/store/services/users-service'

export const TrucksCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const { data: usersSelect } = usersApi.useGetDriversForSelectQuery()
  const { data, isLoading: isLoadingGetOne } = trucksApi.useGetOneTruckQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = trucksApi.useCreateTruckMutation()
  const [edit, { isLoading: isLoadingEdit }] = trucksApi.useEditTruckMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      make: Yup.string().required(t('form.errors.required') ?? ''),
      model: Yup.string().required(t('form.errors.required') ?? ''),
      truck_plate: Yup.string().required(t('form.errors.required') ?? ''),
      trailer_plate: Yup.string().required(t('form.errors.required') ?? ''),
      user_id: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    id: data?.id || null,
    make: data?.make || '',
    model: data?.model || '',
    truck_plate: data?.truck_plate || '',
    trailer_plate: data?.trailer_plate || '',
    user_id: data?.user_id || '',
  }), [data])

  const onSubmit = async (values: any) => {
    let result
    if (params.id) {
      result = await edit(values)
    } else {
      result = await create(values)
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <div>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {() => (
            <Form className={style.form}>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik name="make" placeholder={t('trucks.columns.make') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="model" placeholder={t('trucks.columns.model') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="truck_plate" placeholder={t('trucks.columns.truckPlate') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="trailer_plate" placeholder={t('trucks.columns.trailerPlate') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="user_id"
                    options={usersSelect?.map((item) => ({ value: item.id, label: item.name })) || []}
                    placeholder={t('trucks.columns.user') ?? ''}
                  />
                </Col>
              </Row>
              <Button htmlType="submit" type="primary" loading={isLoadingCreate || isLoadingEdit}>
                {params.id ? t('trucks.save') : t('trucks.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
