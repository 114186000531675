import { detailsApi } from '@src/store/services/details-service'
import { TaskStatusEnum } from '@src/types/tasks'
import { PageTable } from '@src/components/Table/PageTable'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useQuery from '@src/hooks/useQuery'
import { ColumnProps } from 'antd/lib/table'
import { Car } from '@src/types/car'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { DeleteOutlined, FileDoneOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { isSelectionText } from '@src/lib/utils'

export const DetailsInvoices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: {
      status: {
        $eq: TaskStatusEnum.New,
      },
    },
  })
  const { data, isLoading } = detailsApi.useGetDetailInvoiceListQuery(paramsFilters)
  const [onDelete, { isLoading: isLoadingDelete }] = detailsApi.useDeleteDetailInvoiceMutation()
  const [generateDocument] = detailsApi.useGenerateDocumentDetailInvoiceMutation()

  const onCell = useCallback((record: Car) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/details-invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: t('detailsInvoices.columns.number'),
      dataIndex: 'number',
      key: 'number',
      onCell,
    },
    {
      title: t('detailsInvoices.columns.company'),
      dataIndex: 'company',
      key: 'company',
      render: (company) => company.name,
      onCell,
    },
    {
      title: t('detailsInvoices.columns.date'),
      dataIndex: 'date',
      key: 'date',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className="table_actions">
          <button
            onClick={() => generateDocument(record.id)}
            type="button"
            className="table_actions_item"
          >
            <FileDoneOutlined />
          </button>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => {
              onDelete(record.id)
            }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDelete}
          >
            <button
              type="button"
              className={cn('table_actions_item', 'table_actions_item__delete')}
              disabled={isLoadingDelete}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [generateDocument, isLoadingDelete, onCell, onDelete, t])

  return (
    <PageTable
      title={t('detailsInvoices.title')!}
      createLink="/details-invoices/create"
      // filtersList={filtersList}
      filtersV2
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
    />
  )
}
