import { FC, ReactNode, useMemo } from 'react'
import { CarImages } from '@src/types/car'
import { Collapse } from 'antd'
import { Image } from '@src/components/ImagesPreview/Image'
import { CarHistory } from '@src/Pages/Test/CarsMechanicView/CarHistory/CarHistory'
import style from './carViewContent.module.scss'

type CarViewContentProps = {
  activeTabKey: string
  carId: number
  images: CarImages
}

export const CarsMechanicViewContent: FC<CarViewContentProps> = ({ activeTabKey, carId, images }) => {
  const contentListRender = useMemo<Record<string, ReactNode>>(() => ({
    history: <CarHistory carId={carId} />,
    gallery: (
      <Collapse accordion>
        {Object.keys(images).map((key) => (
          <Collapse.Panel header={key} key={key}>
            <div className={style.gallery}>
              {images[key].map((item) => (
                <Image
                  url={`${process.env.REACT_APP_API_URL}/${item.image}`}
                  thumb={`${process.env.REACT_APP_API_URL}/${item.thumb}`}
                />
              ))}
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
    ),
  }), [images, carId])

  return contentListRender[activeTabKey]
}
