import { useCallback, useEffect, useMemo, useState } from 'react'
import { Tag } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { invoicesApi } from '@src/store/services/invoices-service'
import { TableFiltersType } from '@src/types/tableFilters'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { useHistory, useLocation } from 'react-router-dom'
import { BillsTypeEnum } from '@src/types/settings'
import { InvoicesActions } from '@src/Pages/Invoices/Invoices'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { SendEmailType } from '@src/types/mailLogs'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { TransportationBillsActions } from '@src/components/pages/transportationBills/TransportationBills.hook'
import { AgreementInvoicesActions } from '@src/components/pages/agreementInvoices/AgreementInvoicesTable.hook'
import { AgreementBillsActions } from '@src/components/pages/agreementBills/AgreementBillsTable.hook'
import { PageTable } from '@src/components/Table/PageTable'
import { formatPrice } from '@src/lib/formatPrice'
import { isSelectionText } from '@src/lib/utils'
import style from './allInvoices.module.scss'

export const AllInvoices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const filtersQuery = useQuery('filters')
  const pageQuery = useQuery('page')
  const location = useLocation()
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : null,
  })
  const { data: customerNamesList } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data, isLoading } = invoicesApi.useGetAllInvoicesQuery(paramsFilters, { refetchOnFocus: true })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCellMailHandler = useCallback((record: any, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        switch (record.invoiceType) {
          case +BillsTypeEnum.BusinessInvoices: {
            dispatch(setSendEmail({
              companyId: record.company.id, id: record.id, type: SendEmailType.Invoices, isAccountant,
            }))
            break
          }
          case +BillsTypeEnum.TransportationBill: {
            dispatch(setSendEmail({
              companyId: record.company_id, id: record.id, type: SendEmailType.TransportationBills, isAccountant,
            }))
            break
          }
          case +BillsTypeEnum.AgreementInvoices: {
            dispatch(setSendEmail({
              companyId: record.company_id, id: record.id, type: SendEmailType.AgreementInvoices, isAccountant,
            }))
            break
          }
          case +BillsTypeEnum.AgreementBill: {
            dispatch(setSendEmail({
              companyId: record.company_id, id: record.id, type: SendEmailType.AgreementBills, isAccountant,
            }))
            break
          }
          default: {
            break
          }
        }
      }
    },
  }), [dispatch])

  const onCell = useCallback((record: any) => ({
    onClick: () => {
      if (!isSelectionText()) {
        switch (record.invoiceType) {
          case +BillsTypeEnum.BusinessInvoices:
            history.push(`/invoices/edit/${record.id}`, { backUrl: location.pathname + location.search })
            break
          case +BillsTypeEnum.AgreementBill:
            history.push(`/agreement-bills/edit/${record.id}`, { backUrl: location.pathname + location.search })
            break
          case +BillsTypeEnum.AgreementInvoices:
            history.push(`/agreement-invoices/edit/${record.id}`, { backUrl: location.pathname + location.search })
            break
          case +BillsTypeEnum.TransportationBill:
            history.push(`/transportation-bills/edit/${record.id}`, { backUrl: location.pathname + location.search })
            break
          default:
        }
      }
    },
  }), [history, location])

  const columns = useMemo<ColumnProps<any>[]>(() => [
    {
      title: t('allInvoices.columns.type'),
      dataIndex: 'invoiceType',
      key: 'invoiceType',
      render: (invoiceType) => t(`allInvoices.type.${invoiceType}`),
      align: 'center',
      onCell,
    },
    {
      title: t('allInvoices.columns.date'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      onCell,
    },
    {
      title: t('invoices.columns.company'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      onCell,
    },
    {
      title: t('invoices.columns.billNumber'),
      dataIndex: 'billNumber',
      key: 'billNumber',
      align: 'center',
      onCell,
    },
    {
      title: t('invoices.columns.sum'),
      dataIndex: 'sum',
      key: 'sum',
      align: 'center',
      render: (sum) => `${sum}€`,
      onCell,
    },
    {
      title: t('invoices.columns.sentAccountant'),
      dataIndex: 'isSentAccountant',
      key: 'isSentAccountant',
      align: 'center',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record, true),
    },
    {
      title: t('invoices.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      align: 'center',
      className: style.sentCustomer,
      render: (isSentCustomer) => (
        <Tag color={isSentCustomer ? 'green' : 'red'}>
          {t(`sendStatus.${isSentCustomer}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <>
          {record.invoiceType === +BillsTypeEnum.BusinessInvoices && (
            <InvoicesActions record={record} />
          )}
          {record.invoiceType === +BillsTypeEnum.TransportationBill && (
            <TransportationBillsActions record={record} />
          )}
          {record.invoiceType === +BillsTypeEnum.AgreementInvoices && (
            <AgreementInvoicesActions record={record} />
          )}
          {record.invoiceType === +BillsTypeEnum.AgreementBill && (
            <AgreementBillsActions record={record} />
          )}
        </>
      ),
    },
  ], [t, onCell, onCellMailHandler])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'billNumber',
      placeholder: t('allInvoices.columns.billNumber'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'customer_id',
      placeholder: t('agreements.columns.customer'),
      width: '200px',
      options: customerNamesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'rangePicker',
      dataIndex: 'date',
      placeholder: [t('filters.dateFrom'), t('filters.dateTo')],
    },
  ], [customerNamesList, t])

  return (
    <div>
      <PageTable
        title={t('allInvoices.title')}
        setParamsFilters={setParamsFilters}
        filtersList={filtersList}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => <tr {...props} style={{ background: data?.data.find((item: any) => item.id === props['data-row-key'])?.company?.color }} />,
            },
          },
          footer: () => (
            <div className={style.tableFooter}>
              {t('allInvoices.totalSum')}
              {' '}
              {formatPrice(data?.additional.totalSum)}
            </div>
          ),
        }}
      />
    </div>
  )
}
