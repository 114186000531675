import moment from 'moment'

export const calculateTimeUntil = (targetDateTime: string, currentDateTine?: string) => {
  const now = currentDateTine ? moment.utc(currentDateTine) : moment()
  const targetDate = moment.utc(targetDateTime)
  const diff = now.diff(targetDate)

  if (diff < 0) {
    return '00m : 00s'
  }

  const duration = moment.duration(diff)

  // Форматирование продолжительности времени с учётом месяцев и дней
  return duration.format('MM[M] : DD[d] : hh[h] : mm[m] : ss[s]', {
    trim: 'left',
  })
}
