import { api } from '@src/api/api'

export const carsApi = {
  storeFile(form: any, carId: number, agreementId?: number, status?: number) {
    return api.post<string>(
      '/store/image',
      form,
      { carId, agreementId, status },
      { 'Content-Type': 'multipart/form-data' },
    )
  },
}
