import { FC, ReactNode } from 'react'
import { UserRoles } from '@src/types/users'
import { authApi } from '@src/store/services/auth-service'

type AllowedToProps = {
  roles: UserRoles[]
  children: ReactNode
}

export const AllowedTo: FC<AllowedToProps> = ({
  roles, children,
}) => {
  const { data: user } = authApi.useGetMeQuery()

  if (!user?.role || !roles.includes(user.role)) return null

  return children
}
