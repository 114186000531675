import React, { useEffect, useMemo, useState } from 'react'
import { ConfigProvider } from 'antd'
import enAnt from 'antd/lib/locale/en_US'
import ltAnt from 'antd/lib/locale/lt_LT'
import { useTranslation } from 'react-i18next'
import 'moment-business-days'
import 'moment-duration-format'
import { Preloader } from '@src/components/Preloader/Preloader'
import { SendEmail } from '@src/components/SendEmail/SendEmail'
import { authApi } from '@src/store/services/auth-service'
import { PageContainer } from './components/PageContainer/PageContainer'
import { Routes } from './routes/routes'

export const App = () => {
  const [isInit, setIsInit] = useState(false)
  const { i18n } = useTranslation()
  const [trigger, { data: user }] = authApi.useLazyGetMeQuery()

  useEffect(() => {
    (async () => {
      await trigger()
      setIsInit(true)
    })()
  }, [trigger])

  const locale = useMemo(() => {
    switch (i18n.language) {
      case 'lt':
        return ltAnt
      case 'en':
        return enAnt
      default:
        return ltAnt
    }
  }, [i18n.language])

  return (
    <ConfigProvider locale={locale}>
      <Preloader loading={!isInit}>
        <div className="App">
          {user ? (
            <PageContainer>
              <Routes />
            </PageContainer>
          ) : (
            <Routes />
          )}
          <SendEmail />
        </div>
      </Preloader>
    </ConfigProvider>
  )
}
