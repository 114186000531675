import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { ColumnProps } from 'antd/lib/table'
import { Button, Modal, Popconfirm, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { TableFiltersType } from '@src/types/tableFilters'
import { Filters } from '@src/components/Table/Filters/Filters'
import cn from 'classnames'
import { standardWorkApi } from '@src/store/services/standard-work-service'
import { StandardWorkType } from '@src/types/standardWork'
import { StandardWorkCreate } from '@src/Pages/Service/StandardWorkCreate/StandardWorkCreate'
import { isSelectionText } from '@src/lib/utils'
import style from './standardWork.module.scss'

export type StandardWorkActionsProps = {
  record: StandardWorkType
}
export const StandardWorkActions: FC<StandardWorkActionsProps> = ({ record }) => {
  const { t } = useTranslation()
  const [remove, { isLoading: isLoadingRemove }] = standardWorkApi.useRemoveMutation()

  return (
    <div className={style.actions}>
      <Popconfirm
        placement="left"
        title={t('popconfirm.deleteTitle')}
        onConfirm={() => {
          remove(record.id)
        }}
        okText={t('popconfirm.okText')}
        cancelText={t('popconfirm.cancelText')}
        disabled={isLoadingRemove}
      >
        <button
          type="button"
          className={cn(style.actions_item, style.actions_item__delete)}
          disabled={isLoadingRemove}
        >
          <DeleteOutlined />
        </button>
      </Popconfirm>
    </div>
  )
}

export const StandardWork = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : undefined,
  })
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { data, isLoading } = standardWorkApi.useGetListQuery({
    ...paramsFilters, filters: paramsFilters.filters && JSON.stringify(paramsFilters.filters),
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: StandardWorkType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/standard-work/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const columns = useMemo<ColumnProps<StandardWorkType>[]>(() => [
    {
      title: t('standardWork.columns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 20,
      align: 'center',
      render: (_, record, index) => (index + 1 + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('standardWork.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
      align: 'center',
      onCell,
    },
    {
      title: t('standardWork.columns.priceWorkingHour'),
      dataIndex: 'price_working_hour',
      key: 'price_working_hour',
      width: 120,
      align: 'center',
      render: (sum) => `${sum}€`,
      onCell,
    },
    {
      title: t('standardWork.columns.priceNonWorkingHour'),
      dataIndex: 'price_non_working_hour',
      key: 'price_non_working_hour',
      width: 120,
      align: 'center',
      render: (sum) => `${sum}€`,
      onCell,
    },
    {
      title: t('standardWork.columns.priceWorkingHourVat'),
      dataIndex: 'tax',
      key: 'price_working_hour_vat',
      width: 120,
      align: 'center',
      render: (tax, record) => `${record.price_working_hour + (tax / 100) * record.price_working_hour}€`,
      onCell,
    },
    {
      title: t('standardWork.columns.priceNonWorkingHourVat'),
      dataIndex: 'tax',
      key: 'price_non_working_hour_vat',
      width: 120,
      align: 'center',
      render: (tax, record) => `${record.price_non_working_hour + (tax / 100) * record.price_non_working_hour}€`,
      onCell,
    },
    {
      title: t('standardWork.columns.unit'),
      dataIndex: 'unit',
      key: 'unit',
      width: 120,
      align: 'center',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 20,
      align: 'center',
      render: (_, record) => (
        <StandardWorkActions record={record} />
      ),
    },
  ], [data?.meta.pageSize, onCell, paramsFilters.page, t])

  const handleTableChange = useCallback((
    pagination: TablePaginationConfig,
  ) => {
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page: pagination.current || 1,
    }))
  }, [])

  const handleFilters = (e: Array<{ columns: string, value: any }>) => {
    setParamsFilters((prevState) => ({
      ...prevState,
      page: 1,
      filters: { ...prevState.filters, filters: e },
    }))
  }

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'name',
      placeholder: t('standardWork.columns.name'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'price_working_hour',
      placeholder: t('standardWork.columns.priceWorkingHour'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'price_non_working_hour',
      placeholder: t('standardWork.columns.priceNonWorkingHour'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'unit',
      placeholder: t('standardWork.columns.unit'),
      width: '200px',
    },
  ], [t])

  return (
    <div>
      <div className={style.header}>
        <h1 className="page_title">{t('standardWork.title')}</h1>
        <Button type="primary" onClick={() => setIsOpenModal(true)} icon={<PlusOutlined />} size="large">
          {t('standardWork.create')}
        </Button>
      </div>
      <div>
        <Filters
          filters={filtersList}
          onChange={handleFilters}
          values={paramsFilters.filters?.filters}
        />
      </div>
      <Table<StandardWorkType>
        dataSource={data?.data}
        columns={columns}
        loading={isLoading}
        rowKey="id"
        onChange={handleTableChange}
        className={style.table}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: data?.meta.totalCount,
          current: data?.meta.currentPage,
          pageSize: data?.meta.pageSize,
          showSizeChanger: false,
        }}
      />
      <Modal
        title={null}
        open={isOpenModal}
        footer={null}
        onCancel={() => setIsOpenModal(false)}
      >
        <StandardWorkCreate onClose={() => setIsOpenModal(false)} />
      </Modal>
    </div>
  )
}
