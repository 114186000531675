import { Agreement } from '@src/types/agreements'

export enum AgreementInvoicesStatus {
  notProcessed = 1,
  processed = 2,
  archived = 3,
}

export type AgreementInvoicesType = {
  id: number
  agreement: Agreement
  agreement_id: number
  created_at: string
  date_created: string
  date_end: string
  date_start: string
  bill_number: number
  isCredit: number
  pay_by: string
  price_month: number
  updated_at: string
  status: AgreementInvoicesStatus
  text: string
  email: string
}
