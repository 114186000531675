import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { EditTimelogRequest, LastWorkTimelogType, TaskRouteType, TaskSignRequest, TaskType } from '@src/types/tasks'
import moment from 'moment'
import { formatFilters } from '@src/lib/formatFilters'

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Tasks', 'TaskRoutes'],
  endpoints: (build) => ({
    getTasksList: build.query<GetItemsType<TaskType>, any>({
      query: (params) => ({
        url: `/service/tasks?${formatFilters(params)}`,
      }),
      providesTags: () => ['Tasks'],
    }),
    deleteTask: build.mutation<void, number>({
      query: (id) => ({
        url: `/service/tasks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tasks'],
    }),
    getOneTask: build.query<TaskType, number>({
      query: (id) => ({
        url: `/service/tasks/${id}`,
      }),
      providesTags: () => ['Tasks'],
    }),
    createTask: build.mutation<TaskType, any>({
      query: (body) => ({
        url: '/service/tasks',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    editTask: build.mutation<TaskType, any>({
      query: ({ id, ...body }) => ({
        url: `/service/tasks/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    startWork: build.mutation<void, number>({
      query: (task_id) => ({
        url: '/service/start-work',
        method: 'POST',
        body: {
          task_id,
          started: moment(),
        },
      }),
      invalidatesTags: ['Tasks'],
    }),
    stopWork: build.mutation<void, number>({
      query: (task_id) => ({
        url: `/service/${task_id}/end-work`,
        method: 'POST',
        body: {
          ended: moment(),
        },
      }),
      invalidatesTags: ['Tasks'],
    }),
    lastWorkTimelog: build.query<LastWorkTimelogType, void>({
      query: () => ({
        url: '/last-work-timelog',
      }),
      providesTags: () => ['Tasks'],
    }),
    getTimelogsHistory: build.query<LastWorkTimelogType[], number>({
      query: (id) => ({
        url: `/service/tasks/${id}/timelogs`,
      }),
      providesTags: () => ['Tasks'],
    }),
    createTimelog: build.mutation<LastWorkTimelogType[], EditTimelogRequest>({
      query: (body) => ({
        url: 'service/timelogs',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    editTimelog: build.mutation<LastWorkTimelogType[], EditTimelogRequest>({
      query: ({ id, ...body }) => ({
        url: `service/timelogs/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    deleteTimelog: build.mutation<LastWorkTimelogType[], number>({
      query: (id) => ({
        url: `service/timelogs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tasks'],
    }),
    getTasksListForMechanic: build.query<TaskType[], void>({
      query: () => ({
        url: '/service/assigned-tasks',
      }),
      providesTags: () => ['Tasks'],
    }),
    storeTaskImage: build.mutation<void, any>({
      query: ({ formData, taskId }) => ({
        url: '/store/task-image',
        method: 'POST',
        params: {
          task_id: taskId,
        },
        body: formData,
      }),
      invalidatesTags: [],
    }),
    deleteTaskImage: build.mutation<void, number>({
      query: (id) => ({
        url: `/task-image/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Tasks'],
    }),
    addTaskComment: build.mutation<void, { taskId: number, body: any }>({
      query: ({ taskId, body }) => ({
        url: `/service/tasks/${taskId}/comment`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    taskFinish: build.mutation<void, { taskId: number, body: any }>({
      query: ({ taskId, body }) => ({
        url: `/service/tasks/${taskId}/finish`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    taskSign: build.mutation<void, TaskSignRequest>({
      query: ({ taskId, body }) => ({
        url: `/service/tasks/${taskId}/sign`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tasks'],
    }),
    taskInvoice: build.mutation<void, number>({
      query: (taskId) => ({
        url: `/service/tasks/${taskId}/invoice`,
        method: 'GET',
        responseHandler: async (response) => window.open(window.URL.createObjectURL(await response.blob())),
      }),
    }),

    getTaskRoutesList: build.query<GetItemsType<TaskRouteType>, any>({
      query: (params) => ({
        url: '/service/task-routes',
        params: {
          page: params.page,
          filters: params.filters ? JSON.stringify(params.filters) : undefined,
        },
      }),
      providesTags: () => ['TaskRoutes'],
    }),
    deleteTaskRoute: build.mutation<void, number>({
      query: (id) => ({
        url: `/service/task-routes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TaskRoutes'],
    }),
    getOneTaskRoute: build.query<TaskRouteType, number>({
      query: (id) => ({
        url: `/service/task-routes/${id}`,
      }),
      providesTags: () => ['TaskRoutes'],
    }),
    createTaskRoute: build.mutation<TaskRouteType, any>({
      query: (body) => ({
        url: '/service/task-routes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TaskRoutes'],
    }),
    editTaskRoute: build.mutation<TaskRouteType, any>({
      query: ({ id, ...body }) => ({
        url: `/service/task-routes/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TaskRoutes'],
    }),
  }),
})
