import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { DeleteOutlined } from '@ant-design/icons'
import { TableFiltersType } from '@src/types/tableFilters'
import { Car, CarStatus } from '@src/types/car'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { carsApi } from '@src/store/services/cars-service'
import { PageTable } from '@src/components/Table/PageTable'
import { isSelectionText } from '@src/lib/utils'
import style from './cars.module.scss'

export const Cars = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
  })
  const { data, isLoading } = carsApi.useGetCarsListQuery(paramsFilters)
  const [deleteCar, { isLoading: isLoadingDelete }] = carsApi.useDeleteCarMutation()
  const [exportCars, { isLoading: isLoadingExport }] = carsApi.useExportCarsMutation()

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: Car) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/cars/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<Car>[]>(() => [
    {
      title: t('cars.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('cars.columns.make'),
      dataIndex: 'make',
      key: 'make',
      onCell,
    },
    {
      title: t('cars.columns.model'),
      dataIndex: 'model',
      key: 'model',
      onCell,
    },
    {
      title: t('cars.columns.year'),
      dataIndex: 'year',
      key: 'year',
      onCell,
    },
    {
      title: t('cars.columns.serialNumber'),
      dataIndex: 'serial_number',
      key: 'serial_number',
      onCell,
    },
    {
      title: t('cars.columns.engineHours'),
      dataIndex: 'engine_hours',
      key: 'engine_hours',
      onCell,
    },
    {
      title: t('cars.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <div>
          { status === CarStatus.Free && (
            <div className={cn(style.status, style.status__free)}>
              {t('cars.columns.statusFree')}
            </div>
          )}
          { status === CarStatus.Leased && (
            <div className={cn(style.status, style.status__rent)}>
              {t('cars.columns.statusRent')}
            </div>
          )}
          { status === CarStatus.Repair && (
            <div className={cn(style.status, style.status__repair)}>
              {t('cars.columns.statusRepair')}
            </div>
          )}
          { status === CarStatus.Sold && (
            <div className={cn(style.status, style.status__repair)}>
              {t('cars.columns.statusSold')}
            </div>
          )}
        </div>
      ),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteCar(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDelete}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDelete}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteCar, isLoadingDelete, onCell, paramsFilters.page, t])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'make',
      placeholder: t('cars.columns.make'),
      operator: '$eq',
      width: '200px',
      options: makesList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'model',
      placeholder: t('cars.columns.model'),
      operator: '$eq',
      width: '200px',
      options: modelsList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'serial_number',
      placeholder: t('cars.columns.serialNumber'),
      operator: '$contains',
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'owner_id',
      placeholder: t('cars.columns.owner'),
      operator: '$eq',
      width: '200px',
      options: ownersSelect?.map((owner) => ({ value: owner.id, label: owner.name })) || [],
    },
    {
      type: 'select',
      dataIndex: 'status',
      placeholder: t('cars.columns.status'),
      operator: '$eq',
      width: '100px',
      options: [
        {
          value: '0',
          label: t('cars.columns.statusFree'),
        },
        {
          value: CarStatus.Leased,
          label: t('cars.columns.statusRent'),
        },
        {
          value: CarStatus.Repair,
          label: t('cars.columns.statusRepair'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'from_service',
      placeholder: t('cars.columns.fromService'),
      operator: '$eq',
      width: '150px',
      options: [
        {
          value: '0',
          label: t('common.false'),
        },
        {
          value: '1',
          label: t('common.true'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'paid_once',
      placeholder: t('cars.columns.paidOnce'),
      operator: '$eq',
      width: '150px',
      options: [
        {
          value: '0',
          label: t('common.false'),
        },
        {
          value: '1',
          label: t('common.true'),
        },
      ],
    },
  ], [ownersSelect, makesList, modelsList, t])

  return (
    <div>
      <PageTable
        title={t('cars.title')!}
        createLink="/cars/create"
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        onExport={exportCars}
        filtersV2
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
    </div>
  )
}
