import { NavLink } from 'react-router-dom'
import { tasksApi } from '@src/store/services/tasks-service'
import { Card, Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useWorkTime } from '@src/hooks/useWorkTime'
import clock from '../../../assets/clock.svg'
import play from '../../../assets/play-circle.svg'
import style from './TasksMechanic.module.scss'

const TaskTime = ({ taskId }:{taskId: number}) => {
  const lastWorkTimeFormat = useWorkTime(taskId)

  return <p>{lastWorkTimeFormat.time || '-'}</p>
}

export const TasksMechanic = () => {
  const { t } = useTranslation()
  const { data } = tasksApi.useGetTasksListForMechanicQuery(undefined, {
    pollingInterval: 30000,
  })

  return (
    <div>
      <Card title={t('tasks.title')}>
        {!data?.length && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        <div className={style.tasks}>
          {data?.map((task) => (
            <NavLink to={`/tasks/view/${task.id}`} className={style.task} key={task.id}>
              <div className={style.taskTop}>
                <div className={style.title}>{task.name}</div>
                <div className={style.clock}>
                  <img src={clock} alt="clock" />
                  <TaskTime taskId={task.id} />
                </div>
              </div>
              <div className={style.rows}>
                <p className={style.row}>
                  <span>Automobilis:</span>
                  {' '}
                  {task.car.make}
                  /
                  {task.car.model}
                  (
                  {task.car.serial_number}
                  )
                </p>
                <p className={style.row}>
                  <span>Klientas:</span>
                  {' '}
                  {task.client.name}
                </p>
                <p className={style.row}>
                  <span>Kontaktiniai duomenys:</span>
                  {' '}
                  {task.car.make}
                </p>
                <p className={style.row}>
                  <span>Darbo tipas:</span>
                  {' '}
                  {task.work_type.name}
                </p>
                <p className={style.row}>
                  <span>Vieta:</span>
                  {' '}
                  {task.place}
                </p>
                <p className={style.row}>
                  <span>Pradžios ir pabaigos datos:</span>
                  {' '}
                  {moment(task.planned_date_start).format('YYYY-MM-DD HH:mm')}
                  {' '}
                  —
                  {' '}
                  {moment(task.planned_date_end).format('YYYY-MM-DD HH:mm')}
                </p>
              </div>
              <div className={style.bottom}>
                <button type="button" className={style.bottomBlack}>Atidaryti užduotį</button>
                <button type="button" className={style.bottomGrey}>
                  <img src={play} alt="play" />
                  Pradeti darba
                </button>
              </div>
            </NavLink>
          ))}
        </div>
      </Card>
    </div>
  )
}
