import React, { FC, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { Modal, Popconfirm } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { AgreementsPausesCreate } from '@src/components/pages/agreements/pauses/AgreementsPausesCreate'
import { agreementsApi } from '@src/store/services/agreements-service'
import { PageTable } from '@src/components/Table/PageTable'
import { AgreementPause } from '@src/types/agreementPauses'
import cn from 'classnames'
import style from '@src/Pages/AgreementBills/agreementBills.module.scss'
import { DeleteOutlined } from '@ant-design/icons'
import { isSelectionText } from '@src/lib/utils'

type AgreementsPausesProps = {
  agreementId: number
  ref: any
}

export const AgreementsPauses: FC<AgreementsPausesProps> = forwardRef(({ agreementId }, ref) => {
  const { t } = useTranslation()
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [editId, setEditId] = useState<number | null>(null)
  const [paramsFilters, setParamsFilters] = useState({
    page: 1,
    filters: {
      filters: [{ columns: 'agreement_id', value: agreementId }],
    },
  })
  const { data, isLoading } = agreementsApi.useGetListAgreementPausesQuery(paramsFilters)
  const [deleteAgreementPause, { isLoading: isLoadingDelete }] = agreementsApi.useDeleteAgreementPauseMutation()

  const onCell = useCallback((record: AgreementPause) => ({
    onClick: () => {
      if (!isSelectionText()) {
        setEditId(record.id)
        setIsOpenCreate(true)
      }
    },
  }), [])

  const columns = useMemo<ColumnProps<AgreementPause>[]>(() => [
    {
      title: t('agreementsPauses.dateStart'),
      dataIndex: 'date_from',
      key: 'date_from',
      align: 'center',
      onCell,
    },
    {
      title: t('agreementsPauses.dateEnd'),
      dataIndex: 'date_to',
      key: 'date_to',
      align: 'center',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <Popconfirm
          placement="left"
          title={t('popconfirm.deleteTitle')}
          onConfirm={() => { deleteAgreementPause(record.id) }}
          okText={t('popconfirm.okText')}
          cancelText={t('popconfirm.cancelText')}
          disabled={isLoadingDelete}
        >
          <button
            type="button"
            className={cn(style.actions_item, style.actions_item__delete)}
            disabled={isLoadingDelete}
          >
            <DeleteOutlined />
          </button>
        </Popconfirm>
      ),
    },
  ], [deleteAgreementPause, isLoadingDelete, onCell, t])

  const onCloseCreate = () => {
    setIsOpenCreate(false)
    setEditId(null)
  }

  useImperativeHandle(ref, () => ({
    setIsOpenCreate,
  }))

  return (
    <>
      <PageTable
        isTableOnly
        paramsFilters={paramsFilters}
        setParamsFilters={setParamsFilters}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
      <Modal
        title={null}
        open={isOpenCreate}
        footer={null}
        onCancel={onCloseCreate}
      >
        <AgreementsPausesCreate onClose={onCloseCreate} agreementId={agreementId} editId={editId} />
      </Modal>
    </>
  )
})
