import { FC, useCallback, useState, useEffect, useMemo } from 'react'
import { Button, Card, Divider, Table, type TableProps } from 'antd'
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Filters } from '@src/components/Table/Filters/Filters'
import { TableFiltersType } from '@src/types/tableFilters'
import { ResponseMetaType } from '@src/api/api'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { CardTabListType } from 'antd/es/card/Card'
import { FiltersV2 } from '@src/components/Table/Filters/FiltersV2'
import style from './pageTable.module.scss'

type TableRowSelection<T> = TableProps<T>['rowSelection'];

type PageTablePropsBase = {
  title?: string | boolean;
  createLink?: string;
  createText?: string;
  setParamsFilters: (value: (prevState: any) => any) => void;
  onExport?: (value: number[]) => void
  isLoadingExport?: boolean
  tabList?: CardTabListType[]
  onTabChange?: (key: string) => void
  filtersV2?: boolean
  tableData: {
    data?: Array<any>
    meta?: ResponseMetaType
    isLoading: boolean
    columns: any
    components?: any
    footer?: () => any
  }
  actions?: any[]
  isTableOnly?: boolean
  activeTabKey?: string
}

type PageTablePropsWithFilters = PageTablePropsBase & {
  filtersList: TableFiltersType;
  paramsFilters: Record<string, any>
}

type PageTablePropsWithoutFilters = PageTablePropsBase & {
  filtersList?: TableFiltersType;
  paramsFilters?: Record<string, any>;
}

type PageTableProps = PageTablePropsWithFilters | PageTablePropsWithoutFilters;

export const PageTable: FC<PageTableProps> = ({
  title, createLink, createText, setParamsFilters, filtersList, paramsFilters, tableData, onExport,
  isLoadingExport, actions, isTableOnly, tabList, onTabChange, activeTabKey, filtersV2,
}) => {
  const { t } = useTranslation()
  const [selectedRowKeys, setSelectedRowKeys] = useState<Set<number>>(new Set())

  useEffect(() => () => {
    setSelectedRowKeys(new Set())
  }, [])

  const handleFilters = (e: Array<{ columns: string, value: any }>) => {
    setParamsFilters((prevState) => ({
      ...prevState,
      page: 1,
      filters: { ...prevState.filters, filters: e },
    }))
  }

  const handleFiltersV2 = useCallback((e: any) => {
    setParamsFilters((prevState) => ({
      ...prevState,
      page: 1,
      filters: {
        ...prevState.filters,
        ...e,
      },
    }))
  }, [setParamsFilters])

  const handleTableChange = useCallback((
    pagination: TablePaginationConfig,
  ) => {
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page: pagination.current || 1,
    }))
  }, [setParamsFilters])

  const rowSelection = useMemo<TableRowSelection<any>>(() => ({
    selectedRowKeys: Array.from(selectedRowKeys),
    onSelect: (record, selected) => {
      if (selected) {
        setSelectedRowKeys((prevState) => {
          const newItems = new Set(prevState)
          newItems.add(record.id)
          return newItems
        })
      } else {
        setSelectedRowKeys((prevState) => {
          const newItems = new Set(prevState)
          newItems.delete(record.id)
          return newItems
        })
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        setSelectedRowKeys((prevState) => new Set([...prevState, ...changeRows.map((item) => item.id)]))
      } else {
        setSelectedRowKeys((prevState) => {
          const newItems = new Set(prevState)
          changeRows.forEach((element) => newItems.delete(element.id))
          return newItems
        })
      }
    },
  }), [selectedRowKeys])

  const table = useMemo(() => (
    <Table
      components={tableData.components}
      rowSelection={onExport ? rowSelection : undefined}
      dataSource={tableData?.data || []}
      columns={tableData.columns}
      loading={tableData.isLoading}
      onChange={handleTableChange}
      rowKey="id"
      scroll={{ x: 'max-content' }}
      footer={tableData.footer}
      pagination={{
        total: tableData.meta?.totalCount,
        current: tableData.meta?.currentPage,
        pageSize: tableData.meta?.pageSize,
        showSizeChanger: false,
      }}
    />
  ), [handleTableChange, onExport, rowSelection, tableData])

  if (isTableOnly) {
    return table
  }

  return (
    <div>
      <Card
        title={title}
        tabList={tabList}
        onTabChange={onTabChange}
        activeTabKey={activeTabKey}
        extra={(
          <div className={style.cardActions}>
            {actions}
            {onExport && (
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                size="large"
                onClick={() => onExport(Array.from(selectedRowKeys))}
                loading={isLoadingExport}
              >
                Export
              </Button>
            )}
            {createLink && (
              <NavLink to={createLink}>
                <Button type="primary" icon={<PlusOutlined />} size="large">
                  {createText || t('pageTable.create')}
                </Button>
              </NavLink>
            )}
          </div>
        )}
      >
        {filtersList && (
          <>
            {filtersV2 ? (
              <FiltersV2
                filters={filtersList}
                onChange={handleFiltersV2}
                values={paramsFilters!.filters}
              />
            ) : (
              <Filters
                filters={filtersList}
                onChange={handleFilters}
                values={paramsFilters!.filters?.filters}
              />
            )}

            <Divider />
          </>
        )}
        {table}
      </Card>
    </div>
  )
}
