import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

export const Page404 = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle={t('404.subTitle')}
        extra={(
          <NavLink to="/">
            <Button type="primary">{t('404.btn')}</Button>
          </NavLink>
        )}
      />
    </div>
  )
}
