import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { ServiceTemplateShortType, ServiceTemplateType } from '@src/types/serviceTemplates'

export const serviceTemplatesApi = createApi({
  reducerPath: 'serviceTemplatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['ServiceTemplates'],
  endpoints: (build) => ({
    getServiceTemplatesList: build.query<GetItemsType<ServiceTemplateType>, { page: number }>({
      query: (params) => ({
        url: '/service/service-templates',
        params,
      }),
      providesTags: () => ['ServiceTemplates'],
    }),
    getServiceTemplatesListForSelect: build.query<ServiceTemplateShortType[], void>({
      query: () => ({
        url: '/service/service-templates/short',
      }),
      providesTags: () => ['ServiceTemplates'],
    }),
    deleteServiceTemplate: build.mutation<void, number>({
      query: (id) => ({
        url: `/service/service-templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ServiceTemplates'],
    }),
    getOneServiceTemplate: build.query<ServiceTemplateType, number>({
      query: (id) => ({
        url: `/service/service-templates/${id}`,
      }),
      providesTags: () => ['ServiceTemplates'],
    }),
    createServiceTemplate: build.mutation<ServiceTemplateType, any>({
      query: (body) => ({
        url: '/service/service-templates',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceTemplates'],
    }),
    editServiceTemplate: build.mutation<ServiceTemplateType, any>({
      query: ({ id, ...body }) => ({
        url: `/service/service-templates/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ServiceTemplates'],
    }),
  }),
})
