import { tasksApi } from '@src/store/services/tasks-service'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { TaskType } from '@src/types/tasks'
import { PageTable } from '@src/components/Table/PageTable'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment/moment'
import { useHistory } from 'react-router-dom'
import { isSelectionText } from '@src/lib/utils'

type OldWorksProps = {
  carId: number
  taskId: number
}

export const OldWorks: FC<OldWorksProps> = ({ carId, taskId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [paramsFilters, setParamsFilters] = useState({
    page: 1,
    filters: {
      id: {
        $ne: taskId,
      },
      car_id: {
        $eq: carId,
      },
    },
  })
  const { data, isLoading } = tasksApi.useGetTasksListQuery(paramsFilters)

  const onCell = useCallback((record: TaskType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/tasks/view-short/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<TaskType>[]>(() => [
    {
      title: t('tasks.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('tasks.columns.workType'),
      dataIndex: 'work_type',
      key: 'work_type',
      render: (workType) => workType.name,
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.client'),
      dataIndex: 'client',
      key: 'client',
      render: (client) => client?.name,
      onCell,
    },
    {
      title: t('tasks.columns.car'),
      dataIndex: 'car',
      key: 'car',
      render: (car) => `${car.make}/${car.model}(${car.serial_number})`,
      onCell,
    },
    {
      title: t('tasks.columns.plannedDateStart'),
      dataIndex: 'planned_date_start',
      key: 'planned_date_start',
      render: (plannedDateStart) => moment(plannedDateStart).format('YYYY-MM-DD HH:mm'),
      onCell,
    },
    {
      title: t('tasks.columns.plannedDateEnd'),
      dataIndex: 'planned_date_end',
      key: 'planned_date_end',
      render: (plannedDateEnd) => moment(plannedDateEnd).format('YYYY-MM-DD HH:mm'),
      onCell,
    },
    {
      title: t('tasks.columns.author'),
      dataIndex: 'author',
      key: 'author',
      render: (author) => `${author.name} ${author.surname}`,
      onCell,
    },
    {
      title: t('tasks.columns.workers'),
      dataIndex: 'workers',
      key: 'workers',
      // eslint-disable-next-line react/no-danger
      render: (workers) => <div dangerouslySetInnerHTML={{ __html: workers.map((item: any) => `${item.name} ${item.surname}`).join('<br/>') }} />,
      onCell,
    },
    {
      title: t('tasks.columns.description'),
      dataIndex: 'description',
      key: 'description',
      width: '200px',
      onCell,
    },
  ], [t, onCell, paramsFilters.page, data?.meta.pageSize])

  return (
    <div>
      <PageTable
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        isTableOnly
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
    </div>
  )
}
