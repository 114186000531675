import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { serviceInvoicesApi } from '@src/store/services/serviceInvoices-service'
import { useMemo } from 'react'
import { Preloader } from '@src/components/Preloader/Preloader'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button } from 'antd'

export const ServiceInvoicesEdit = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const { data, isLoading } = serviceInvoicesApi.useGetOneServiceInvoiceQuery(+params.id!)
  const [editServiceInvoice, { isLoading: isLoadingEdit }] = serviceInvoicesApi.useEditServiceInvoiceMutation()

  const initialValues = useMemo(() => ({
    id: data?.id,
    number: data?.number || '',
  }), [data])

  const onSubmit = async (values: any) => {
    const result = await editServiceInvoice(values)

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <div>
      <h1 className="page_title">{t('serviceInvoices.titleEdit')}</h1>
      <Preloader loading={isLoading}>
        <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
          <Form>
            <FieldFormik name="number" placeholder={t('serviceInvoices.columns.number')} />
            <Button htmlType="submit" type="primary" loading={isLoadingEdit}>
              {t('serviceInvoices.save')}
            </Button>
            <NavLink to={`/tasks/view/${data?.task.id}`} style={{ marginLeft: 8 }}>
              <Button>{t('serviceInvoices.taskLink')}</Button>
            </NavLink>
          </Form>
        </Formik>
      </Preloader>
    </div>
  )
}
