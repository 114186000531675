import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Row, Switch } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { invoicesApi } from '@src/store/services/invoices-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import style from '@src/Pages/TransportationBillsEdit/transportationBillsEdit.module.scss'
import * as Yup from 'yup'
import { companiesApi } from '@src/store/services/companies-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { InvoicesDocument } from '@src/components/pages/invoices/InvoicesDocument'

export const InvoicesCreate = () => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const { data, isLoading } = invoicesApi.useGetOneQuery(params.id || '', { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = invoicesApi.useCreateMutation()
  const [update, { isLoading: isLoadingUpdate }] = invoicesApi.useUpdateMutation()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()

  const initialValues = useMemo(() => ({
    pay_by: data?.pay_by ? moment(data.pay_by) : '',
    sum: data?.sum || '',
    bill_number: data?.bill_number || '',
    isCredit: data?.isCredit || 0,
    date_closed: data?.date_closed ? moment(data.date_closed) : '',
    company_id: data?.company_id || '',
    with_pvm: data?.with_pvm ?? true,
    services: data?.services ? JSON.parse(data.services) : [
      { name: '', unit: '', amount: '', value: '' },
    ],
  }), [data])

  const onSubmit = async (values: any) => {
    const requestData = {
      ...values,
      services: JSON.stringify(values.services),
      date_closed: values.date_closed ? values.date_closed.format('YYYY-MM-DD') : '',
      pay_by: values.pay_by ? values.pay_by.format('YYYY-MM-DD') : '',
    }

    let result
    if (params.id) {
      result = await update({
        ...requestData,
        invoiceId: params.id,
      })
    } else {
      result = await create(requestData)
    }

    if ('data' in result) {
      if (values.sum && values.bill_number) {
        dispatch(setSendEmail({
          type: SendEmailType.Invoices,
          companyId: +values.company_id,
          id: result.data.id ? +result.data.id : +params.id!,
        }))
      }
      history.goBack()
    }
  }

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      company_id: Yup.string().required(t('form.errors.required') ?? ''),
      bill_number: Yup.string().required(t('form.errors.required') ?? ''),
      services: Yup.array().of(Yup.object().shape({
        name: Yup.string().required(t('form.errors.required') ?? ''),
        unit: Yup.string().required(t('form.errors.required') ?? ''),
        amount: Yup.string().required(t('form.errors.required') ?? ''),
        value: Yup.string().required(t('form.errors.required') ?? ''),
      })),
      date_closed: Yup.string().required(t('form.errors.required') ?? ''),
      pay_by: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  return (
    <div>
      <h1 className="page_title">{params.id ? t('invoicesCreate.titleEdit') : t('invoicesCreate.title')}</h1>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Row gutter={[24, 24]}>
              <Col xl={24} xxl={12}>
                <Form>
                  <Row gutter={[20, 0]}>
                    <Col span={24}>
                      <SelectFormik
                        name="company_id"
                        showSearch
                        placeholder={t('invoicesCreate.company') ?? ''}
                        options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="bill_number"
                        placeholder={t('invoicesCreate.billNumber') ?? ''}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="sum"
                        placeholder={t('invoicesCreate.sum') ?? ''}
                      />
                    </Col>
                    {values.services.map((item: any, i: any) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Fragment key={i}>
                        <Col xs={24} md={5}>
                          <FieldFormik
                            name={`services[${i}].name`}
                            placeholder={t('invoicesCreate.customCargoName') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={5}>
                          <FieldFormik
                            name={`services[${i}].unit`}
                            type="number"
                            placeholder={t('invoicesCreate.customCargoUnit') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={5}>
                          <FieldFormik
                            name={`services[${i}].amount`}
                            type="number"
                            placeholder={t('invoicesCreate.customCargoAmount') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={5}>
                          <FieldFormik
                            name={`services[${i}].value`}
                            type="number"
                            placeholder={t('invoicesCreate.customBillValue') ?? ''}
                          />
                        </Col>
                        <Col xs={24} md={2}>
                          {values.services.length > 1 && (
                          <Button
                            size="large"
                            onClick={() => {
                              setFieldValue('services', values.services.filter((_: any, filterI: number) => filterI !== i))
                            }}
                          >
                            Delete
                          </Button>
                          )}
                        </Col>
                      </Fragment>
                    ))}
                    <Col span={24}>
                      <Button
                        className={style.btn}
                        onClick={() => {
                          setFieldValue('services', [...values.services, { name: '', unit: '', amount: '', value: '' }])
                        }}
                      >
                        {t('invoicesCreate.addServices')}
                      </Button>
                    </Col>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="date_closed" placeholder={t('invoicesCreate.dateCreated') ?? ''} />
                    </Col>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="pay_by" placeholder={t('invoicesCreate.payBy') ?? ''} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={12}>
                      <div style={{ marginBottom: 15 }}>
                        <span style={{ marginRight: 15 }}>{t('transportationCreate.type')}</span>
                        <RadioFormik
                          options={[
                            { value: 0, label: t('transportationCreate.basic') },
                            { value: 1, label: t('transportationCreate.credit') },
                          ]}
                          name="isCredit"
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <Switch
                        checkedChildren={t('invoicesCreate.withPvmTrue')}
                        unCheckedChildren={t('invoicesCreate.withPvmFalse')}
                        checked={values.with_pvm}
                        onChange={(e) => setFieldValue('with_pvm', e)}
                      />
                    </Col>
                  </Row>
                  <Button
                    style={{ marginTop: 20 }}
                    htmlType="submit"
                    type="primary"
                    loading={isLoadingCreate || isLoadingUpdate}
                  >
                    {t('invoicesCreate.submit')}
                  </Button>
                </Form>
              </Col>
              <Col xl={24} xxl={12}>
                <Card>
                  <InvoicesDocument />
                </Card>
              </Col>
            </Row>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
