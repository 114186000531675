import { useTranslation } from 'react-i18next'
import { Preloader } from '@src/components/Preloader/Preloader'
import { Form, Formik } from 'formik'
import { useMemo } from 'react'
import { Button, Col, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { companiesApi } from '@src/store/services/companies-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { detailsApi } from '@src/store/services/details-service'
import style from '@src/Pages/TransportationBillsEdit/transportationBillsEdit.module.scss'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment'

export const DetailsInvoicesCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()
  const { data: details } = detailsApi.useGetDetailsListForSelectQuery()
  const [create, { isLoading: isLoadingCreate }] = detailsApi.useCreateDetailInvoiceMutation()
  const [update, { isLoading: isLoadingUpdate }] = detailsApi.useUpdateDetailInvoiceMutation()
  const { data, isLoading: isLoadingGetOne } = detailsApi.useGetOneDetailInvoiceQuery(+params.id!, { skip: !params.id })

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      number: Yup.string().required(t('form.errors.required')!),
      company_id: Yup.string().required(t('form.errors.required')!),
      date: Yup.string().required(t('form.errors.required')!),
      details: Yup.array().of(Yup.object().shape({
        id: Yup.string().required(t('form.errors.required')!),
        count: Yup.number().required(t('form.errors.required')!),
      })),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    number: data?.number || '',
    company_id: data?.company_id || null,
    date: data ? moment(data.date) : '',
    details: data ? data.details.map((item: any) => ({
      id: item.id,
      count: item.pivot.count,
    })) : [
      {
        id: null,
        count: '',
      },
    ],
  }), [data])

  const onSubmit = async (values: any) => {
    const body = {
      ...values,
      date: values.date.format('YYYY-MM-DD'),
      details: values.details.reduce((acc: any, item: any) => {
        // eslint-disable-next-line no-param-reassign
        acc[item.id] = { count: item.count }
        return acc
      }, {}),
    }

    let result
    if (params.id) {
      result = await update({
        ...body,
        id: params.id,
      })
    } else {
      result = await create(body)
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <Preloader loading={isLoadingGetOne}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Row gutter={[20, 0]}>
              <Col span={24}>
                <SelectFormik
                  name="company_id"
                  showSearch
                  placeholder={t('detailsInvoices.columns.company')!}
                  options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="number" placeholder={t('detailsInvoices.columns.number')!} />
              </Col>
              <Col xs={24} md={12}>
                <DatePickerFormik name="date" placeholder={t('detailsInvoices.columns.date')!} />
              </Col>
              {values.details.map((detail: any, index: number) => (
                <>
                  <Col xs={24} md={12}>
                    <SelectFormik
                      name={`details[${index}].id`}
                      showSearch
                      placeholder={t('detailsInvoices.columns.detail')!}
                      options={details?.map((item) => ({
                        label: item.name,
                        value: item.id,
                        disabled: values.details.some((itm: any) => itm.id === item.id),
                      })) || []}
                    />
                  </Col>
                  <Col xs={22} md={10}>
                    <FieldFormik
                      name={`details[${index}].count`}
                      placeholder={t('detailsInvoices.columns.count')!}
                      type="number"
                    />
                  </Col>
                  <Col span={2}>
                    {values.details.length > 1 && (
                      <Button
                        size="large"
                        onClick={() => {
                          setFieldValue('details', values.details.filter((_: any, filterI: number) => filterI !== index))
                        }}
                      >
                        -
                      </Button>
                    )}
                  </Col>
                </>
              ))}
              <Col span={24}>
                <Button
                  className={style.btn}
                  onClick={() => {
                    setFieldValue('details', [...values.details, { id: null, count: '' }])
                  }}
                >
                  {t('detailsInvoices.addDetail')}
                </Button>
              </Col>
            </Row>
            <Button
              style={{ marginTop: 20 }}
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingUpdate}
            >
              {t('detailsInvoices.submit')}
            </Button>
          </Form>
        )}
      </Formik>
    </Preloader>
  )
}
