import { PageTable } from '@src/components/Table/PageTable'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import useQuery from '@src/hooks/useQuery'
import { stocksApi } from '@src/store/services/stocks-service'
import { ColumnProps } from 'antd/lib/table'
import { StockAreaType } from '@src/types/stocks'
import { useHistory, useParams } from 'react-router-dom'
import style from '@src/Pages/Invoices/invoices.module.scss'
import cn from 'classnames'
import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { isSelectionText } from '@src/lib/utils'

export const StockAreas = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ stock: string }>()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    'filters[stock_id][$eq]': params.stock,
  })
  const { data, isLoading } = stocksApi.useGetStockAreasListQuery(paramsFilters)
  const [remove, { isLoading: isLoadingRemove }] = stocksApi.useDeleteStockAreaMutation()

  const onCell = useCallback((record: StockAreaType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/stock-areas/${params.stock}/edit/${record.id}`)
      }
    },
  }), [history, params])

  const columns = useMemo<ColumnProps<StockAreaType>[]>(() => [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1 + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('stockAreas.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('stockAreas.columns.address'),
      dataIndex: 'address',
      key: 'address',
      onCell,
    },
    {
      title: t('stockAreas.columns.notes'),
      dataIndex: 'notes',
      key: 'notes',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { remove(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingRemove}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingRemove}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, isLoadingRemove, onCell, paramsFilters.page, remove, t])

  return (
    <div>
      <PageTable
        title={t('stockAreas.title')}
        createLink={`/stock-areas/${params.stock}/create`}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          columns,
          isLoading,
        }}
      />
    </div>
  )
}
