import { FC } from 'react'
import { Column, DualAxes, Pie } from '@ant-design/charts'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { statisticsApi } from '@src/store/services/statistics-service'
import { Preloader } from '@src/components/Preloader/Preloader'
import { Col, Row } from 'antd'
import { configColumn, configDualAxes, configPie } from '../config'

type StatisticsRentProps = {
  dates: [moment.Moment, moment.Moment]
}

export const StatisticsTransportation: FC<StatisticsRentProps> = ({ dates }) => {
  const { t } = useTranslation()
  const { data, isLoading } = statisticsApi.useGetTransportationQuery({
    from: dates[0].format('YYYY-MM-DD'), to: dates[1].format('YYYY-MM-DD'),
  })

  return (
    <div>
      <Preloader loading={isLoading}>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <h3>{t('statistics.profitSpendings')}</h3>
            <DualAxes
              {...configDualAxes}
              data={data?.dualLineProfitSpendings ? [data?.dualLineProfitSpendings, data?.dualLineProfitSpendings] : []}
            />
          </Col>
          <Col span={12}>
            <h3>{t('statistics.selectedMonth')}</h3>
            <Column
              {...configColumn}
              xField="month"
              data={data?.lineChartSelectedMonth || []}
              seriesField="type"
              isGroup
            />
          </Col>
          <Col span={24}>
            <h3>{t('statistics.companyIncome')}</h3>
            <Pie {...configPie} data={data?.pieChartCompanies || []} />
          </Col>
        </Row>
      </Preloader>
    </div>
  )
}
