import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { ServiceInvoiceType } from '@src/types/serviceInvoices'
import { formatFilters } from '@src/lib/formatFilters'

export const serviceInvoicesApi = createApi({
  reducerPath: 'serviceInvoicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['ServiceInvoice'],
  endpoints: (build) => ({
    getListServiceInvoices: build.query<GetItemsType<ServiceInvoiceType>, { page: number, filters: any }>({
      query: (params) => ({
        url: `/service/invoices?${formatFilters(params)}`,
      }),
      providesTags: () => ['ServiceInvoice'],
    }),
    sendCustomerServiceInvoice: build.mutation<void, {id: number, email: string}>({
      query: (params) => ({
        url: `/service/invoices/${params.id}/customer-send`,
        method: 'GET',
        params,
      }),
    }),
    getOneServiceInvoice: build.query<ServiceInvoiceType, number>({
      query: (id) => ({
        url: `/service/invoices/${id}`,
      }),
      providesTags: () => ['ServiceInvoice'],
    }),
    editServiceInvoice: build.mutation<void, any>({
      query: ({ id, ...body }) => ({
        url: `/service/invoices/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    generateDocumentServiceInvoice: build.mutation<void, number>({
      query: (id) => ({
        url: `/service/invoices/${id}/generate-document`,
        method: 'GET',
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
      }),
    }),
  }),
})
