import {
  FC, ReactNode, useMemo,
} from 'react'
import {
  DatePicker, Input, Select, Switch,
} from 'antd'

import { TableFiltersType } from '@src/types/tableFilters'
import { authApi } from '@src/store/services/auth-service'
import style from './filters.module.scss'

type FiltersProps = {
  filters: TableFiltersType
  onChange: (value: any) => void
  loading?: boolean
  children?: ReactNode
  values?: any
}

export const FiltersV2: FC<FiltersProps> = ({
  children, filters, onChange, loading, values,
}) => {
  const { data: user } = authApi.useGetMeQuery()

  const filtersComponents = useMemo(() => filters.map((filter) => {
    if (filter.roles?.length && !filter.roles.includes(user!.role)) {
      return null
    }

    switch (filter.type) {
      case 'input':
        return (
          <Input
            defaultValue={values[filter.dataIndex]?.[filter.operator!]}
            type="text"
            key={filter.dataIndex}
            onChange={(event) => {
              onChange({
                [filter.dataIndex]: {
                  [filter.operator!]: event.target.value,
                },
              })
            }}
            placeholder={filter.placeholder}
            style={{ width: filter.width }}
          />
        )
      case 'datePicker':
        return (
          <DatePicker
            defaultValue={values[filter.dataIndex]?.[filter.operator!]}
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            onChange={(e) => {
              onChange({
                [filter.dataIndex]: {
                  [filter.operator!]: e?.format(),
                },
              })
            }}
            className={filter.className}
            style={{ width: filter.width }}
          />
        )
      case 'rangePicker': {
      //  const thisValue = filtersData?.find((value) => value.columns === filter.dataIndex)?.value

        return (
          <DatePicker.RangePicker
            // value={thisValue ? [moment(thisValue[0]), moment(thisValue[1])] as any : []}
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            onChange={(e) => {
              onChange({
                [filter.dataIndex]: {
                  $gte: e && e[0]?.format('YYYY-MM-DD'),
                  $lte: e && e[1]?.format('YYYY-MM-DD'),
                },
              })
            }}
            // @ts-ignore
            defaultValue={filter.defaultValue}
            className={filter.className}
            style={{ width: filter.width }}
            allowClear={!filter.notAllowClear}
          />
        )
      }
      case 'select':
        return (
          <Select
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            allowClear
            showSearch={filter.showSearch || true}
            onChange={(event) => {
              onChange({
                [filter.dataIndex]: {
                  [filter.operator!]: event,
                },
              })
            }}
            onSearch={(e) => filter.onSearch && filter.onSearch(e)}
            className={filter.className}
            style={{ width: filter.width }}
            optionFilterProp="children"
            defaultValue={values[filter.dataIndex]?.[filter.operator!]}
          >
            {filter.options.map((option) => (
              <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            ))}
          </Select>
        )
      case 'switch':
        return (
          <div className={style.switch} key={filter.dataIndex}>
            <div className={style.switch_title}>{filter.placeholder}</div>
            <Switch onChange={(event) => {
              onChange({
                [filter.dataIndex]: {
                  [filter.operator!]: event,
                },
              })
            }}
            />
          </div>
        )
      default:
        return null
    }
  }), [filters, onChange, user, values])

  return (
    <div className={style.filters}>
      {!loading && (
        <>
          {filtersComponents}
          {children}
        </>
      )}
      {/* <Button
        icon={<DeleteOutlined />}
        onClick={clearFilters}
      >
        {(t('table.filtersClear'))}
      </Button> */}
    </div>
  )
}
