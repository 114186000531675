import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { CompaniesForSelectResponse, Company, CompanyRepresentative, CompanyType } from '@src/types/company'

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Company'],
  endpoints: (build) => ({
    getCompanyList: build.query<GetItemsType<Company>, any>({
      query: (params) => ({
        url: '/companies',
        params: {
          page: params.page,
          filters: JSON.stringify(params.filters),
        },
      }),
      providesTags: () => ['Company'],
    }),
    deleteCompany: build.mutation<void, number>({
      query: (id) => ({
        url: `/companies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Company'],
    }),
    getOneCompany: build.query<Company, number>({
      query: (id) => ({
        url: `/companies/${id}`,
      }),
      providesTags: () => ['Company'],
    }),
    createCompany: build.mutation<Company, any>({
      query: (body) => ({
        url: '/companies',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    editCompany: build.mutation<Company, any>({
      query: ({ id, ...body }) => ({
        url: `/companies/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Company'],
    }),
    getSelectCompanies: build.query<CompaniesForSelectResponse, CompanyType | void>({
      query: (type) => ({
        url: '/companies-short',
        params: {
          filters: type && JSON.stringify({
            filters: [{
              columns: 'type',
              value: type,
            }],
          }),
        },
      }),
      providesTags: () => ['Company'],
    }),
    getCompanyRepresentative: build.query<CompanyRepresentative, number>({
      query: (id) => ({
        url: `/companies/${id}/get-representative`,
      }),
      providesTags: () => ['Company'],
    }),
    exportCompanies: build.mutation<void, number[]>({
      query: (companiesIds) => ({
        url: 'companies/excel-export',
        method: 'POST',
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        body: {
          companiesIds,
        },
      }),
    }),
  }),
})
