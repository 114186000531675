import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { agreementsApi } from '@src/store/services/agreements-service'
import { ColumnProps } from 'antd/lib/table'
import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { Button, Popconfirm, Popover, Tag } from 'antd'
import style from '@src/Pages/AgreementInvoices/agreementInvoices.module.scss'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { DeleteOutlined, FileTextOutlined, MailOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { isSelectionText } from '@src/lib/utils'

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}

type AgreementInvoicesActionsProps = {
  record: AgreementInvoicesType
}

export const AgreementInvoicesActions: FC<AgreementInvoicesActionsProps> = ({ record }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [deleteAgreementInvoice, { isLoading: isLoadingDelete }] = agreementsApi.useDeleteAgreementInvoiceMutation()

  return (
    <div className={style.actions}>
      <a
        // eslint-disable-next-line max-len
        href={`${process.env.REACT_APP_API_URL}/api/agreements/generate-invoice/${record.id}?access_token=${localStorage.getItem('token')}`}
        target="_blank"
        rel="noreferrer"
        className={style.actions_item}
      >
        <FileTextOutlined />
      </a>
      <button
        type="button"
        onClick={() => {
          dispatch(setSendEmail({
            type: SendEmailType.AgreementInvoices,
            companyId: record.agreement.customer.id,
            id: record.id,
          }))
        }}
        className={style.actions_item}
      >
        <MailOutlined />
      </button>
      <Popconfirm
        placement="left"
        title={t('popconfirm.deleteTitle')}
        onConfirm={() => { deleteAgreementInvoice(record.id) }}
        okText={t('popconfirm.okText')}
        cancelText={t('popconfirm.cancelText')}
        disabled={isLoadingDelete}
      >
        <button
          type="button"
          className={cn(style.actions_item, style.actions_item__delete)}
          disabled={isLoadingDelete}
        >
          <DeleteOutlined />
        </button>
      </Popconfirm>
    </div>
  )
}

export const useAgreementInvoicesTableHook = ({ initParamsFilters }: any) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const [isOpenEditStatus, setIsOpenEditStatus] = useState<null | number>(null)
  const { data, isLoading } = agreementsApi.useGetListAgreementInvoicesQuery(paramsFilters)
  const [changeStatus] = agreementsApi.useChangeStatusAgreementInvoicesMutation()

  const onCell = useCallback((record: AgreementInvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-invoices/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const updateStatus = useCallback((id: number, status: AgreementInvoicesStatus) => {
    changeStatus({ id, status })
    setIsOpenEditStatus(null)
  }, [changeStatus])

  const onCellMailHandler = useCallback((record: AgreementInvoicesType, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        dispatch(setSendEmail({
          type: SendEmailType.AgreementInvoices,
          companyId: record.agreement.customer.id,
          id: record.id,
          isAccountant,
        }))
      }
    },
  }), [dispatch])

  const columns = useMemo<ColumnProps<AgreementInvoicesType>[]>(() => [
    {
      title: t('agreementInvoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      width: 100,
      align: 'center',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      width: 100,
      align: 'center',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.customer'),
      dataIndex: 'agreement',
      key: 'customer',
      width: 200,
      render: (agreement) => agreement.customer.name,
      onCell,
    },
    {
      title: t('agreementInvoices.columns.car'),
      dataIndex: 'agreement',
      key: 'car',
      width: 200,
      align: 'center',
      render: (agreement) => `${agreement.car.make} ${agreement.car.model} (${agreement.car.serial_number})`,
      onCell,
    },
    {
      title: t('agreementInvoices.columns.number'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      width: 120,
      align: 'center',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.price'),
      dataIndex: 'price_month',
      key: 'price_month',
      width: 120,
      align: 'center',
      render: (priceMonth) => `${priceMonth}€`,
      onCell,
    },
    {
      title: t('agreementInvoices.columns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'center',
      render: (status, record) =>
        (
          <Popover
            content={(
              <div>
                <div>
                  <Button
                    type="text"
                    onClick={() => updateStatus(record.id, AgreementInvoicesStatus.notProcessed)}
                  >
                    {t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`)}
                  </Button>
                </div>
                <div>
                  <Button
                    type="text"
                    onClick={() => updateStatus(record.id, AgreementInvoicesStatus.processed)}
                  >
                    {t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`)}
                  </Button>
                </div>
                <div>
                  <Button
                    type="text"
                    onClick={() => updateStatus(record.id, AgreementInvoicesStatus.archived)}
                  >
                    {t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`)}
                  </Button>
                </div>
              </div>
            )}
            trigger="click"
            placement="bottom"
            open={isOpenEditStatus === record.id}
            onOpenChange={() => setIsOpenEditStatus(record.id)}
          >
            <Tag style={{ cursor: 'pointer' }} color={statusColor[status]}>{t(`agreementInvoices.status.${status}`)}</Tag>
          </Popover>
        ),
    },
    {
      title: t('agreementInvoices.columns.sentAccountant'),
      dataIndex: 'isSentAccountant',
      key: 'isSentAccountant',
      width: 120,
      align: 'center',
      className: style.sentCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record, true),
    },
    {
      title: t('agreementInvoices.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      width: 120,
      align: 'center',
      className: style.sentCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <AgreementInvoicesActions record={record} />
      ),
    },
  ], [t, onCell, onCellMailHandler, paramsFilters.page, data?.meta.pageSize, isOpenEditStatus, updateStatus])

  return {
    columns,
    paramsFilters,
    setParamsFilters,
    data,
    isLoading,
  }
}
