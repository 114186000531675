import { agreementsApi } from '@src/store/services/agreements-service'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { AgreementBillStatus, AgreementBillType } from '@src/types/agreementBills'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { ColumnProps } from 'antd/lib/table'
import { Button, Popconfirm, Popover, Tag } from 'antd'
import style from '@src/Pages/AgreementBills/agreementBills.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EuroOutlined, MailOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { isSelectionText } from '@src/lib/utils'

const statusColor: any = {
  1: 'blue',
  2: 'green',
  3: 'red',
}

type AgreementBillsActionsProps = {
  record: AgreementBillType
}

export const AgreementBillsActions: FC<AgreementBillsActionsProps> = ({ record }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const [deleteAgreementBill, { isLoading: isLoadingDelete }] = agreementsApi.useDeleteAgreementBillMutation()

  return (
    <div className={style.actions}>
      <a
        // eslint-disable-next-line max-len
        href={`${process.env.REACT_APP_API_URL}/api/agreements/generate-bill/${record.id}?access_token=${localStorage.getItem('token')}`}
        target="_blank"
        rel="noreferrer"
        className={style.actions_item}
      >
        <EuroOutlined />
      </a>
      <button
        type="button"
        onClick={() => {
          dispatch(setSendEmail({
            type: SendEmailType.AgreementBills,
            companyId: record.agreement.customer.id,
            id: record.id,
          }))
        }}
        className={style.actions_item}
      >
        <MailOutlined />
      </button>
      <Popconfirm
        placement="left"
        title={t('popconfirm.deleteTitle')}
        onConfirm={() => { deleteAgreementBill(record.id) }}
        okText={t('popconfirm.okText')}
        cancelText={t('popconfirm.cancelText')}
        disabled={isLoadingDelete}
      >
        <button
          type="button"
          className={cn(style.actions_item, style.actions_item__delete)}
          disabled={isLoadingDelete}
        >
          <DeleteOutlined />
        </button>
      </Popconfirm>
    </div>
  )
}

export const useAgreementBillsTable = ({ initParamsFilters }: any) => {
  const { t } = useTranslation()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = agreementsApi.useGetListAgreementBillsQuery(paramsFilters)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const [isOpenEditStatus, setIsOpenEditStatus] = useState<null | number>(null)
  const [changeStatus] = agreementsApi.useChangeStatusAgreementBillMutation()

  const updateStatus = useCallback((id: number, status: AgreementBillStatus) => {
    changeStatus({ id, status })
    setIsOpenEditStatus(null)
  }, [changeStatus])

  const onCell = useCallback((record: AgreementBillType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-bills/edit/${record.id}`, { backUrl: location.pathname + location.search })
      }
    },
  }), [history, location])

  const onCellMailHandler = useCallback((record: AgreementBillType, isAccountant?: boolean) => ({
    onClick: () => {
      if (!isSelectionText()) {
        dispatch(setSendEmail({
          type: SendEmailType.AgreementBills,
          companyId: record.agreement.customer.id,
          id: record.id,
          isAccountant,
        }))
      }
    },
  }), [dispatch])

  const columns = useMemo<ColumnProps<AgreementBillType>[]>(() => [
    {
      title: t('agreementBills.columns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('agreementBills.columns.date'),
      dataIndex: 'agreement',
      key: 'dateEnd',
      width: 120,
      align: 'center',
      render: (agreement) => `${agreement.date_end}`,
      onCell,
    },
    {
      title: t('agreementBills.columns.customer'),
      dataIndex: 'agreement',
      key: 'customer',
      width: 200,
      render: (agreement) => agreement.customer.name,
      onCell,
    },
    {
      title: t('agreementsCreate.car'),
      dataIndex: 'agreement',
      key: 'car',
      width: 200,
      align: 'center',
      render: (agreement) => `${agreement.car.make} ${agreement.car.model} (${agreement.car.serial_number})`,
      onCell,
    },
    {
      title: t('agreementBills.columns.billNumber'),
      dataIndex: 'bill_number',
      key: 'bill_number',
      width: 120,
      align: 'center',
      onCell,
    },
    {
      title: t('agreementBills.columns.price'),
      dataIndex: 'price',
      key: 'price',
      width: 120,
      align: 'center',
      render: (price) => `${price}€`,
      onCell,
    },
    {
      title: t('agreementBills.columns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'center',
      render: (status, record) => (
        <Popover
          content={(
            <div>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record.id, AgreementBillStatus.notProcessed)}
                >
                  {t(`agreementBills.status.${AgreementBillStatus.notProcessed}`)}
                </Button>
              </div>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record.id, AgreementBillStatus.processed)}
                >
                  {t(`agreementBills.status.${AgreementBillStatus.processed}`)}
                </Button>
              </div>
              <div>
                <Button
                  type="text"
                  onClick={() => updateStatus(record.id, AgreementBillStatus.archived)}
                >
                  {t(`agreementBills.status.${AgreementBillStatus.archived}`)}
                </Button>
              </div>
            </div>
          )}
          trigger="click"
          placement="bottom"
          open={isOpenEditStatus === record.id}
          onOpenChange={() => setIsOpenEditStatus(record.id)}
        >
          <Tag color={statusColor[status]}>{t(`agreementInvoices.status.${status}`)}</Tag>
        </Popover>
      ),
    },
    {
      title: t('agreementBills.columns.sentAccountant'),
      dataIndex: 'isSentAccountant',
      key: 'isSentAccountant',
      width: 120,
      align: 'center',
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record, true),
    },
    {
      title: t('agreementBills.columns.sentCustomer'),
      dataIndex: 'isSentCustomer',
      key: 'isSentCustomer',
      width: 120,
      align: 'center',
      className: style.sentCustomer,
      render: (isSentAccountant) => (
        <Tag color={isSentAccountant ? 'green' : 'red'}>
          {t(`sendStatus.${isSentAccountant}`)}
        </Tag>
      ),
      onCell: (record) => onCellMailHandler(record),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <AgreementBillsActions record={record} />
      ),
    },
  ], [data?.meta.pageSize, isOpenEditStatus, onCell, onCellMailHandler, paramsFilters.page, t, updateStatus])

  return {
    data,
    isLoading,
    paramsFilters,
    setParamsFilters,
    columns,
  }
}
