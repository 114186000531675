import { Button, Card, Col, Descriptions, Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import React, { useCallback, useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner'

import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { detailsApi } from '@src/store/services/details-service'
import style from '@src/Pages/Test/DetailTransfer/detail-transfer.module.scss'
import { DetailsCreate } from '@src/Pages/Service/Details/DetailsCreate'
import { throttle } from 'lodash'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { ArrowLeftOutlined } from '@ant-design/icons'

export function GetDetail() {
  const svgRef = React.useRef(null)
  const { t } = useTranslation()
  const history = useHistory()
  const { data: user } = authApi.useGetMeQuery()
  const [isScanPaused, setIsScanPaused] = useState(false)
  const [scannedSerial, setScannedSerial] = useState('')
  const [isDetailScanned, setIsDetailScanned] = useState(false)
  const [isMissingSerial, setIsMissingSerial] = useState(false)

  const [trigger, { data: details, isLoading }] = detailsApi.useLazyGetDetailsListQuery()
  const [takeDetail, { isLoading: isLoadingTakeDetail }] = detailsApi.useTakeDetailMutation()
  const [, { isLoading: isLoadingPutDetail }] = detailsApi.usePutDetailMutation()
  const [changeDetailCount, { isLoading: isLoadingChangeDetailCount }] = detailsApi.useChangeDetailCountMutation()

  const goBack = () => {
    if (!isDetailScanned) {
      history.goBack()
    } else {
      setScannedSerial('')
      setIsDetailScanned(false)
      setIsScanPaused(false)
    }
  }

  const initialValues = useMemo(() => ({
    count: 0,
  }), [])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      count: Yup.number().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const onSubmit = useCallback(async (data: any) => {
    const { count } = data

    const detail = details?.data[0]

    if (detail) {
      if (user?.role === UserRoles.StoreManager || user?.role === UserRoles.ServiceManager) {
        const result = await changeDetailCount({
          serial_number: detail.serial_number,
          count,
        })

        if ('data' in result) {
          history.push('/details')
        }
      } else {
        const result = await takeDetail({
          serial_number: detail.serial_number,
          count,
        })

        if ('data' in result) {
          history.push('/mechanic/my-details')
        }
      }
    }
  }, [details?.data, history, takeDetail, user, changeDetailCount])

  const detailDescription = useMemo(() => {
    const detail = details?.data[0]

    return (
      [
        {
          key: '1',
          label: t('detailTransfer.description.title') ?? '-',
          children: detail?.name ?? '-',
        },
        {
          key: '2',
          label: t('detailTransfer.description.serialNumber') ?? '-',
          children: detail?.serial_number ?? '-',
        },
        {
          key: '3',
          label: t('detailTransfer.description.count') ?? '-',
          children: detail?.count ?? '-',
        },
      ]
    )
  }, [details, t])

  const onClick = (e: any) => {
    if (e.target.dataset?.serialNumber) {
      setScannedSerial(e.target.dataset?.serialNumber)

      trigger({ page: 1, filters: { serialNumbers: { serial_number: { $eq: e.target.dataset?.serialNumber } } } }).then(({ data }) => {
        if (data?.data[0]) setIsDetailScanned(true)
        else setIsMissingSerial(true)
      })
    }
  }

  const centerTextThrottle = useMemo(() => throttle((fn: any) => {
    fn()
  }, 500), [])

  const content = useMemo(() => {
    const detail = details?.data[0]

    if (!isDetailScanned) {
      return (
        <>
          <Scanner
            onScan={() => {}}
            paused={isScanPaused}
            formats={['code_39', 'code_128']}
            styles={{
              container: {
                width: '500px',
                height: '500px',
              },
              video: {
                width: '500px',
                height: '500px',
              },
            }}
            components={{
              audio: false,
              onOff: false,
              torch: false,
              zoom: false,
              finder: true,
              tracker: (tt) => {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                centerTextThrottle(() => centerText2(tt, svgRef.current as any))
              },
            }}
          >
            <svg
              ref={svgRef}
              width="100%"
              height="100%"
              style={{ position: 'absolute', top: 0, left: 0 }}
              onClick={(e) => onClick(e)}
            />
          </Scanner>
          {/* <Button
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            style={{ marginTop: 24 }}
            onClick={() => setIsScanPaused(false)}
          >
            {t('getDetail.scan')}
          </Button> */}
        </>
      )
    }

    if (detail) {
      return (
        <Preloader loading={isLoading}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            <Form className={style.form}>
              <>
                <Row>
                  <Col span={24}>
                    <Descriptions items={detailDescription} />
                  </Col>
                </Row>
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={4}>
                    <FieldFormik
                      name="count"
                      type="number"
                    />
                  </Col>
                </Row>
              </>
              <Button
                htmlType="submit"
                type="primary"
                disabled={isLoading || isLoadingTakeDetail || isLoadingPutDetail || isLoadingChangeDetailCount}
              >
                {[UserRoles.StoreManager, UserRoles.ServiceManager].includes(user?.role!) ? t('getDetail.add') : t('getDetail.take')}
              </Button>
            </Form>
          </Formik>
        </Preloader>
      )
    }

    return <DetailsCreate scannedSerial={scannedSerial} />
    // eslint-disable-next-line
  }, [centerTextThrottle, detailDescription, details?.data, initialValues, isDetailScanned, isLoading, isLoadingTakeDetail, isScanPaused, onSubmit, scannedSerial, t, validationSchema, isLoadingPutDetail, isLoadingChangeDetailCount])

  return (
    <div>
      <Modal
        title={t('getDetail.modal.title') ?? ''}
        open={isMissingSerial}
        onOk={() => {
          setIsDetailScanned(true)
          setIsMissingSerial(false)
        }}
        onCancel={() => {
          setIsScanPaused(false)
          setIsMissingSerial(false)
        }}
      >
        {t('getDetail.modal.description') ?? ''}
      </Modal>
      <Card
        title={[UserRoles.StoreManager, UserRoles.ServiceManager].includes(user?.role!) ? t('getDetail.addTitle') : t('getDetail.title')!}
      >
        <Button type="link" onClick={goBack} icon={<ArrowLeftOutlined />}>{t('common.goBack')}</Button>
        <br />
        <br />
        {content}
      </Card>
    </div>
  )
}

export function centerText2(detectedCodes: IDetectedBarcode[], svg: HTMLElement) {
  while (svg?.firstChild) {
    svg.removeChild(svg.firstChild)
  }

  detectedCodes.forEach((detectedCode) => {
    const { boundingBox, rawValue } = detectedCode
    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2
    const fontSize = Math.max(12, (50 * boundingBox.width) / svg?.clientWidth)

    let formattedText
    try {
      formattedText = JSON.stringify(JSON.parse(rawValue), null, 2)
    } catch {
      formattedText = rawValue
    }

    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text')
    text.setAttribute('font-size', fontSize.toString())
    text.setAttribute('font-family', 'sans-serif')
    text.textContent = formattedText
    svg?.appendChild(text)
    const { width: textWidth } = text.getBBox()

    const textHeight = fontSize * 1.2
    const padding = 10
    const rectX = centerX - textWidth / 2 - padding
    const rectY = centerY - textHeight / 2 - padding
    const rectWidth = textWidth + padding * 2
    const rectHeight = textHeight + padding
    const radius = 8

    text.setAttribute('x', (rectX + padding).toString())
    text.setAttribute('y', (rectY + textHeight).toString())
    text.setAttribute('data-serial-number', formattedText)

    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
    rect.setAttribute('x', rectX.toString())
    rect.setAttribute('y', rectY.toString())
    rect.setAttribute('width', rectWidth.toString())
    rect.setAttribute('height', rectHeight.toString())
    rect.setAttribute('rx', radius.toString())
    rect.setAttribute('ry', radius.toString())
    rect.setAttribute('fill', 'rgba(255, 255, 0, 0.9)')
    rect.setAttribute('data-serial-number', formattedText)
    text.before(rect)
  })
}
