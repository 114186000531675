import React, { FC, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Button, Col, Drawer, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SignatureFormik } from '@src/components/SignatureFormik/SignatureFormik'
import { transportationApi } from '@src/store/services/transportation-service'
import { Preloader } from '@src/components/Preloader/Preloader'

type TransportationSignProps = {
  onClose: () => void
  transportationId: number | null
}

export const TransportationSign: FC<TransportationSignProps> = ({ onClose, transportationId }) => {
  const { t } = useTranslation()
  const [signTransportation, { isLoading }] = transportationApi.useSignTransportationMutation()
  const { data, isLoading: isLoadingGetOne } = transportationApi.useGetOneTransportationQuery(transportationId!, { skip: !transportationId })

  const validationSignSchema = useMemo(
    () =>
      Yup.object().shape({
        customer_name: Yup.string().required(t('form.errors.required') ?? ''),
        customer_surname: Yup.string().required(t('form.errors.required') ?? ''),
        signature: Yup.string().required(t('form.errors.required') ?? ''),
      }),
    [t],
  )

  const initialValues = useMemo(() => ({
    customer_name: data?.customer_name || '',
    customer_surname: data?.customer_surname || '',
    signature: '',
  }), [data])

  const onSubmit = async (body: any) => {
    const result = await signTransportation({
      id: transportationId!,
      body,
    })

    if ('data' in result) {
      onClose()
    }
  }

  return (
    <Drawer
      title={t('transportationCreate.customerSign')}
      open={!!transportationId}
      onClose={onClose}
      width={1000}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSignSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="customer_name"
                    placeholder={t('transportationCreate.customerName') ?? ''}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik
                    name="customer_surname"
                    placeholder={t('transportationCreate.customerSurname') ?? ''}
                  />
                </Col>
                <Col span={24}>
                  <SignatureFormik name="signature" placeholder={t('transportationCreate.signature') ?? ''} />
                </Col>
              </Row>

              <Button htmlType="submit" type="primary" loading={isLoading}>
                {t('transportationCreate.sign')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </Drawer>
  )
}
