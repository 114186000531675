import { User } from '@src/types/users'

export enum DetailUnitEnum {
  Kg = 'kg',
  L = 'l',
  Vnt = 'vnt'
}

export type DetailType = {
  id: number
  from_service: boolean
  name: string
  price: number
  serial_number: string
  serial_numbers: Array<{
    serial_number: string
  }>
  service_id: string
  unit: DetailUnitEnum
  vat: number
  vat_price: number
  count: number
  stock_area_id: number
  images: Array<{
    id: number
    image: string
    thumb: string
  }>
}

export type DetailShortType = {
  id: number
  name: string
  serial_number: string
}

type Storage = {
  id: number
  user_id: number
  detail_id: number
  count: number
  created_at: string
  updated_at: string
}

export type UserDetailType = {
  id: number
  serial_number: string
  name: string
  serial_numbers: Array<{
    serial_number: string
  }>
  storages: Storage[]
}

export const REQUEST_STATUSES = {
  Created: 'created',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Closed: 'closed',
} as const

export type DetailTransferRequest = {
  count: number
  created_at: string
  detail_id: number
  from_id: number
  id: number
  status: (typeof REQUEST_STATUSES)[keyof typeof REQUEST_STATUSES]
  to_id: number
  updated_at: string
  user_id: number
  from: User
  to: User
}
