import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AgreementInvoicesStatus } from '@src/types/agreementInvoices'
import { useHistory } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { TableFiltersType } from '@src/types/tableFilters'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { carsApi } from '@src/store/services/cars-service'
import { PageTable } from '@src/components/Table/PageTable'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useAgreementInvoicesTableHook } from '@src/components/pages/agreementInvoices/AgreementInvoicesTable.hook'

export const AgreementInvoices = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const { data: customerNamesList } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const [exportAgreementInvoices, { isLoading: isLoadingExport }] = agreementsApi.useExportAgreementInvoicesMutation()

  const { columns, paramsFilters, setParamsFilters, isLoading, data } = useAgreementInvoicesTableHook({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: filtersQuery ? JSON.parse(filtersQuery) : null,
    },
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'customer_id',
      placeholder: t('agreementInvoices.columns.customer'),
      width: '200px',
      options: customerNamesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'make',
      placeholder: t('agreementBills.columns.make'),
      width: '200px',
      options: makesList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'model',
      placeholder: t('agreementBills.columns.model'),
      width: '200px',
      options: modelsList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'serial_number',
      placeholder: t('agreementBills.columns.serialNumber'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'bill_number',
      placeholder: t('agreementInvoices.columns.number'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'status',
      placeholder: t('agreementInvoices.columns.status'),
      width: '200px',
      options: [
        {
          value: AgreementInvoicesStatus.notProcessed,
          label: t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`),
        },
        {
          value: AgreementInvoicesStatus.processed,
          label: t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`),
        },
        {
          value: AgreementInvoicesStatus.archived,
          label: t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'isSentAccountant',
      placeholder: t('agreementInvoices.columns.sentAccountant'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'isSentCustomer',
      placeholder: t('agreementInvoices.columns.sentCustomer'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'owner_id',
      placeholder: t('cars.columns.owner'),
      width: '200px',
      options: ownersSelect?.map((owner) => ({ value: owner.id, label: owner.name })) || [],
    },
    {
      type: 'rangePicker',
      dataIndex: 'date_start',
      placeholder: [t('filters.dateStartFrom'), t('filters.dateStartTo')],
    },
    {
      type: 'rangePicker',
      dataIndex: 'date_end',
      placeholder: [t('filters.dateEndFrom'), t('filters.dateEndTo')],
    },
  ], [customerNamesList, makesList, modelsList, ownersSelect, t])

  return (
    <div>
      <PageTable
        title={t('agreementInvoices.title')!}
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        onExport={exportAgreementInvoices}
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading,
          columns,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => <tr {...props} style={{ background: data?.data.find((item) => item.id === props['data-row-key'])?.agreement.owner.color }} />,
            },
          },
        }}
      />
    </div>
  )
}
