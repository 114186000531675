import { DualAxesConfig } from '@ant-design/plots/es/components/dual-axes'
import { LineConfig } from '@ant-design/plots/es/components/line'
import { PieConfig } from '@ant-design/plots/es/components/pie'
import i18n from 'i18next'
import { ColumnConfig } from '@ant-design/plots/es/components/column'
import { degreesToRadians } from '@src/lib/converter'

export const configColumn: Omit<ColumnConfig, 'data'> = {
  xField: 'name',
  yField: 'value',
  label: {
    position: 'middle',
    autoRotate: false,
    rotate: degreesToRadians(90),
    style: {
      fill: '#FFFFFF',
      opacity: 1,
    },
  },
  seriesField: 'type',
  isGroup: true,
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: true,
    },
  },
  meta: {
    name: {
      alias: '类别',
    },
    value: {
      alias: i18n.t('statistics.income') as string,
    },
  },
  columnStyle: {
    radius: [6, 6, 0, 0],
  },
}

export const configLine: Omit<LineConfig, 'data'> = {
  xField: 'date',
  yField: 'priceSum',
  seriesField: 'category',
  tooltip: {
    // fields: ['category', 'date', 'price', 'priceSum'],
    /* customContent: (title: string, data: any) => {
      console.log(title, data)
      return <div>{title}</div>
    }, */
    // formatter: (datum: any) => ({ name: datum.category, value: `${datum.priceSum}€` }),
  },
  xAxis: {
    type: 'time',
  },
  yAxis: {
    label: {
      formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    },
  },
}

export const configDualAxes: Omit<DualAxesConfig, 'data'> = {
  xField: 'month',
  yField: ['profit', 'spendings'],
  geometryOptions: [
    {
      geometry: 'line',
      color: '#5B8FF9',
    },
    {
      geometry: 'line',
      color: '#5AD8A6',
    },
  ],
/*  yAxis: {
    label: {
      formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    },
  }, */
}

export const configPie: Omit<PieConfig, 'data'> = {
  appendPadding: 10,
  angleField: 'value',
  colorField: 'name',
  radius: 0.8,
  label: {
    type: 'outer',
    content: '{name} {percentage}',
  },
  interactions: [
    {
      type: 'pie-legend-active',
    },
    {
      type: 'element-active',
    },
  ],
}
