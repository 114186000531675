import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { Car, CarForSelect, StoreFileRequest } from '@src/types/car'
import { formatFilters } from '@src/lib/formatFilters'

export const carsApi = createApi({
  reducerPath: 'carsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['cars'],
  endpoints: (build) => ({
    getCarsList: build.query<GetItemsType<Car>, any>({
      query: (params) => ({
        url: `/cars?${formatFilters(params)}`,
      }),
      providesTags: () => ['cars'],
    }),
    getCarsMakes: build.query<string[], void>({
      query: () => ({
        url: '/car-makes',
      }),
      providesTags: () => ['cars'],
    }),
    getCarsModels: build.query<string[], void>({
      query: () => ({
        url: '/car-models',
      }),
      providesTags: () => ['cars'],
    }),
    getCarsForSelect: build.query<CarForSelect[], {agreements_id?: number}>({
      query: (params) => ({
        url: '/agreements/get-cars',
        params,
      }),
      providesTags: () => ['cars'],
    }),
    deleteCar: build.mutation<void, number>({
      query: (id) => ({
        url: `/cars/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['cars'],
    }),
    getOneCar: build.query<Car, number>({
      query: (id) => ({
        url: `/cars/${id}`,
      }),
      providesTags: () => ['cars'],
    }),
    createCar: build.mutation<Car, any>({
      query: (body) => ({
        url: '/cars',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['cars'],
    }),
    editCar: build.mutation<Car, any>({
      query: ({ id, formData }) => ({
        url: `/cars/${id}?_method=PUT`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['cars'],
    }),
    exportCars: build.mutation<void, number[]>({
      query: (carIds) => ({
        url: '/cars/excel-export',
        method: 'POST',
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        body: {
          carIds,
        },
      }),
    }),
    storeFile: build.mutation<void, StoreFileRequest>({
      query: ({ body, ...params }) => ({
        url: '/store/image',
        method: 'POST',
        body,
        params,
      }),
      invalidatesTags: ['cars'],
    }),
    deleteImage: build.mutation<void, number>({
      query: (id) => ({
        url: `/delete/image/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['cars'],
    }),
  }),
})
