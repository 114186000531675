import { Car } from '@src/types/car'
import { AgreementInvoicesType } from '@src/types/agreementInvoices'
import { Company } from '@src/types/company'

export type CarImages = {
  [key: string]: Array<{
    id: number
    image: string
    thumb: string
    order: number
  }>
}

export enum AgreementTermTypeEnum {
  Months = 'mėn',
  Days = 'd.',
  Hours = 'val',
}

export type Agreement = {
  id: number
  created_at: string
  car_id: number
  owner_id: number
  customer_id: number
  customer_representative: string
  customer_representative_position: string
  owner_representative: string
  owner_representative_position: string
  insurance: string
  minimum_rent: string
  payment_deadline: string
  lease_term: string
  max_engine_hours: string
  engine_hour_cost: string
  rent_place: string
  car_value: string
  engine_hours_start: string
  engine_hours_end: string
  equipment: string
  services: string
  date_start: string
  date_end: string
  date_created: string
  time_rent: string
  comment: string
  comment_close: string
  status: number
  is_tank_full: number
  term_type: AgreementTermTypeEnum
  bill_number: string
  car: Car
  customer: Company
  images: CarImages
  invoices: AgreementInvoicesType[]
  owner: Company
  attachments: {
    id: number
    file: string
    filename: string
  }[]
  specials?: Array<{
    id: number
    name: string
    hours: number
    price_hour: number
    total_price: number
  }>
}
