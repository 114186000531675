import {
  FC, useCallback, useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Button, Col, ColorPicker, Row } from 'antd'
import { Preloader } from '@src/components/Preloader/Preloader'
import { companiesApi } from '@src/store/services/companies-service'
import { CustomerWorkers } from '@src/components/CustomerWorkers/CustomerWorkers'
import { CompanyType } from '@src/types/company'
import style from './ownerCreate.module.scss'

type OwnerCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
}

export const OwnerCreate: FC<OwnerCreateProps> = ({ isModal, successCallbackModal }) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const [createOwner, { isLoading: isLoadingCreate }] = companiesApi.useCreateCompanyMutation()
  const [editOwner, { isLoading: isLoadingEdit }] = companiesApi.useEditCompanyMutation()
  const { data, isLoading } = companiesApi.useGetOneCompanyQuery(params.id ? +params.id : 0, { skip: !params.id || isModal })

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      company_code: Yup.string().required(t('form.errors.required') ?? ''),
      vat_number: Yup.string().required(t('form.errors.required') ?? ''),
      address: Yup.string().required(t('form.errors.required') ?? ''),
      account_number: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: data?.name ?? '',
    company_code: data?.company_code ?? '',
    vat_number: data?.vat_number ?? '',
    address: data?.address ?? '',
    account_number: data?.account_number ?? '',
    color: data?.color ?? '',
    type: CompanyType.Owner,
  }), [data])

  const successCallback = useCallback(() => {
    if (successCallbackModal) {
      successCallbackModal()
    } else {
      history.goBack()
    }
  }, [history, successCallbackModal])

  const onSubmit = useCallback(async (values: any) => {
    let result
    if (params.id && !isModal) {
      result = await editOwner({ ...values, id: +params.id })
    } else {
      result = await createOwner(values)
    }

    if ('data' in result) {
      successCallback()
    }
  }, [createOwner, editOwner, isModal, params.id, successCallback])

  return (
    <div>
      {!isModal && (
        <h1 className="page_title">
          {params.id ? t('ownerCreate.titleEdit') : t('ownerCreate.title')}
        </h1>
      )}
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className={style.form}>
              <Row gutter={[20, 0]}>
                <Col xs={24} md={12}>
                  <FieldFormik name="name" placeholder={t('ownerCreate.name') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="company_code" placeholder={t('ownerCreate.code') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="vat_number" placeholder={t('ownerCreate.vatNumber') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="account_number" placeholder={t('ownerCreate.accountNumber') ?? ''} />
                </Col>
                <Col xs={24} md={12}>
                  <FieldFormik name="address" placeholder={t('ownerCreate.address') ?? ''} />
                </Col>
                <Col xs={24} md={24}>
                  <ColorPicker
                    value={values.color}
                    onChangeComplete={(color) => setFieldValue('color', color.toHexString())}
                  />
                </Col>
              </Row>

              <Button
                style={{ marginTop: 16 }}
                htmlType="submit"
                type="primary"
                loading={isLoadingCreate || isLoadingEdit}
              >
                {params.id && !isModal ? t('ownerCreate.save') : t('ownerCreate.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
      {params.id && (
        <CustomerWorkers companyId={+params.id} />
      )}
    </div>
  )
}
