import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'antd'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import * as Yup from 'yup'
import { standardWorkApi } from '@src/store/services/standard-work-service'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { UNIT } from '@src/types/standardWork'

type StandardWorkCreatePropsType = {
  onClose?: () => void;
}

export const StandardWorkCreate = ({ onClose }: StandardWorkCreatePropsType) => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const location = useLocation<any>()
  const { data, isLoading } = standardWorkApi.useGetOneQuery(params.id || '', { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = standardWorkApi.useCreateMutation()
  const [update, { isLoading: isLoadingUpdate }] = standardWorkApi.useUpdateMutation()

  const initialValues = useMemo(() => ({
    name: data?.name || '',
    price_working_hour: data?.price_working_hour || '',
    price_non_working_hour: data?.price_non_working_hour || '',
    tax: data?.tax || '',
    unit: data?.unit || '',
  }), [data])

  const onSubmit = async (values: any) => {
    const requestData = {
      ...values,
    }

    let result
    if (params.id) {
      result = await update({
        ...requestData,
        id: params.id,
      })
    } else {
      result = await create(requestData)
    }

    if ('data' in result) {
      if (location.state?.backUrl) {
        history.push(`${location.state.backUrl}`)
      } else {
        history.goBack()
      }
    }
  }

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      price_working_hour: Yup.number().required(t('form.errors.required') ?? ''),
      price_non_working_hour: Yup.number().required(t('form.errors.required') ?? ''),
      tax: Yup.number().required(t('form.errors.required') ?? ''),
      unit: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  return (
    <div>
      <h1 className="page_title">{params.id ? t('standardWorkCreate.titleEdit') : t('standardWorkCreate.title')}</h1>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {() => (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <FieldFormik
                    name="name"
                    placeholder={t('standardWork.columns.name') ?? ''}
                  />
                </Col>
                <Col span={12}>
                  <FieldFormik
                    type="number"
                    name="price_working_hour"
                    placeholder={t('standardWork.columns.priceWorkingHour') ?? ''}
                  />
                </Col>
                <Col span={12}>
                  <FieldFormik
                    type="number"
                    name="price_non_working_hour"
                    placeholder={t('standardWork.columns.priceNonWorkingHour') ?? ''}
                  />
                </Col>
                <Col span={12}>
                  <FieldFormik
                    name="tax"
                    placeholder={t('standardWork.columns.tax') ?? ''}
                  />
                </Col>
                <Col span={12}>
                  <SelectFormik
                    name="unit"
                    showSearch
                    placeholder={t('standardWork.columns.unit') ?? ''}
                    options={Object.keys(UNIT).map((unit) => {
                      const unitKey = unit as keyof typeof UNIT

                      return ({ value: UNIT[unitKey], label: t(`standardWorkCreate.unit.${UNIT[unitKey]}`) })
                    })}
                  />
                </Col>
              </Row>
              <Button
                style={{ marginTop: 20 }}
                htmlType="submit"
                type="primary"
                loading={isLoadingCreate || isLoadingUpdate}
              >
                {t('standardWorkCreate.submit')}
              </Button>
              {onClose && (
                <Button
                  style={{ marginTop: 20, marginLeft: 12 }}
                  htmlType="button"
                  type="default"
                  loading={isLoadingCreate || isLoadingUpdate}
                  onClick={onClose}
                >
                  {t('standardWorkCreate.close')}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
