import React, {
  useEffect, useMemo, useState,
} from 'react'
import { ColumnProps } from 'antd/lib/table'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { TableFiltersType } from '@src/types/tableFilters'
import { Car } from '@src/types/car'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { carsApi } from '@src/store/services/cars-service'
import { PageTable } from '@src/components/Table/PageTable'

export function CarsMechanic() {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: makesList } = carsApi.useGetCarsMakesQuery()
  const { data: modelsList } = carsApi.useGetCarsModelsQuery()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : {},
  })
  const { data, isLoading } = carsApi.useGetCarsListQuery(paramsFilters)

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const columns = useMemo<ColumnProps<Car>[]>(() => [
    {
      title: t('cars.columns.make'),
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: t('cars.columns.model'),
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: t('cars.columns.year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('cars.columns.serialNumber'),
      dataIndex: 'serial_number',
      key: 'serial_number',
    },
    {
      title: t('cars.columns.engineHours'),
      dataIndex: 'engine_hours',
      key: 'engine_hours',
    },

    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <NavLink to={`/mechanic/cars/${record.id}`}>
          <EyeOutlined />
        </NavLink>
      ),
    },
  ], [t])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'make',
      placeholder: t('cars.columns.make'),
      operator: '$eq',
      width: '200px',
      options: makesList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'select',
      dataIndex: 'model',
      placeholder: t('cars.columns.model'),
      operator: '$eq',
      width: '200px',
      options: modelsList?.map((item) => ({ value: item, label: item })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'serial_number',
      placeholder: t('cars.columns.serialNumber'),
      operator: '$contains',
      width: '200px',
    },
  ], [makesList, modelsList, t])

  return (
    <div>
      <PageTable
        title={t('cars.title')!}
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        filtersV2
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
    </div>
  )
}
