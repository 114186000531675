import i18n from 'i18next'
import { notification } from 'antd'
import { AppDispatch } from '@src/store/store'
import { carsApi } from '@src/api/cars-api'
import {
  setIsLoadingCreate,
} from '@src/store/ducks/cars/reducer'

export const carAddImage = (data: any, id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setIsLoadingCreate(true))

    if (data.fileList.length) {
      const filesPromise = data.fileList.map((item: any) => {
        const formData = new FormData()
        formData.append('image', item.originFileObj)

        return carsApi.storeFile(formData, id)
      })
      await Promise.all(filesPromise)
    }
  } catch (err: any) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description: `${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(setIsLoadingCreate(false))
  }
}
