import { useTranslation } from 'react-i18next'
import { PageTable } from '@src/components/Table/PageTable'
import { usersApi } from '@src/store/services/users-service'
import React, { useCallback, useMemo, useState } from 'react'
import useQuery from '@src/hooks/useQuery'
import { ColumnProps } from 'antd/lib/table'
import { User } from '@src/types/users'
import { useHistory } from 'react-router-dom'
import style from '@src/Pages/AgreementInvoices/agreementInvoices.module.scss'
import { Popconfirm } from 'antd'
import cn from 'classnames'
import { DeleteOutlined } from '@ant-design/icons'
import { isSelectionText } from '@src/lib/utils'

export const Users = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const { data, isLoading } = usersApi.useGetListUsersQuery(paramsFilters)
  const [deleteUser, { isLoading: isLoadingDeleteUser }] = usersApi.useDeleteUserMutation()

  const onCell = useCallback((record: User) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/users/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<User>[]>(() => [
    {
      title: t('users.columns.name'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => `${name} ${record.surname}`,
      onCell,
    },
    {
      title: t('users.columns.email'),
      dataIndex: 'email',
      key: 'email',
      onCell,
    },
    {
      title: t('users.columns.phone'),
      dataIndex: 'phone',
      key: 'phone',
      onCell,
    },
    {
      title: t('users.columns.role'),
      dataIndex: 'role',
      key: 'role',
      render: (role) => t(`users.roles.${role}`),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteUser(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDeleteUser}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDeleteUser}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [t, onCell, isLoadingDeleteUser, deleteUser])

  return (
    <div>
      <PageTable
        title={t('users.title')}
        createLink="/users/create"
        setParamsFilters={setParamsFilters}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
    </div>
  )
}
