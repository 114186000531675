import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Card, Col, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Preloader } from '@src/components/Preloader/Preloader'
import { AppDispatch } from '@src/store/store'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import moment from 'moment'
import { agreementsApi } from '@src/store/services/agreements-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { AgreementInvoicesDocument } from '@src/components/pages/agreementInvoices/AgreementInvoicesDocument'
import style from './agreementInvoicesEdit.module.scss'

export const AgreementInvoicesEdit = () => {
  const { t } = useTranslation()
  const formRef = useRef<any>()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const { data: info, isLoading: isLoadingGetOne } = agreementsApi.useGetOneAgreementInvoiceQuery(+params.id)
  const [editAgreementInvoices, { isLoading }] = agreementsApi.useEditAgreementInvoiceMutation()

  const initialValues = useMemo(() => ({
    id: info?.id ?? '',
    agreement_id: info?.agreement_id ?? '',
    bill_number: info?.bill_number ?? '',
    text: info?.text ?? '',
    price_month: info?.price_month ?? '',
    isCredit: info?.isCredit ?? 0,
    date_created: info?.date_created ? moment(info?.date_created) : '',
    pay_by: info?.pay_by ? moment(info?.pay_by) : '',
    date_start: info?.date_start ? moment(info?.date_start) : '',
    date_end: info?.date_end ? moment(info?.date_end) : '',
  }), [info])

  const onSubmit = useCallback(async (data: any) => {
    const result = await editAgreementInvoices({
      id: +params.id,
      body: {
        ...data,
        pay_by: data.pay_by ? data.pay_by.format('YYYY-MM-DD') : '',
        date_created: data.date_created ? data.date_created.format('YYYY-MM-DD') : '',
        date_start: data.date_start ? data.date_start.format('YYYY-MM-DD') : '',
        date_end: data.date_end ? data.date_end.format('YYYY-MM-DD') : '',
      },
    })

    if ('data' in result) {
      if (formRef.current.values.price_month && formRef.current.values.bill_number && info) {
        dispatch(setSendEmail({
          type: SendEmailType.AgreementInvoices,
          companyId: +info.agreement.customer.id,
          id: +params.id,
        }))
      }

      history.goBack()
    }
  }, [dispatch, editAgreementInvoices, history, info, params.id])

  return (
    <div>
      <h1 className="page_title">{t('agreementInvoicesEdit.titleEdit')}</h1>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          innerRef={formRef}
        >
          {() => (
            <Row gutter={[24, 24]}>
              <Col xl={24} xxl={12}>
                <Form>
                  <Row gutter={[20, 0]}>
                    <Col xs={24} sm={12}>
                      <FieldFormik
                        name="bill_number"
                        placeholder={t('agreementInvoicesEdit.number') ?? ''}
                      />
                    </Col>
                    <Col xs={24} sm={12}>
                      <FieldFormik
                        name="price_month"
                        placeholder={t('agreementInvoicesEdit.priceMonth') ?? ''}
                      />
                    </Col>
                    <Col xs={24} sm={12}>
                      <DatePickerFormik name="date_start" placeholder={t('agreementInvoices.columns.dateStart') ?? ''} />
                    </Col>
                    <Col xs={24} sm={12}>
                      <DatePickerFormik name="date_end" placeholder={t('agreementInvoices.columns.dateEnd') ?? ''} />
                    </Col>
                    <Col span={24}>
                      <FieldFormik
                        name="text"
                        placeholder={t('agreementInvoicesEdit.text') ?? ''}
                      />
                    </Col>
                    <Col xs={24} sm={12}>
                      <DatePickerFormik name="date_created" placeholder={t('agreementInvoicesEdit.dateCreated') ?? ''} />
                    </Col>
                    <Col xs={24} sm={12}>
                      <DatePickerFormik name="pay_by" placeholder={t('agreementInvoicesEdit.payBy') ?? ''} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={12}>
                      <div style={{ marginBottom: 15 }}>
                        <span style={{ marginRight: 15 }}>{t('transportationCreate.type')}</span>
                        <RadioFormik
                          options={[
                            { value: 0, label: t('transportationCreate.basic') },
                            { value: 1, label: t('transportationCreate.credit') },
                          ]}
                          name="isCredit"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Button htmlType="submit" type="primary" loading={isLoading}>
                    {t('agreementInvoicesEdit.submit')}
                  </Button>
                  <NavLink
                    to={`/agreements/edit/${info?.agreement.id}`}
                    className={style.link}
                  >
                    {t('agreementBillsEdit.openTransportation')}
                  </NavLink>
                </Form>
              </Col>
              <Col xl={24} xxl={12}>
                <Card>
                  <AgreementInvoicesDocument />
                </Card>
              </Col>
            </Row>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
