import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Drawer, Row } from 'antd'
import { tasksApi } from '@src/store/services/tasks-service'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { AddressFormik } from '@src/components/AddressFormik/AddressFormik'
import * as Yup from 'yup'
import { EnvironmentOutlined } from '@ant-design/icons'
import { displayLocation } from '@src/lib/getLocation'

type TaskRouteCreateProps = {
  taskId: number
  isOpen: boolean
  onClose: () => void
  editId?: number
}

export const TaskRouteCreate: FC<TaskRouteCreateProps> = ({ taskId, isOpen, onClose, editId }) => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const { data } = tasksApi.useGetOneTaskRouteQuery(editId!, { skip: !editId })
  const [create, { isLoading: isLoadingCreate }] = tasksApi.useCreateTaskRouteMutation()
  const [edit, { isLoading: isLoadingEdit }] = tasksApi.useEditTaskRouteMutation()
  const [addressFrom, setAddressFrom] = useState<any>(null)
  const [addressTo, setAddressTo] = useState<any>(null)

  const validationSchema = useMemo(() => Yup.object().shape({
    address_from: Yup.string().required(t('form.errors.required') ?? ''),
    address_to: Yup.string().required(t('form.errors.required') ?? ''),
    distance: Yup.string().required(t('form.errors.required') ?? ''),
    price: Yup.string().required(t('form.errors.required') ?? ''),
  }), [t])

  const initialValues = useMemo(() => ({
    id: editId,
    task_id: taskId,
    address_from: data?.address_from || '',
    address_to: data?.address_to || '',
    distance: data?.distance || '',
    price: data?.price || '',
  }), [editId, taskId, data])

  const calculateDistance = useCallback(() => {
    if (window.google && window.google.maps) {
      const directionsService = new window.google.maps.DirectionsService()
      let origin
      if (typeof addressFrom === 'string') {
        const [lat, lng] = addressFrom.split(',')
        origin = new window.google.maps.LatLng(+lat, +lng)
      } else {
        origin = addressFrom.geometry.location
      }

      let destination
      if (typeof addressTo === 'string') {
        const [lat, lng] = addressTo.split(',')
        destination = new window.google.maps.LatLng(+lat, +lng)
      } else {
        destination = addressTo.geometry.location
      }

      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            const route = response?.routes[0]
            let totalDistance = 0

            if (route) {
              // eslint-disable-next-line no-restricted-syntax
              for (const leg of route.legs) {
                totalDistance += leg.distance?.value || 0
              }
            }

            const distanceInKilometers = Math.ceil(totalDistance / 1000)
            formRef.current.setFieldValue('distance', distanceInKilometers)
          } else {
            console.error(`Directions request failed: ${status}`)
          }
        },
      )
    }
  }, [addressFrom, addressTo])

  useEffect(() => {
    if (addressFrom && addressTo) {
      calculateDistance()
    }
  }, [addressFrom, addressTo, calculateDistance])

  const onSubmit = async (values: any) => {
    let result

    if (editId) {
      result = await edit(values)
    } else {
      result = await create(values)
    }

    if ('data' in result) {
      onClose()
    }
  }

  const handleMyLocation = async (type: 'address_from' | 'address_to') => {
    const location = await displayLocation()

    if (location) {
      const { latitude, longitude } = location
      formRef.current.setFieldValue(type, `${latitude},${longitude}`)
      if (type === 'address_to') {
        setAddressTo(`${latitude},${longitude}`)
      } else {
        setAddressFrom(`${latitude},${longitude}`)
      }
    }
  }

  return (
    <Drawer open={isOpen} onClose={onClose} width={1000}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        <Form>
          <Row gutter={[20, 0]}>
            <Col xs={24} md={12}>
              <FieldFormik
                disabled
                name="address_from"
                placeholder={t('taskRoute.columns.addressFrom') ?? ''}
              />
            </Col>
            <Col xs={24} md={12}>
              <FieldFormik
                disabled
                name="address_to"
                placeholder={t('taskRoute.columns.addressTo') ?? ''}
              />
            </Col>
            <Col xs={24} md={12}>
              <div style={{ display: 'flex' }}>
                <AddressFormik
                  onChange={(place) => setAddressFrom(place)}
                  name="address_from"
                  placeholder={t('taskRoute.columns.addressFrom') ?? ''}
                />
                <Button size="large" onClick={() => handleMyLocation('address_from')}>
                  <EnvironmentOutlined />
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div style={{ display: 'flex' }}>
                <AddressFormik
                  onChange={(place) => setAddressTo(place)}
                  name="address_to"
                  placeholder={t('taskRoute.columns.addressTo') ?? ''}
                />
                <Button size="large" onClick={() => handleMyLocation('address_to')}>
                  <EnvironmentOutlined />
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <FieldFormik
                name="distance"
                type="number"
                placeholder={t('taskRoute.columns.distance') ?? ''}
              />
            </Col>
            <Col xs={24} md={12}>
              <FieldFormik
                name="price"
                type="number"
                placeholder={t('taskRoute.columns.price') ?? ''}
              />
            </Col>
          </Row>
          <Button htmlType="submit" type="primary" loading={isLoadingCreate || isLoadingEdit} style={{ marginTop: 16 }}>
            {editId ? t('taskRoute.save') : t('taskRoute.submit')}
          </Button>
        </Form>
      </Formik>
    </Drawer>
  )
}
