import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import {
  CreateStockAreaRequest,
  CreateStockRequest, EditStockAreaRequest,
  EditStockRequest, StockAreaShortType,
  StockAreaType,
  StockType,
} from '@src/types/stocks'

export const stocksApi = createApi({
  reducerPath: 'stocksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Stocks'],
  endpoints: (build) => ({
    getStocksList: build.query<GetItemsType<StockType>, { page: number }>({
      query: (params) => ({
        url: '/stocks',
        params,
      }),
      providesTags: () => ['Stocks'],
    }),
    getOneStock: build.query<StockType, number>({
      query: (id) => ({
        url: `/stocks/${id}`,
      }),
      providesTags: () => ['Stocks'],
    }),
    createStock: build.mutation<StockType, CreateStockRequest>({
      query: (body) => ({
        url: '/stocks',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Stocks'],
    }),
    updateStock: build.mutation<StockType, EditStockRequest>({
      query: ({ id, ...body }) => ({
        url: `/stocks/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Stocks'],
    }),
    deleteStock: build.mutation<void, number>({
      query: (id) => ({
        url: `/stocks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Stocks'],
    }),

    getStockAreasList: build.query<GetItemsType<StockAreaType>, { page: number }>({
      query: (params) => ({
        url: '/stock-areas',
        params,
      }),
      providesTags: () => ['Stocks'],
    }),
    getStockAreasShortList: build.query<StockAreaShortType[], void>({
      query: () => ({
        url: '/get-short/stock-areas',
      }),
      providesTags: () => ['Stocks'],
    }),
    getOneStockArea: build.query<StockAreaType, number>({
      query: (id) => ({
        url: `/stock-areas/${id}`,
      }),
      providesTags: () => ['Stocks'],
    }),
    createStockArea: build.mutation<StockAreaType, CreateStockAreaRequest>({
      query: (body) => ({
        url: '/stock-areas',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Stocks'],
    }),
    updateStockArea: build.mutation<StockAreaType, EditStockAreaRequest>({
      query: ({ id, ...body }) => ({
        url: `/stock-areas/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Stocks'],
    }),
    deleteStockArea: build.mutation<void, number>({
      query: (id) => ({
        url: `/stock-areas/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Stocks'],
    }),
  }),
})
