import { useTranslation } from 'react-i18next'
import { FC, useCallback, useMemo, useState } from 'react'
import { Button, Popconfirm, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { CustomerWorkerCreate } from '@src/components/CustomerWorkerCreate/CustomerWorkerCreate'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { CustomerWorkerType } from '@src/types/customerWorkers'
import cn from 'classnames'
import { DeleteOutlined } from '@ant-design/icons'
import { isSelectionText } from '@src/lib/utils'
import style from './customerWorkers.module.scss'

type CustomerWorkersProps = {
  companyId: number
  notHeader?: boolean
}

export const CustomerWorkers: FC<CustomerWorkersProps> = ({ companyId, notHeader }) => {
  const { t } = useTranslation()
  const { data, isLoading } = customerWorkersApi.useListQuery({ companyId })
  const [onDelete, { isLoading: isLoadingDelete }] = customerWorkersApi.useDeleteWorkerMutation()
  const [isOpenCreate, setIsOpenCreate] = useState(false)
  const [editId, setEditId] = useState<null | number>(null)

  const onCell = useCallback((record: CustomerWorkerType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        setEditId(record.id)
        setIsOpenCreate(true)
      }
    },
  }), [])

  const columns = useMemo<ColumnProps<CustomerWorkerType>[]>(() => [
    {
      title: t('customerWorkers.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('customerWorkers.columns.email'),
      dataIndex: 'email',
      key: 'email',
      onCell,
    },
    {
      title: t('customerWorkers.columns.phone'),
      dataIndex: 'phone',
      key: 'phone',
      onCell,
    },
    {
      title: t('customerWorkers.columns.position'),
      dataIndex: 'position',
      key: 'position',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { onDelete(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDelete}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDelete}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [isLoadingDelete, onCell, onDelete, t])

  const onCloseCreate = () => {
    setIsOpenCreate(false)
    setEditId(null)
  }

  return (
    <div>
      {!notHeader && (
        <div className={style.header}>
          <h3>{t('customerWorkers.title')}</h3>
          <Button type="primary" onClick={() => setIsOpenCreate(true)}>
            {t('customerWorkers.add')}
          </Button>
        </div>
      )}

      <Table<CustomerWorkerType>
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />

      <CustomerWorkerCreate isOpen={isOpenCreate} onClose={onCloseCreate} companyId={companyId} editId={editId} />
    </div>
  )
}
