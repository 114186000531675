import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Popconfirm } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { TableFiltersType } from '@src/types/tableFilters'
import { UserRoles } from '@src/types/users'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { companiesApi } from '@src/store/services/companies-service'
import { Company, CompanyType } from '@src/types/company'
import { PageTable } from '@src/components/Table/PageTable'
import { isSelectionText } from '@src/lib/utils'
import style from './companies.module.scss'

type CompaniesProps = {
  type: CompanyType
}

export const Companies: FC<CompaniesProps> = ({ type }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : { filters: [] },
  })
  const { data, isLoading } = companiesApi.useGetCompanyListQuery({
    page: paramsFilters.page,
    filters: {
      filters: [{ columns: 'type', value: type }, ...paramsFilters.filters?.filters],
    },
  })
  const [deleteCompany, { isLoading: isLoadingDelete }] = companiesApi.useDeleteCompanyMutation()
  const [exportCompanies, { isLoading: isLoadingExport }] = companiesApi.useExportCompaniesMutation()

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: Company) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/companies/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<Company>[]>(() => [
    {
      title: t('companies.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('companies.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('companies.columns.code'),
      dataIndex: 'company_code',
      key: 'company_code',
      onCell,
    },
    {
      title: t('companies.columns.address'),
      dataIndex: 'address',
      key: 'address',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <AllowedTo roles={[UserRoles.Admin]}>
          <div className={style.actions}>
            <NavLink
              to={record.type === CompanyType.Customer ? `/customers/edit/${record.id}` : `/owners/edit/${record.id}`}
              className={cn(style.actions_item, style.actions_item__pencil)}
            >
              <EditOutlined />
            </NavLink>
            <Popconfirm
              placement="left"
              title={t('popconfirm.deleteTitle')}
              onConfirm={() => { deleteCompany(record.id) }}
              okText={t('popconfirm.okText')}
              cancelText={t('popconfirm.cancelText')}
              disabled={isLoadingDelete}
            >
              <button
                type="button"
                className={cn(style.actions_item, style.actions_item__delete)}
                disabled={isLoadingDelete}
              >
                <DeleteOutlined />
              </button>
            </Popconfirm>
          </div>
        </AllowedTo>
      ),
    },
  ], [t, onCell, paramsFilters.page, data?.meta.pageSize, isLoadingDelete, deleteCompany])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'name',
      placeholder: t('companies.columns.name'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'company_code',
      placeholder: t('companies.columns.code'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'from_service',
      placeholder: t('companies.columns.fromService'),
      width: '150px',
      options: [
        {
          value: '0',
          label: t('common.false'),
        },
        {
          value: '1',
          label: t('common.true'),
        },
      ],
    },
  ], [t])

  return (
    <div>
      <PageTable
        title={type === CompanyType.Customer ? t('companies.customersTitle')! : t('companies.ownersTitle')!}
        createLink={type === CompanyType.Customer ? '/customers/create' : '/owners/create'}
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        onExport={exportCompanies}
        isLoadingExport={isLoadingExport}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
    </div>

  )
}
