import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Preloader } from '@src/components/Preloader/Preloader'
import { useHistory, useParams } from 'react-router-dom'
import { serviceTemplatesApi } from '@src/store/services/serviceTemplates-service'
import style from '@src/Pages/CarsCreate/carsCreate.module.scss'
import { Button, Col, List, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { detailsApi } from '@src/store/services/details-service'
import { DeleteOutlined } from '@ant-design/icons'

export const ServiceTemplatesCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const { data, isLoading: isLoadingGetOne } = serviceTemplatesApi.useGetOneServiceTemplateQuery(+params.id!, { skip: !params.id })
  const { data: details } = detailsApi.useGetDetailsListForSelectQuery()
  const [create, { isLoading: isLoadingCreate }] = serviceTemplatesApi.useCreateServiceTemplateMutation()
  const [update, { isLoading: isLoadingUpdate }] = serviceTemplatesApi.useEditServiceTemplateMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: data?.name || '',
    description: data?.description || '',
    detailsSelectTmp: [],
    details: data?.details.reduce((acc: any, current) => {
      // eslint-disable-next-line no-param-reassign
      acc[current.id] = current
      return acc
    }, {}) || {} as any,
  }), [data])

  const onSubmit = async (values: any) => {
    let result

    const body = {
      ...values,
      id: params.id,
      details: Object.keys(values.details).reduce((acc: any, key) => {
        // eslint-disable-next-line no-param-reassign
        acc[key] = { quantity: values.details[key].quantity }
        return acc
      }, {}),
    }

    if (params.id) {
      result = await update(body)
    } else {
      result = await create(body)
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <div>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form className={style.form}>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <FieldFormik name="name" placeholder={t('serviceTemplates.columns.name') ?? ''} />
                </Col>
                <Col span={24}>
                  <FieldFormik name="description" placeholder={t('serviceTemplates.columns.description') ?? ''} />
                </Col>
                <Col span={24}>
                  <SelectFormik
                    name="detailsSelectTmp"
                    options={details?.filter((item) => !Object.keys(values.details).includes(item.id.toString())).map((item) => ({
                      ...item, value: item.id, label: `${item.name} ${item.serial_number}`,
                    })) || []}
                    onChange={(value: any, current) => {
                      setFieldValue('detailsSelectTmp', [])
                      setFieldValue('details', {
                        ...values.details,
                        [current[0].id]: {
                          ...current[0],
                          quantity: 1,
                        },
                      })
                    }}
                    isMultiple
                    placeholder={t('serviceTemplates.columns.details')}
                  />
                </Col>
                <Col span={24}>
                  <List
                    bordered
                    header={(
                      <Row gutter={[20, 0]}>
                        <Col span={8}>{t('details.columns.name')}</Col>
                        <Col span={8}>{t('details.columns.serialNumber')}</Col>
                        <Col span={7}>{t('details.columns.quantity')}</Col>
                      </Row>
                    )}
                    dataSource={Object.values(values.details)}
                    renderItem={(item: any) => (
                      <List.Item>
                        <Row style={{ width: '100%' }} gutter={[20, 0]}>
                          <Col span={8}>{item.name}</Col>
                          <Col span={8}>{item.serial_number}</Col>
                          <Col span={7}>
                            <FieldFormik name={`details.${item.id}.quantity`} placeholder="quantity" size="middle" noMarginBottom type="number" />
                          </Col>
                          <Col span={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button
                              onClick={() => {
                                const newDetails = values.details
                                delete newDetails[item.id]
                                setFieldValue('details', newDetails)
                              }}
                              type="button"
                            >
                              <DeleteOutlined />
                            </button>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
              <Button
                htmlType="submit"
                type="primary"
                loading={isLoadingCreate || isLoadingUpdate}
                style={{ marginTop: 16 }}
              >
                {params.id ? t('serviceTemplates.save') : t('serviceTemplates.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
