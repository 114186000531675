import { notification } from 'antd'
import i18n from 'i18next'

function getLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    } else {
      reject(new Error('Geolocation не поддерживается этим браузером.'))
    }
  })
}

// eslint-disable-next-line consistent-return
export async function displayLocation() {
  try {
    const position: any = await getLocation()
    const { latitude } = position.coords
    const { longitude } = position.coords

    return { latitude, longitude }
  } catch (error: any) {
    let errorMessage
    if (error.code) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          errorMessage = 'Vartotojas atsisakė pateikti geografinę vietą.'
          break
        case error.POSITION_UNAVAILABLE:
          errorMessage = 'Vietos informacija nepasiekiama.'
          break
        case error.TIMEOUT:
          errorMessage = 'Baigėsi geografinės vietos nustatymo užklausos skirtasis laikas.'
          break
        default:
          errorMessage = 'Įvyko nežinoma klaida.'
      }
    } else {
      errorMessage = error.message
    }
    notification.error({
      message: i18n.t('errorTitle'),
      description: errorMessage,
    })
  }
}
