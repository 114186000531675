import {
  FC, ForwardRefExoticComponent, useCallback, useMemo, useState,
  ReactNode,
} from 'react'
import { Input } from 'antd'
import { useField } from 'formik'
import cn from 'classnames'
import type { SizeType } from 'antd/es/config-provider/SizeContext'
import style from './FieldFormik.module.scss'

type FieldFormikProps = {
  name: string
  Prefix?: ForwardRefExoticComponent<any>
  placeholder?: string
  type?: string
  required?: boolean
  styleInline?: object
  autoSize?: {
    minRows: number
    maxRows: number
  }
  addonBefore?: ReactNode
  autoFocus?: boolean
  disabled?: boolean
  readonly?: boolean
  autoComplete?: string
  onChange?: (e: any) => void
  size?: SizeType
  noMarginBottom?: boolean
}

export const FieldFormik: FC<FieldFormikProps> = ({
  Prefix,
  autoComplete,
  readonly,
  disabled,
  autoFocus,
  addonBefore,
  styleInline,
  placeholder,
  name,
  type = 'text',
  required,
  autoSize,
  onChange,
  size = 'large',
  noMarginBottom,
}) => {
  const [field, meta] = useField(name)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const isError = useMemo(() => !isFocused && meta.error && meta.touched, [isFocused, meta.error, meta.touched])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
  }, [])
  const handleBlur = useCallback((e: any) => {
    setIsFocused(false)
    field.onBlur(e)
  }, [field])

  const handleChange = useCallback((e: any) => {
    field.onChange(e)

    if (onChange) {
      onChange(e)
    }
  }, [field, onChange])

  const fieldComponent = useMemo(
    () => {
      switch (type) {
        case 'password':
          return (
            <Input.Password
              {...field}
              prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
              type={type}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              addonBefore={addonBefore}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
              size="large"
            />
          )
        case 'textarea':
          return (
            <Input.TextArea
              {...field}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              autoSize={autoSize}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
            />
          )
        default:
          return (
            <Input
              {...field}
              prefix={Prefix && <Prefix style={{ color: '#6A1D51' }} />}
              type={type}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{ ...styleInline, borderColor: isError ? 'red' : '' }}
              addonBefore={addonBefore}
              autoFocus={autoFocus}
              disabled={disabled || readonly}
              autoComplete={autoComplete}
              onChange={handleChange}
              size={size}
            />
          )
      }
    },
    [
      Prefix, addonBefore, autoComplete, autoFocus,
      autoSize, disabled, field, handleBlur, handleChange,
      handleFocus, isError, readonly, styleInline, type, size,
    ],
  )

  return (
    <div className={cn(style.wrapper, { [style.wrapperNoMargin]: noMarginBottom })}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={cn(style.label, readonly && !disabled && style.readonly)}>
        {fieldComponent}
        <div
          className={
            cn(
              style.placeholder,
              type === 'textarea' && style.placeholder__textarea,
              Prefix && style.placeholder_icon,
              (meta.value || meta.value === 0) && style.placeholder_active,
            )
          }
        >
          {placeholder}
          {required && <span className={style.placeholder_required}>*</span>}
        </div>

        {isError && <div className={style.error}>{meta.error}</div>}
      </label>
    </div>
  )
}
