import { useEffect, useState } from 'react'

export const usePageFocus = () => {
  const [isFocus, setIsFocus] = useState(true)

  useEffect(() => {
    const handleFocus = () => setIsFocus(true)
    const handleBlur = () => setIsFocus(false)

    window.addEventListener('focus', handleFocus)
    window.addEventListener('blur', handleBlur)

    return () => {
      window.removeEventListener('focus', handleFocus)
      window.removeEventListener('blur', handleBlur)
    }
  }, [])

  return isFocus
}
