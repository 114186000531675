import { Preloader } from '@src/components/Preloader/Preloader'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Button, Card, Col, Row, Table } from 'antd'
import { Form, Formik } from 'formik'
import { useMemo, useRef } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import moment from 'moment/moment'
import { agreementsApi } from '@src/store/services/agreements-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { AgreementBillsDocument } from '@src/components/pages/agreementBills/AgreementBillsDocument'
import style from './agreementBillsEdit.module.scss'

export const AgreementBillsEdit = () => {
  const { t } = useTranslation()
  const formRef = useRef<any>()
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const { data: info, isLoading: isLoadingGetOne } = agreementsApi.useGetOneAgreementBillQuery(+params.id)
  const [editAgreementBill, { isLoading }] = agreementsApi.useEditAgreementBillMutation()

  const validationSchema = useMemo(() => Yup.object().shape({
    price: Yup.string().required(t('form.errors.required') ?? ''),
    bill_number: Yup.string().required(t('form.errors.required') ?? ''),
  }), [t])

  const initialValues = useMemo(() => ({
    id: info?.id ?? '',
    agreement_id: info?.agreement_id ?? '',
    price: info?.price ?? '',
    bill_number: info?.bill_number ?? '',
    text: info?.text ?? '',
    isCredit: info?.isCredit ?? 0,
    pay_by: info?.pay_by ? moment(info.pay_by) : '',
    date_closed: info?.date_closed ? moment(info.date_closed) : '',
  }), [info])

  const onSubmit = async (data: any) => {
    const result = await editAgreementBill({
      id: +params.id,
      body: {
        ...data,
        pay_by: data.pay_by ? data.pay_by.format('YYYY-MM-DD') : '',
        date_closed: data.date_closed ? data.date_closed.format('YYYY-MM-DD') : '',
      },
    })

    if ('data' in result) {
      if (data.price && formRef.current.values.bill_number && info && info.agreement.customer.id) {
        dispatch(setSendEmail({
          type: SendEmailType.AgreementBills,
          companyId: +info.agreement.customer.id,
          id: +params.id,
        }))
      }
      history.goBack()
    }
  }

  const specialsColumns = useMemo(() => [
    {
      title: t('agreementsClose.servicesName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('agreementsClose.servicesHours'),
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: t('agreementsClose.servicesPriceHour'),
      dataIndex: 'price_hour',
      key: 'price_hour',
    },
    {
      title: t('agreementsClose.servicesTotalPrice'),
      dataIndex: 'total_price',
      key: 'total_price',
    },
  ], [t])

  return (
    <div>
      <h1 className="page_title">
        {t('agreementBillsEdit.title')}
      </h1>

      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          innerRef={formRef}
          enableReinitialize
        >
          {() => (
            <Row gutter={[24, 24]}>
              <Col xl={24} xxl={12}>
                {!!info?.agreement.specials?.length && (
                  <Card style={{ marginBottom: 16 }} title={t('agreementsClose.services')}>
                    <Table
                      dataSource={info?.agreement.specials}
                      columns={specialsColumns}
                      rowKey="id"
                      pagination={false}
                    />
                  </Card>
                )}
                <Form>
                  <Row gutter={[20, 0]}>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="price"
                        placeholder={t('agreementBills.columns.price') ?? ''}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="bill_number"
                        placeholder={t('transportationCreate.billNumber') ?? ''}
                      />
                    </Col>
                    <Col span={24}>
                      <FieldFormik
                        name="text"
                        placeholder={t('transportationCreate.text') ?? ''}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="pay_by" placeholder={t('transportationCreate.payBy') ?? ''} />
                    </Col>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="date_closed" placeholder={t('transportationBillsEdit.dateClosed') ?? ''} />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={12}>
                      <div style={{ marginBottom: 15 }}>
                        <span style={{ marginRight: 15 }}>{t('transportationCreate.type')}</span>
                        <RadioFormik
                          options={[
                            { value: 0, label: t('transportationCreate.basic') },
                            { value: 1, label: t('transportationCreate.credit') },
                          ]}
                          name="isCredit"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Button htmlType="submit" type="primary" loading={isLoading}>
                    {t('transportationBillsEdit.submit')}
                  </Button>
                  <NavLink
                    to={`/agreements/edit/${info?.agreement.id}`}
                    className={style.link}
                  >
                    {t('agreementBillsEdit.openTransportation')}
                  </NavLink>
                </Form>
              </Col>
              <Col xl={24} xxl={12}>
                <Card>
                  <AgreementBillsDocument />
                </Card>
              </Col>
            </Row>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
