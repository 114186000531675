import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { Button, Card, Divider } from 'antd'
import { tasksApi } from '@src/store/services/tasks-service'
import { ArrowLeftOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { TaskInformation } from '@src/components/pages/tasks/TaskInformation'
import { TaskTimelogs } from '@src/components/pages/tasks/TaskTimelogs'
import { TaskRouteHistory } from '@src/components/pages/tasks/TaskRouteHistory'
import { TaskPhotoGallery } from '@src/components/pages/tasks/TaskPhotoGallery'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { TaskWorkTime } from '@src/components/pages/tasks/TaskWorkTime'
import { TaskEndWork } from '@src/components/pages/tasks/TaskEndWork'
import { TaskComments } from '@src/components/pages/tasks/TaskComments'
import { TaskStatusEnum } from '@src/types/tasks'
import { OldWorks } from '@src/components/pages/tasks/OldWorks'

type TaskViewProps = {
  readonly?: boolean
}

export const TaskView: FC<TaskViewProps> = ({ readonly }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const [activeTabKey, setActiveTabKey] = useState<string>('information')
  const { data, isLoading } = tasksApi.useGetOneTaskQuery(+params.id!)
  const taskRouteHistoryRef = useRef<any>()
  const taskTimelogsRef = useRef<any>()
  const { data: user } = authApi.useGetMeQuery()

  useEffect(() => {
    setActiveTabKey('information')
  }, [params.id])

  const contentList = useMemo<Record<string, ReactElement>>(() => ({
    information: (
      <TaskInformation data={data!} />
    ),
    routeHistory: (
      <TaskRouteHistory taskId={+params.id} ref={taskRouteHistoryRef} />
    ),
    photoGallery: (
      <TaskPhotoGallery taskId={+params.id} images={data?.images!} />
    ),
    timelogs: (
      <TaskTimelogs taskId={+params.id} ref={taskTimelogsRef} workers={data?.workers || []} />
    ),
    endWork: (
      <TaskEndWork
        taskId={+params.id}
        taskStatus={data?.status!}
        setActiveTabKey={setActiveTabKey}
        details={[]}
        data={data!}
      />
    ),
    comments: (
      <TaskComments taskId={+params.id} comments={data?.comments!} readonly={readonly} />
    ),
    checkingAct: (
      <TaskEndWork
        taskId={+params.id}
        quiz={data?.quiz}
        taskStatus={data?.status!}
        setActiveTabKey={setActiveTabKey}
        details={data?.details || []}
        workDurationHours={data?.work_duration_hours}
        workDurationMinutes={data?.work_duration_minutes}
        data={data!}
      />
    ),
    oldWorks: (
      <OldWorks carId={data?.car_id!} taskId={+params.id} />
    ),
  }), [data, params.id, readonly])

  const isMechanic = useMemo(() => user?.role === UserRoles.Mechanic, [user])

  const tabList = useMemo(() => {
    if (readonly) {
      return [
        {
          key: 'information',
          label: t('tasks.viewTabs.information'),
        },
        {
          key: 'photoGallery',
          label: t('tasks.viewTabs.photoGallery'),
        },
        {
          key: 'comments',
          label: t('tasks.viewTabs.comments'),
        },
      ]
    }

    if (isMechanic) {
      return [
        {
          key: 'information',
          label: t('tasks.viewTabs.information'),
        },
        {
          key: 'photoGallery',
          label: t('tasks.viewTabs.photoGallery'),
        },
        {
          key: 'timelogs',
          label: t('tasks.viewTabs.timelogs'),
        },
        {
          key: 'endWork',
          label: t('tasks.viewTabs.endWork'),
        },
        {
          key: 'comments',
          label: t('tasks.viewTabs.comments'),
        },
        {
          key: 'oldWorks',
          label: t('tasks.viewTabs.oldWorks'),
        },
      ]
    }
    return [
      {
        key: 'information',
        label: t('tasks.viewTabs.information'),
      },
      {
        key: 'routeHistory',
        label: t('tasks.viewTabs.routeHistory'),
      },
      {
        key: 'photoGallery',
        label: t('tasks.viewTabs.photoGallery'),
      },
      {
        key: 'timelogs',
        label: t('tasks.viewTabs.timelogs'),
      },
      {
        key: 'checkingAct',
        label: t('tasks.viewTabs.checkingAct'),
        disabled: data?.status !== TaskStatusEnum.Finished && data?.status !== TaskStatusEnum.Signed,
      },
      {
        key: 'comments',
        label: t('tasks.viewTabs.comments'),
      },
      {
        key: 'oldWorks',
        label: t('tasks.viewTabs.oldWorks'),
      },
    ]
  }, [data?.status, isMechanic, readonly, t])

  const extraCard = useMemo(() => {
    if (activeTabKey === 'routeHistory') {
      return [
        <Button
          key="createRouteHistory"
          type="primary"
          icon={<PlusOutlined />}
          size="middle"
          onClick={() => {
            taskRouteHistoryRef.current?.setIsOpenCreate()
          }}
        >
          {t('pageTable.create')}
        </Button>,
      ]
    }

    if (activeTabKey === 'timelogs' && (user?.role === UserRoles.Admin || user?.role === UserRoles.ServiceManager)) {
      return [
        <Button
          key="createRouteHistory"
          type="primary"
          icon={<PlusOutlined />}
          size="middle"
          onClick={() => {
            taskTimelogsRef.current?.setIsOpenCreate()
          }}
        >
          {t('pageTable.create')}
        </Button>,
      ]
    }

    return undefined
  }, [activeTabKey, t, user?.role])

  return (
    <>
      <div>
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => history.goBack()}
          type="link"
        >
          {t('common.goBack')}
        </Button>
      </div>
      <br />
      <AllowedTo roles={[UserRoles.Mechanic]}>
        <TaskWorkTime taskId={+params.id} />
      </AllowedTo>
      {data?.status === TaskStatusEnum.Finished && !isMechanic && !readonly ? (
        <Card
          title={data?.name}
          loading={isLoading}
          actions={[
            <NavLink to={`/tasks/edit/${params.id}`}>
              <EditOutlined key="edit" />
            </NavLink>,
          ]}
        >
          <TaskInformation data={data!} />
          <Divider orientation="left" orientationMargin="0">
            {t('tasks.viewTabs.routeHistory')}
          </Divider>
          <TaskRouteHistory taskId={+params.id} ref={taskRouteHistoryRef} isCreate />
          <Divider orientation="left" orientationMargin="0">
            {t('tasks.viewTabs.photoGallery')}
          </Divider>
          <TaskPhotoGallery taskId={+params.id} images={data?.images!} />
          <Divider orientation="left" orientationMargin="0">
            {t('tasks.viewTabs.timelogs')}
          </Divider>
          <TaskTimelogs taskId={+params.id} ref={taskTimelogsRef} workers={data?.workers || []} />
          <Divider orientation="left" orientationMargin="0">
            {t('tasks.viewTabs.checkingAct')}
          </Divider>
          <TaskEndWork
            taskId={+params.id}
            quiz={data?.quiz}
            taskStatus={data?.status!}
            setActiveTabKey={setActiveTabKey}
            details={data?.details || []}
            workDurationHours={data?.work_duration_hours}
            workDurationMinutes={data?.work_duration_minutes}
            data={data!}
          />
          <Divider orientation="left" orientationMargin="0">
            {t('tasks.viewTabs.comments')}
          </Divider>
          <TaskComments taskId={+params.id} comments={data?.comments!} readonly={readonly} />
        </Card>
      ) : (
        <Card
          title={data?.name}
          loading={isLoading}
          activeTabKey={activeTabKey}
          onTabChange={setActiveTabKey}
          tabList={tabList}
          extra={extraCard}
          actions={!isMechanic && !readonly ? [
            <NavLink to={`/tasks/edit/${params.id}`}>
              <EditOutlined key="edit" />
            </NavLink>,
          ] : undefined}
        >
          {data && contentList[activeTabKey]}
        </Card>
      )}
    </>
  )
}
