import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import * as Yup from 'yup'
import { carCategoriesApi } from '@src/store/services/car-categories-service'
import { Preloader } from '@src/components/Preloader/Preloader'
import { Form, Formik } from 'formik'
import style from '@src/Pages/CarsCreate/carsCreate.module.scss'
import { Button, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'

export const CarCategoriesCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const { data, isLoading: isLoadingGetOne } = carCategoriesApi.useGetOneCarCategoryQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = carCategoriesApi.useCreateCarCategoryMutation()
  const [edit, { isLoading: isLoadingEdit }] = carCategoriesApi.useEditCarCategoryMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    id: data?.id || null,
    name: data?.name || '',
  }), [data])

  const onSubmit = async (values: any) => {
    let result
    if (params.id) {
      result = await edit(values)
    } else {
      result = await create(values)
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <div>
      <Preloader loading={isLoadingGetOne}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {() => (
            <Form className={style.form}>
              <Row gutter={[20, 0]}>
                <FieldFormik name="name" placeholder={t('carCategories.columns.name') ?? ''} />
              </Row>
              <Button htmlType="submit" type="primary" loading={isLoadingCreate || isLoadingEdit} style={{ marginTop: 16 }}>
                {params.id ? t('carCategories.save') : t('carCategories.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Preloader>
    </div>
  )
}
