import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BillsResponse, BillsRevenueResponse, BillsTypeEnum } from '@src/types/settings'

export const accountingApi = createApi({
  reducerPath: 'accountingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Accounting'],
  endpoints: (build) => ({
    getBills: build.query<BillsResponse, {dates: any, type: BillsTypeEnum, filters?: any}>({
      query: ({ dates, type, filters }) => ({
        url: '/accounting',
        params: {
          from: dates[0].format('YYYY-MM-DD'),
          to: dates[1].format('YYYY-MM-DD'),
          type,
          filters: filters && JSON.stringify(filters),
        },
      }),
      providesTags: () => ['Accounting'],
    }),
    getBillsRevenue: build.query<BillsRevenueResponse, {dates: any, type: BillsTypeEnum, filters?: any}>({
      query: ({ dates, type, filters }) => ({
        url: '/accounting/get-revenue',
        params: {
          from: dates[0].format('YYYY-MM-DD'),
          to: dates[1].format('YYYY-MM-DD'),
          type,
          filters: filters && JSON.stringify(filters),
        },
      }),
      providesTags: () => ['Accounting'],
    }),
    sendEmailAccountant: build.mutation<string, {ids: number[], email: string, type: BillsTypeEnum}>({
      query: (body) => ({
        url: '/accounting/send',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Accounting'],
    }),
  }),
})
