import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { CarCategoryType } from '@src/types/carCategories'

export const carCategoriesApi = createApi({
  reducerPath: 'carCategoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['CarCategories'],
  endpoints: (build) => ({
    getCarCategoriesList: build.query<GetItemsType<CarCategoryType>, { page: number }>({
      query: (params) => ({
        url: '/car-categories',
        params,
      }),
      providesTags: () => ['CarCategories'],
    }),
    getCarCategoriesForSelect: build.query<CarCategoryType[], void>({
      query: () => ({
        url: '/car-categories/short',
      }),
      providesTags: () => ['CarCategories'],
    }),
    deleteCarCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `/car-categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CarCategories'],
    }),
    getOneCarCategory: build.query<CarCategoryType, number>({
      query: (id) => ({
        url: `/car-categories/${id}`,
      }),
      providesTags: () => ['CarCategories'],
    }),
    createCarCategory: build.mutation<CarCategoryType, any>({
      query: (body) => ({
        url: '/car-categories',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CarCategories'],
    }),
    editCarCategory: build.mutation<CarCategoryType, any>({
      query: ({ id, ...body }) => ({
        url: `/car-categories/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CarCategories'],
    }),
  }),
})
