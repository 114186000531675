import { useTranslation } from 'react-i18next'
import { usersApi } from '@src/store/services/users-service'
import { Form, Formik } from 'formik'
import { FC, useMemo } from 'react'
import { Preloader } from '@src/components/Preloader/Preloader'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Col, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { UserRoles } from '@src/types/users'
import * as Yup from 'yup'

type UsersCreateProps = {
  isModal?: boolean
  successCallbackModal?: () => void
}

export const UsersCreate: FC<UsersCreateProps> = ({ isModal, successCallbackModal }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string }>()
  const { data, isLoading } = usersApi.useGetOneUserQuery(+params.id!, { skip: !params.id || isModal })
  const [create, { isLoading: isLoadingCreate }] = usersApi.useCreateUserMutation()
  const [edit, { isLoading: isLoadingEdit }] = usersApi.useEditUserMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      surname: Yup.string().required(t('form.errors.required') ?? ''),
      email: Yup.string().email().required(t('form.errors.required') ?? ''),
      role: Yup.string().required(t('form.errors.required') ?? ''),
      password: Yup.string().when('isEdit', {
        is: true,
        then: () => Yup.string().min(8),
        otherwise: () => Yup.string().min(8).required(t('form.errors.required') ?? ''),
      }),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    isEdit: !!params.id && !isModal,
    name: data?.name || '',
    surname: data?.surname || '',
    email: data?.email || '',
    phone: data?.phone || '',
    role: isModal ? UserRoles.Mechanic : data?.role || '',
    password: '',
  }), [data, params.id, isModal])

  const onSubmit = async (values: typeof initialValues) => {
    let result

    if (params.id && !isModal) {
      result = await edit({
        ...values,
        id: params.id,
        password: values.password || undefined,
      })
    } else {
      result = await create(values)
    }

    if ('data' in result) {
      if (isModal) {
        successCallbackModal?.()
      } else {
        history.push('/users')
      }
    }
  }

  return (
    <div>
      {!isModal && (
        <h1 className="page_title">
          {params.id ? t('usersCreate.titleEdit') : t('usersCreate.title')}
        </h1>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          <Preloader loading={isLoading}>
            <Row gutter={[20, 0]}>
              <Col xs={24} md={12}>
                <FieldFormik name="name" placeholder={t('users.columns.name')!} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="surname" placeholder={t('users.columns.surname')!} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="email" placeholder={t('users.columns.email')!} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="phone" placeholder={t('users.columns.phone')!} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="password" placeholder={t('users.columns.password')!} />
              </Col>
              {!isModal && (
                <Col xs={24} md={12}>
                  <SelectFormik
                    name="role"
                    placeholder={t('users.columns.role')!}
                    options={Object.values(UserRoles).map((value) => ({
                      label: t(`users.roles.${value}`),
                      value,
                    }))}
                  />
                </Col>
              )}
            </Row>
            <Button htmlType="submit" type="primary" loading={isLoading || isLoadingCreate || isLoadingEdit}>
              {params.id && !isModal ? t('usersCreate.save') : t('usersCreate.submit')}
            </Button>
          </Preloader>
        </Form>
      </Formik>
    </div>
  )
}
