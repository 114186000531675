import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Layout, Menu } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { ReactComponent as Logo } from '@src/assets/logo.svg'
import { ReactComponent as LogoIcon } from '@src/assets/logo_icon.svg'
import { settingsApi } from '@src/store/services/settings-service'
import { usePageFocus } from '@src/hooks/usePageFocus'
import { useWindowSize } from '@src/hooks/useWindowSize'
import cn from 'classnames'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { useMenuList } from './menuList'
import style from './SitebarLeft.module.scss'

type SitebarLeftProps = {
  collapsed: boolean
  isOpenMenu: boolean
}

export const SitebarLeft: FC<SitebarLeftProps> = ({ collapsed, isOpenMenu }) => {
  const { width } = useWindowSize()
  const location = useLocation()
  const isPageFocus = usePageFocus()
  const { data: user } = authApi.useGetMeQuery()
  const [formatBillNumber, setFormatBillNumber] = useState('')
  const { data: lastBillNumber, refetch } = settingsApi.useGetLastNumberQuery(isPageFocus ? undefined : skipToken, {
    pollingInterval: 5000,
    skip: user?.role !== UserRoles.Admin,
  })
  const menuList = useMenuList()

  useEffect(() => {
    if (isPageFocus && user?.role === UserRoles.Admin) {
      refetch()
    }
  }, [isPageFocus, refetch, user])

  useEffect(() => {
    if (lastBillNumber) {
      setFormatBillNumber(lastBillNumber > 1000 ? `0${lastBillNumber}` : `00${lastBillNumber}`)
    }
  }, [lastBillNumber])

  const collapsedMeno = useMemo(() => {
    if (width > 1024) {
      return collapsed
    }

    return false
  }, [collapsed, width])

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsedMeno}
      width={250}
      theme="light"
      className={cn(style.leftSitebar, { [style.leftSitebarOpen]: isOpenMenu })}
    >
      <div className={style.logoContainer}>
        <NavLink to="/" className={style.logo}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {user && (user.role === UserRoles.Admin) ? (
            collapsedMeno ? <LogoIcon /> : <Logo />
          ) : <div>CRM</div>}
        </NavLink>
      </div>
      <Menu
        mode="inline"
        selectedKeys={[`/${location.pathname.split('/')[1]}`]}
        className={style.menu}
        items={menuList}
      />
      {formatBillNumber && user?.role === UserRoles.Admin && (
        <NavLink to="/all-invoices" className={style.lastNumber}>
          <Button type="primary" style={{ width: '95%' }}>
            {formatBillNumber}
          </Button>
        </NavLink>
      )}
    </Layout.Sider>
  )
}
