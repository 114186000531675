import { Button, Card } from 'antd'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import { Preloader } from '@src/components/Preloader/Preloader'
import { detailsApi } from '@src/store/services/details-service'
import { authApi } from '@src/store/services/auth-service'
import { DetailTransferForm } from '@src/Pages/Test/DetailTransfer/DetailTransferForm/DetailTransferForm'
import useQuery from '@src/hooks/useQuery'
import style from './detail-transfer.module.scss'

export const OPERATION_TYPES = {
  Mechanic: 'mechanic',
  Storage: 'storage',
}

export function DetailTransfer() {
  const { t } = useTranslation()
  const history = useHistory()
  const id = useQuery('id')

  const { data: user } = authApi.useGetMeQuery()
  const { data: details, isLoading: isLoadingDetails } = detailsApi.useGetUserDetailsListForSelectQuery({ id: user?.id }, { skip: !user?.id })
  const [createDetailTransferRequest, { isLoading: isLoadingDetailTransferRequest }] = detailsApi.useCreateDetailTransferRequestMutation()
  const [returnDetail, { isLoading: isLoadingDetailReturn }] = detailsApi.useReturnDetailMutation()

  const initialValues = useMemo(() => ({
    detail_id: '',
    worker_id: '',
    operation: OPERATION_TYPES.Storage,
    count: 0,
  }), [])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      detail_id: Yup.string().required(t('form.errors.required') ?? ''),
      worker_id: Yup.string(),
      operation: Yup.string().required(t('form.errors.required') ?? ''),
      count: Yup.number().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const onSubmit = useCallback(async (data: any) => {
    const { operation, detail_id, count, worker_id } = data

    const detail = details?.find((item) => item.id === +detail_id)!

    if (operation === 'storage') {
      const result = await returnDetail({
        serial_number: detail.serial_numbers[0].serial_number,
        count,
      })

      if ('data' in result) {
        history.push('/mechanic/my-details')
      }
    }

    if (operation === 'mechanic') {
      const result = await createDetailTransferRequest({
        detail_id,
        count,
        to_id: worker_id,
      })

      if ('data' in result) {
        history.push('/mechanic/my-details')
      }
    }
  }, [details, history, returnDetail, createDetailTransferRequest])

  return (
    <div>
      {id && (
      <div className={style.back}>
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={() => history.push('/mechanic/my-details')}
          type="link"
        >
          {t('common.goBack')}
        </Button>
      </div>
      )}
      <Card
        title={t('detailTransfer.title') ?? ''}
      >
        <Preloader loading={isLoadingDetails}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            <DetailTransferForm details={details || []} loading={isLoadingDetailTransferRequest || isLoadingDetailReturn} />
          </Formik>
        </Preloader>
      </Card>
    </div>
  )
}
