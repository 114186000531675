import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransportationType } from '@src/types/transportation'
import { ResponseMetaType } from '@src/api/api'

const initialState = {
  list: [] as TransportationType[],
  meta: {
    currentPage: 1,
    lastPage: 0,
    pageSize: 0,
    totalCount: 0,
  },
  isLoadingList: false,
  isLoadingCreate: false,
  isLoadingGetOne: false,
  isLoadingDelete: false,
  isLoadingSign: false,
  isLoadingEmail: false,
  info: null as TransportationType | null,
}

const transportationSlice = createSlice({
  name: 'transportation',
  initialState,
  reducers: {
    setTransportationList(state, { payload }: PayloadAction<TransportationType[]>) {
      state.list = payload.map((item, i) => ({
        ...item,
        key: item.id,
        index: i + 1,
      }))
    },
    setTransportationMeta(state, { payload }: PayloadAction<ResponseMetaType>) {
      state.meta = payload
    },
    setIsLoadingList(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingList = payload
    },
    setIsLoadingCreate(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingCreate = payload
    },
    setIsLoadingGetOne(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingGetOne = payload
    },
    setInfo(state, { payload }: PayloadAction<TransportationType | null>) {
      state.info = payload
    },
    setIsLoadingDelete(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingDelete = payload
    },
    setIsLoadingSign(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingSign = payload
    },
    setIsLoadingEmail(state, { payload }: PayloadAction<boolean>) {
      state.isLoadingEmail = payload
    },
  },
})

export type TransportationStateType = typeof initialState

export const {
  setTransportationList,
  setTransportationMeta,
  setIsLoadingList,
  setIsLoadingCreate,
  setIsLoadingGetOne,
  setInfo,
  setIsLoadingSign,
  setIsLoadingDelete,
  setIsLoadingEmail,
} = transportationSlice.actions

export const transportationReducer = transportationSlice.reducer
