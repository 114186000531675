import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import * as Yup from 'yup'
import { monthlyPaymentsApi } from '@src/store/services/mounthly-paymenys-service'
import moment from 'moment/moment'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'

export const MonthlyPaymentsCreate = () => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const { data, isLoading } = monthlyPaymentsApi.useGetOneQuery(params.id || '', { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = monthlyPaymentsApi.useCreateMutation()
  const [update, { isLoading: isLoadingUpdate }] = monthlyPaymentsApi.useUpdateMutation()

  const initialValues = useMemo(() => ({
    salary: data?.salary || '',
    petrol: data?.petrol || '',
    leasing: data?.leasing || '',
    insurance: data?.insurance || '',
    service: data?.service || '',
    additional: data?.service || '',
    period: data?.period ? moment(data.period) : moment(),
  }), [data])

  const onSubmit = async (values: any) => {
    const requestData = {
      ...values,
      period: values.period.format('YYYY-MM-DD'),
    }

    let result
    if (params.id) {
      result = await update({
        ...requestData,
        id: params.id,
      })
    } else {
      result = await create(requestData)
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      salary: Yup.number().required(t('form.errors.required') ?? ''),
      petrol: Yup.number().required(t('form.errors.required') ?? ''),
      leasing: Yup.number().required(t('form.errors.required') ?? ''),
      insurance: Yup.number().required(t('form.errors.required') ?? ''),
      service: Yup.number().required(t('form.errors.required') ?? ''),
      additional: Yup.number().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  return (
    <div>
      <h1 className="page_title">{params.id ? t('monthlyPaymentsCreate.titleEdit') : t('monthlyPaymentsCreate.title')}</h1>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          <Form>
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <FieldFormik
                  name="salary"
                  placeholder={t('monthlyPaymentsCreate.salary') ?? ''}
                />
              </Col>
              <Col span={12}>
                <FieldFormik
                  name="petrol"
                  placeholder={t('monthlyPaymentsCreate.petrol') ?? ''}
                />
              </Col>
              <Col span={12}>
                <FieldFormik
                  name="leasing"
                  placeholder={t('monthlyPaymentsCreate.leasing') ?? ''}
                />
              </Col>
              <Col span={12}>
                <FieldFormik
                  name="insurance"
                  placeholder={t('monthlyPaymentsCreate.insurance') ?? ''}
                />
              </Col>
              <Col span={12}>
                <FieldFormik
                  name="service"
                  placeholder={t('monthlyPaymentsCreate.service') ?? ''}
                />
              </Col>
              <Col span={12}>
                <FieldFormik
                  name="additional"
                  placeholder={t('monthlyPaymentsCreate.additional') ?? ''}
                />
              </Col>
              <Col xs={24} md={4}>
                <DatePickerFormik name="period" placeholder={t('monthlyPaymentsCreate.period') ?? ''} />
              </Col>
            </Row>
            <Button
              style={{ marginTop: 20 }}
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingUpdate}
            >
              {t('monthlyPaymentsCreate.submit')}
            </Button>
          </Form>
        </Formik>
      </Preloader>
    </div>
  )
}
