import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'
import { TasksMechanic } from '@src/Pages/Service/Tasks/TasksMechanic'
import { Redirect } from 'react-router-dom'

export const Home = () => {
  const { data: user } = authApi.useGetMeQuery()

  switch (user?.role) {
    case UserRoles.Mechanic:
      return <TasksMechanic />
    case UserRoles.StoreManager:
      return <Redirect to="/details" />
    default:
      return <Redirect to="/customers" />
  }
}
