import { FC, useState } from 'react'
import { Input } from 'antd'
import { useField, useFormikContext } from 'formik'
// eslint-disable-next-line import/no-extraneous-dependencies
import { usePlacesWidget } from 'react-google-autocomplete'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'

type AddressFormikProps = {
  name: string
  placeholder: string
  onChange?: (value: any) => void
}

export const AddressFormik: FC<AddressFormikProps> = ({ name, placeholder, onChange }) => {
  const [value, setValue] = useState('')
  const [, meta] = useField(name)
  const { setFieldValue } = useFormikContext<any>()

  const { ref }: any = usePlacesWidget({
    apiKey: 'AIzaSyCbIbOy8l4T5d4m_CtaOwUjGPNWsx4s3V0',
    language: 'lt',
    onPlaceSelected: (place) => {
      // antInputRef.current.setValue(place?.formatted_address)
      setValue(place?.formatted_address || '')
      setFieldValue(name, place?.formatted_address || '')
      onChange?.(place)
    },
    options: {
      types: ['geocode', 'establishment'],
    },
  })

  return (
    <div className={style.wrapper}>
      <Input
        size="large"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        ref={(c) => {
          if (c) ref.current = c.input
        }}
      />
      {meta.error && <div className={style.error}>{meta.error}</div>}
    </div>
  )
}
