import { FC } from 'react'
import { Button, Col, Descriptions, List, Row } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { TaskType } from '@src/types/tasks'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { UserRoles } from '@src/types/users'
import { NavLink } from 'react-router-dom'

type TaskInformationProps = {
  data: TaskType
}

export const TaskInformation: FC<TaskInformationProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Descriptions
        column={{ sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
        items={[
          {
            label: t('tasks.columns.client'),
            children: data.client?.name,
          },
          {
            label: t('tasks.columns.car'),
            children: `${data.car.make}/${data.car.model}(${data.car.serial_number})`,
          },
          {
            label: t('tasks.columns.author'),
            children: `${data.author.name} ${data.author.surname}`,
          },
          {
            label: t('tasks.columns.workers'),
            children: data.workers.map((item) => `${item.name} ${item.surname}`).join(', '),
          },
          {
            label: t('tasks.columns.workType'),
            children: data.work_type.name,
          },
          {
            label: t('tasks.columns.status'),
            children: t(`tasks.statuses.${data.status}`),
          },
          {
            label: t('tasks.columns.plannedDateStart'),
            children: `${moment(data.planned_date_start).format('YYYY-MM-DD HH:mm')}`,
          },
          {
            label: t('tasks.columns.plannedDateEnd'),
            children: `${moment(data.planned_date_end).format('YYYY-MM-DD HH:mm')}`,
          },
          {
            label: t('tasks.endWork.workDurationHours'),
            children: data.work_duration_hours,
          },
          {
            label: t('tasks.endWork.workDurationMinutes'),
            children: data.work_duration_minutes,
          },
          {
            label: t('tasks.columns.place'),
            children: data.place,
            span: 2,
          },
          {
            label: t('tasks.columns.description'),
            children: data.description,
            span: 2,
          },
        ]}
      />
      <List
        bordered
        header={(
          <Row gutter={[20, 0]}>
            <Col span={7}>{t('details.columns.name')}</Col>
            <Col span={7}>{t('details.columns.serialNumber')}</Col>
            <Col span={4}>{t('details.columns.quantity')}</Col>
            <Col span={6}>{t('details.columns.appointor')}</Col>
          </Row>
      )}
        dataSource={data.details}
        renderItem={(item) => (
          <List.Item>
            <Row style={{ width: 'calc(100% + 20px)' }} gutter={[20, 0]}>
              <Col span={7}>{item.name}</Col>
              <Col span={7}>{item.serial_number}</Col>
              <Col span={4}>
                {item.quantity}
              </Col>
              <Col span={6}>
                {item.appointor?.name}
                {' '}
                {item.appointor?.surname}
              </Col>
            </Row>
          </List.Item>
        )}
      />
      <AllowedTo roles={[UserRoles.Mechanic]}>
        <div>
          <br />
          <NavLink to={`/tasks/edit/${data.id}`}>
            <Button type="primary">{t('tasks.addDetails')}</Button>
          </NavLink>
        </div>
      </AllowedTo>
    </div>
  )
}
