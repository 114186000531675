import { FC } from 'react'
import { Radio } from 'antd'
import { useField } from 'formik'
import style from './radioFormik.module.scss'

type RadioFormikProps = {
  name: string
  options: Array<{
    value: any
    label: string
  }>
  className?: string
  placeholder?: string
  disabled?: boolean
}

export const RadioFormik: FC<RadioFormikProps> = ({ name, options, className, placeholder, disabled }) => {
  const [field, meta] = useField({ name })

  return (
    <>
      {placeholder}
      {' '}
      <Radio.Group {...field} name={name} disabled={disabled}>
        {options.map((item) => (
          <Radio style={{ color: '#15130d' }} className={className} key={item.value} value={item.value}>{item.label}</Radio>
        ))}
      </Radio.Group>
      {meta.touched && !!meta.error && (
        <div className={style.error}>
          {meta.error}
        </div>
      )}
    </>
  )
}
