import { AppDispatch } from '@src/store/store'
import { notification } from 'antd'
import i18n from 'i18next'
import { transportationApi } from '@src/api/transportation-api'
import { closeSendEmail } from '@src/store/ducks/app/reducer'
import {
  setIsLoadingEmail,
} from './reducer'

export const sendEmailCompanyTransportationBills = (id: number, email: string) => async (dispatch: AppDispatch) => {
  try {
    await transportationApi.sendEmail(id, email)

    dispatch(closeSendEmail())
    notification.success({
      message: 'Email Status Notification',
      description: 'Agreement has been successfully send to customer.',
    })
  } catch (err: any) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description: `${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(setIsLoadingEmail(false))
  }
}
