import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { companiesApi } from '@src/store/services/companies-service'
import { Card, Col, Descriptions, Popconfirm, Row } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { CompanyType } from '@src/types/company'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { PageTable } from '@src/components/Table/PageTable'
import { useAgreementsTable } from '@src/components/pages/agreements/AgreementsTable.hook'
import { CustomerWorkers } from '@src/components/CustomerWorkers/CustomerWorkers'
import moment from 'moment/moment'
import { authApi } from '@src/store/services/auth-service'
import { UserRoles } from '@src/types/users'

export const CompaniesView = () => {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [activeTabKey, setActiveTabKey] = useState<string>('customerWorkers')
  const { data, isLoading } = companiesApi.useGetOneCompanyQuery(+params.id)
  const { data: user } = authApi.useGetMeQuery()
  const { columns, data: dataAgreements, paramsFilters, setParamsFilters, AgreementsComponents, isLoading: isLoadingAgreements } = useAgreementsTable({
    initParamsFilters: {
      page: 1,
      filters: {
        filters: data?.type === CompanyType.Owner ? [{ columns: 'owner_id', value: params.id }] : [{ columns: 'customer_id', value: params.id }],
      },
    },
    skip: user?.role !== UserRoles.Admin,
  })
  const [deleteCompany, { isLoading: isLoadingDelete }] = companiesApi.useDeleteCompanyMutation()

  useEffect(() => {
    setParamsFilters({
      page: 1,
      filters: {
        filters: data?.type === CompanyType.Owner ? [{ columns: 'owner_id', value: params.id }] : [{ columns: 'customer_id', value: params.id }],
      },
    })
  }, [data?.type, params.id, setParamsFilters])

  const contentList: Record<string, ReactNode> = useMemo(() => ({
    agreements: user?.role === UserRoles.Admin ? (
      <PageTable
        isTableOnly
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...dataAgreements,
          isLoading: isLoadingAgreements,
          columns,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => <tr {...props} style={{ background: dataAgreements?.data.find((item) => item.id === props['data-row-key'])?.owner.color }} />,
            },
          },
        }}
      />
    ) : null,
    customerWorkers: (
      <CustomerWorkers companyId={+params.id} notHeader />
    ),
  }), [columns, dataAgreements, isLoadingAgreements, params.id, paramsFilters, setParamsFilters, user])

  const handleDelete = async () => {
    const result = deleteCompany(+params.id)

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <div>
      <Row gutter={[12, 12]}>
        <Col lg={24} xl={8}>
          <Card
            loading={isLoading}
            actions={[
              <NavLink
                to={data?.type === CompanyType.Owner ? `/owners/edit/${params.id}` : `/customers/edit/${params.id}`}
              >
                <EditOutlined key="edit" />
              </NavLink>,
              <Popconfirm
                placement="left"
                title={t('popconfirm.deleteTitle')}
                onConfirm={handleDelete}
                okText={t('popconfirm.okText')}
                cancelText={t('popconfirm.cancelText')}
                disabled={isLoadingDelete}
              >
                <button
                  type="button"
                  disabled={isLoadingDelete}
                >
                  <DeleteOutlined />
                </button>
              </Popconfirm>,
            ]}
          >
            {data && (
              <Descriptions
                title={data.name}
                column={1}
                items={[
                  { label: t('companies.columns.code'), children: data.company_code },
                  { label: t('companies.columns.address'), children: data.address },
                  { label: t('companies.columns.accountNumber'), children: data.account_number },
                  { label: t('companies.columns.agreementNumber'), children: data.agreement_number },
                  { label: t('companies.columns.createdAt'), children: moment(data.created_at).format('YYYY-MM-DD') },
                  { label: t('companies.columns.vatNumber'), children: data.vat_number },
                  { label: t('companies.columns.fromService'), children: data.from_service ? t('common.true') : t('common.false') },
                ]}
              />
            )}
          </Card>
        </Col>
        <Col lg={24} xl={16}>
          <Card
            activeTabKey={activeTabKey}
            onTabChange={setActiveTabKey}
            tabList={user?.role === UserRoles.Admin ? [
              {
                key: 'customerWorkers',
                label: t('customerWorkers.title'),
              },
              {
                key: 'agreements',
                label: t('agreements.title'),
              },
            ] : [
              {
                key: 'customerWorkers',
                label: t('customerWorkers.title'),
              },
            ]}
            tabProps={{
              size: 'middle',
            }}
          >
            {contentList[activeTabKey]}
          </Card>
        </Col>
      </Row>
      {AgreementsComponents}
    </div>
  )
}
