import { AppDispatch } from '@src/store/store'
import { authApi } from '@src/api/auth-api'
import { notification } from 'antd'
import i18n from 'i18next'
import { logoutAC } from './reducer'

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    await authApi.logout()
  } catch (err: any) {
    console.error(err)
    notification.error({
      message: i18n.t('errorTitle'),
      description: `${err?.response?.data?.message ?? err.message}`,
    })
  } finally {
    dispatch(logoutAC())
    localStorage.setItem('token', '')
  }
}
