import {
  FC, ReactNode, useCallback, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button, DatePicker, Input, Select, Switch,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { TableFiltersType } from '@src/types/tableFilters'
import moment from 'moment'
import { authApi } from '@src/store/services/auth-service'
import style from './filters.module.scss'

type FiltersProps = {
  filters: TableFiltersType
  onChange: (filters: Array<{ columns: string, value: any }>) => void
  loading?: boolean
  children?: ReactNode
  values?: Array<{ columns: string, value: any }>
}

export const Filters: FC<FiltersProps> = ({
  children, filters, onChange, loading, values,
}) => {
  const { t } = useTranslation()
  const { data: user } = authApi.useGetMeQuery()
  const [filtersData, setFiltersData] = useState<Array<{ columns: string, value: any }>>(values || [])

  const handleOnChange = useCallback((dataIndex: string, value: any) => {
    const newFiltersData = filtersData.filter((filter) => filter.columns !== dataIndex)
    if (value) {
      newFiltersData.push({ columns: dataIndex, value })
    }
    setFiltersData(newFiltersData)
    onChange(newFiltersData)
  }, [filtersData, onChange])

  const clearFilters = () => {
    setFiltersData([])
    onChange([])
  }

  const filtersComponents = useMemo(() => filters.map((filter) => {
    if (filter.roles?.length && !filter.roles.includes(user!.role)) {
      return null
    }

    switch (filter.type) {
      case 'input':
        return (
          <Input
            value={filtersData?.find((value) => value.columns === filter.dataIndex)?.value}
            type="text"
            key={filter.dataIndex}
            onChange={(e) => handleOnChange(filter.dataIndex, e.target.value)}
            placeholder={filter.placeholder}
            style={{ width: filter.width }}
          />
        )
      case 'datePicker':
        return (
          <DatePicker
            value={filtersData?.find((value) => value.columns === filter.dataIndex)?.value}
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            onChange={(e) => handleOnChange(filter.dataIndex, e?.format())}
            className={filter.className}
            style={{ width: filter.width }}
          />
        )
      case 'rangePicker': {
        const thisValue = filtersData?.find((value) => value.columns === filter.dataIndex)?.value

        return (
          <DatePicker.RangePicker
            value={thisValue ? [moment(thisValue[0]), moment(thisValue[1])] as any : []}
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            onChange={(e) => handleOnChange(
              filter.dataIndex,
              e && ([e[0]?.format('YYYY-MM-DD'), e[1]?.format('YYYY-MM-DD')]),
            )}
            // @ts-ignore
            defaultValue={filter.defaultValue}
            className={filter.className}
            style={{ width: filter.width }}
            allowClear={!filter.notAllowClear}
          />
        )
      }
      case 'select':
        return (
          <Select
            key={filter.dataIndex}
            placeholder={filter.placeholder}
            allowClear
            showSearch={filter.showSearch || true}
            onChange={(e) => handleOnChange(filter.dataIndex, e)}
            onSearch={(e) => filter.onSearch && filter.onSearch(e)}
            className={filter.className}
            style={{ width: filter.width }}
            optionFilterProp="children"
            value={filtersData?.find((value) => value.columns === filter.dataIndex)?.value}
          >
            {filter.options.map((option) => (
              <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
            ))}
          </Select>
        )
      case 'switch':
        return (
          <div className={style.switch} key={filter.dataIndex}>
            <div className={style.switch_title}>{filter.placeholder}</div>
            <Switch onChange={(e) => handleOnChange(filter.dataIndex, e)} />
          </div>
        )
      default:
        return null
    }
  }), [filters, filtersData, handleOnChange, user])

  return (
    <div className={style.filters}>
      {!loading && (
        <>
          {filtersComponents}
          {children}
        </>
      )}
      <Button
        icon={<DeleteOutlined />}
        onClick={clearFilters}
      >
        {(t('table.filtersClear'))}
      </Button>
    </div>
  )
}
