import { FC, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Button, Col, Divider, List, Row } from 'antd'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { tasksApi } from '@src/store/services/tasks-service'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { UserRoles } from '@src/types/users'
import { TaskStatusEnum, TaskType } from '@src/types/tasks'
import moment from 'moment'
import { serviceInvoicesApi } from '@src/store/services/serviceInvoices-service'
import { authApi } from '@src/store/services/auth-service'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import style from './taskEndWork.module.scss'

type TaskEndWorkProps = {
  taskId: number
  quiz?: string
  disabled?: boolean
  taskStatus: TaskStatusEnum
  setActiveTabKey: (value: string) => void
  workDurationHours?: string
  workDurationMinutes?: string
  data: TaskType
  details: Array<{
    id: number
    name: string
    serial_number: string
    quantity: number
  }>
}

export const TaskEndWork: FC<TaskEndWorkProps> = ({ taskId, quiz, disabled, taskStatus, setActiveTabKey, details, workDurationHours, workDurationMinutes, data }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: user } = authApi.useGetMeQuery()
  const [taskFinish, { isLoading: isLoadingFinish }] = tasksApi.useTaskFinishMutation()
  const [taskSign, { isLoading: isLoadingSign }] = tasksApi.useTaskSignMutation()
  const { data: timelogs } = tasksApi.useGetTimelogsHistoryQuery(
    taskId,
    { skip: (taskStatus !== TaskStatusEnum.Finished && taskStatus !== TaskStatusEnum.Signed) },
  )

  const quizObj = useMemo(() => (quiz ? JSON.parse(quiz) : undefined), [quiz])

  const timelogsDiff = useMemo(() => {
    if (timelogs) {
      return timelogs.reduce((acc, current) => acc + moment(current.ended).diff(current.started, 'minutes'), 0)
    }

    return 0
  }, [timelogs])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      quiz: Yup.object().shape({
        spotLubricationLoadLiftingDevices: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        lubricationCardanDrive: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        fillingCentralLubricationSystem: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),

        engineOilLevel: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        transmissionOilLevel: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        coolantLevel: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        hydraulicOilLevel: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),

        cabinStructure: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        lightingElements: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        interiorDetails: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        windows: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        safetyElements: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),

        tireCondition: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        steeringGearSystemCondition: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),

        technicalFluidLeaks: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        bucketBladeCondition: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
        freedomOfJointsInMachineComponents: Yup.object().shape({
          value: Yup.string().required(t('form.errors.required') ?? ''),
        }),
      }),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    quiz: quiz ? JSON.parse(quiz) : {
      spotLubricationLoadLiftingDevices: {
        value: '',
        comment: '',
      },
      lubricationCardanDrive: {
        value: '',
        comment: '',
      },
      fillingCentralLubricationSystem: {
        value: '',
        comment: '',
      },

      engineOilLevel: {
        value: '',
        comment: '',
      },
      transmissionOilLevel: {
        value: '',
        comment: '',
      },
      coolantLevel: {
        value: '',
        comment: '',
      },
      hydraulicOilLevel: {
        value: '',
        comment: '',
      },

      cabinStructure: {
        value: '',
        comment: '',
      },
      lightingElements: {
        value: '',
        comment: '',
      },
      interiorDetails: {
        value: '',
        comment: '',
      },
      windows: {
        value: '',
        comment: '',
      },
      safetyElements: {
        value: '',
        comment: '',
      },

      tireCondition: {
        value: '',
        comment: '',
      },
      steeringGearSystemCondition: {
        value: '',
        comment: '',
      },

      technicalFluidLeaks: {
        value: '',
        comment: '',
      },
      bucketBladeCondition: {
        value: '',
        comment: '',
      },
      freedomOfJointsInMachineComponents: {
        value: '',
        comment: '',
      },
    },
  }), [quiz])

  const onSubmit = async (values: any) => {
    const result = await taskFinish({
      taskId,
      body: {
        quiz: JSON.stringify(values.quiz),
      },
    })

    if ('data' in result) {
      if (user?.role === UserRoles.Mechanic) {
        history.push('/')
      } else {
        history.goBack()
      }
    }
  }

  const signValidationSchema = useMemo(() => (
    Yup.object().shape({
      work_duration_hours: Yup.string().required(t('form.errors.required') ?? ''),
      work_duration_minutes: Yup.string().required(t('form.errors.required') ?? ''),
      date_start: Yup.string().required(t('form.errors.required') ?? ''),
      date_end: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const signInitialValues = useMemo(() => ({
    work_duration_hours: workDurationHours || Math.floor(timelogsDiff / 60),
    work_duration_minutes: workDurationMinutes || timelogsDiff % 60,
    details: details.map((item) => ({
      ...item,
      spent_quantity: item.quantity,
      user_id: data.workers.length === 1 ? data.workers[0].id : null,
    })),
    date_start: data.date_start ? moment(data.date_start) : '',
    date_end: data.date_end ? moment(data.date_end) : '',
  }), [details, timelogsDiff, workDurationHours, workDurationMinutes, data])

  const onSubmitSign = async (values: any) => {
    const result = await taskSign({
      taskId,
      body: {
        ...values,
        date_start: moment(values.date_start).format('YYYY-MM-DD HH:mm'),
        date_end: moment(values.date_end).format('YYYY-MM-DD HH:mm'),
      },
    })

    if ('data' in result) {
      serviceInvoicesApi.util.invalidateTags(['ServiceInvoice'])
      setActiveTabKey('information')
    }
  }

  return (
    <div>
      {taskStatus === TaskStatusEnum.Finished && user?.role !== UserRoles.Mechanic ? (
        <Row gutter={[8, 8]}>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Krovinio kėlimo įtaisų taškinis tepimas</div>
              <div className={style.quizAnswer}>
                {quizObj?.spotLubricationLoadLiftingDevices.value}
                {quizObj?.spotLubricationLoadLiftingDevices.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.spotLubricationLoadLiftingDevices.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Kardaninės pavaros tepimas</div>
              <div className={style.quizAnswer}>
                {quizObj?.lubricationCardanDrive.value}
                {quizObj?.lubricationCardanDrive.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.lubricationCardanDrive.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Centrinio tepimo sistemos užpildymas</div>
              <div className={style.quizAnswer}>
                {quizObj?.fillingCentralLubricationSystem.value}
                {quizObj?.fillingCentralLubricationSystem.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.fillingCentralLubricationSystem.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Variklio alyvos lygis</div>
              <div className={style.quizAnswer}>
                {quizObj?.engineOilLevel.value}
                {quizObj?.engineOilLevel.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.engineOilLevel.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Transmisinės alyvos lygis</div>
              <div className={style.quizAnswer}>
                {quizObj?.transmissionOilLevel.value}
                {quizObj?.transmissionOilLevel.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.transmissionOilLevel.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Aušinimo skysčio lygis</div>
              <div className={style.quizAnswer}>
                {quizObj?.coolantLevel.value}
                {quizObj?.coolantLevel.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.coolantLevel.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Hidraulinės alyvos lygis</div>
              <div className={style.quizAnswer}>
                {quizObj?.hydraulicOilLevel.value}
                {quizObj?.hydraulicOilLevel.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.hydraulicOilLevel.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Kabinos konstrukcija</div>
              <div className={style.quizAnswer}>
                {quizObj?.cabinStructure.value}
                {quizObj?.cabinStructure.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.cabinStructure.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Apšvietimo elementai</div>
              <div className={style.quizAnswer}>
                {quizObj?.lightingElements.value}
                {quizObj?.lightingElements.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.lightingElements.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Salono detalės</div>
              <div className={style.quizAnswer}>
                {quizObj?.interiorDetails.value}
                {quizObj?.interiorDetails.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.interiorDetails.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Stiklai</div>
              <div className={style.quizAnswer}>
                {quizObj?.windows.value}
                {quizObj?.windows.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.windows.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Saugos elementai</div>
              <div className={style.quizAnswer}>
                {quizObj?.safetyElements.value}
                {quizObj?.safetyElements.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.safetyElements.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Padangų būklė</div>
              <div className={style.quizAnswer}>
                {quizObj?.tireCondition.value}
                {quizObj?.tireCondition.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.tireCondition.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Vikšrinės pavaros sistemos būklė</div>
              <div className={style.quizAnswer}>
                {quizObj?.steeringGearSystemCondition.value}
                {quizObj?.steeringGearSystemCondition.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.steeringGearSystemCondition.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Techninių skysčių nuotekis</div>
              <div className={style.quizAnswer}>
                {quizObj?.technicalFluidLeaks.value}
                {quizObj?.technicalFluidLeaks.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.technicalFluidLeaks.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Kaušo peilio būklė</div>
              <div className={style.quizAnswer}>
                {quizObj?.bucketBladeCondition.value}
                {quizObj?.bucketBladeCondition.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.bucketBladeCondition.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <div className={style.quiz}>
              <div className={style.quizQuestion}>Laisvumas technikos elementų sujungimo vietose</div>
              <div className={style.quizAnswer}>
                {quizObj?.freedomOfJointsInMachineComponents.value}
                {quizObj?.freedomOfJointsInMachineComponents.comment && (
                  <>
                    <div>Komentaras:</div>
                    <div>{quizObj?.freedomOfJointsInMachineComponents.comment}</div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          <Form>
            <Divider orientation="left" orientationMargin="0">
              Tepimo taškų ir sistemos patikra
            </Divider>
            <div>
              <RadioFormik
                name="quiz.spotLubricationLoadLiftingDevices.value"
                placeholder="Krovinio kėlimo įtaisų taškinis tepimas"
                disabled={disabled}
                options={[
                  {
                    label: 'Tepama',
                    value: 'Tepama',
                  },
                  {
                    label: 'Netepama',
                    value: 'Netepama',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik
              disabled={disabled}
              name="quiz.spotLubricationLoadLiftingDevices.comment"
              placeholder="Komentaras"
            />
            <br />
            <div>
              <RadioFormik
                name="quiz.lubricationCardanDrive.value"
                placeholder="Kardaninės pavaros tepimas"
                disabled={disabled}
                options={[
                  {
                    label: 'Tepama',
                    value: 'Tepama',
                  },
                  {
                    label: 'Netepama',
                    value: 'Netepama',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik
              disabled={disabled}
              name="quiz.lubricationCardanDrive.comment"
              placeholder="Komentaras"
            />
            <br />
            <div>
              <RadioFormik
                name="quiz.fillingCentralLubricationSystem.value"
                placeholder="Centrinio tepimo sistemos užpildymas"
                disabled={disabled}
                options={[
                  {
                    label: 'Tepama',
                    value: 'Tepama',
                  },
                  {
                    label: 'Netepama',
                    value: 'Netepama',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik
              disabled={disabled}
              name="quiz.fillingCentralLubricationSystem.comment"
              placeholder="Komentaras"
            />

            <Divider orientation="left" orientationMargin="0">
              Techninių skysčių patikra
            </Divider>
            <div>
              <RadioFormik
                name="quiz.engineOilLevel.value"
                placeholder="Variklio alyvos lygis"
                disabled={disabled}
                options={[
                  {
                    label: 'Tinkamas',
                    value: 'Tinkamas',
                  },
                  {
                    label: 'Netinkamas',
                    value: 'Netinkamas',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik
              disabled={disabled}
              name="quiz.engineOilLevel.comment"
              placeholder="Komentaras"
            />
            <br />
            <div>
              <RadioFormik
                name="quiz.transmissionOilLevel.value"
                placeholder="Transmisinės alyvos lygis"
                disabled={disabled}
                options={[
                  {
                    label: 'Tinkamas',
                    value: 'Tinkamas',
                  },
                  {
                    label: 'Netinkamas',
                    value: 'Netinkamas',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik
              disabled={disabled}
              name="quiz.transmissionOilLevel.comment"
              placeholder="Komentaras"
            />
            <br />
            <div>
              <RadioFormik
                name="quiz.coolantLevel.value"
                placeholder="Aušinimo skysčio lygis"
                disabled={disabled}
                options={[
                  {
                    label: 'Tinkamas',
                    value: 'Tinkamas',
                  },
                  {
                    label: 'Netinkamas',
                    value: 'Netinkamas',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.coolantLevel.comment" placeholder="Komentaras" />
            <br />
            <div>
              <RadioFormik
                name="quiz.hydraulicOilLevel.value"
                placeholder="Hidraulinės alyvos lygis"
                disabled={disabled}
                options={[
                  {
                    label: 'Tinkamas',
                    value: 'Tinkamas',
                  },
                  {
                    label: 'Netinkamas',
                    value: 'Netinkamas',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.hydraulicOilLevel.comment" placeholder="Komentaras" />

            <Divider orientation="left" orientationMargin="0">
              Kėbulo elementų patikra
            </Divider>
            <div>
              <RadioFormik
                name="quiz.cabinStructure.value"
                placeholder="Kabinos konstrukcija"
                disabled={disabled}
                options={[
                  {
                    label: 'Nepažeista',
                    value: 'Nepažeista',
                  },
                  {
                    label: 'Pažeista',
                    value: 'Pažeista',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.cabinStructure.comment" placeholder="Komentaras" />
            <br />
            <div>
              <RadioFormik
                name="quiz.lightingElements.value"
                placeholder="Apšvietimo elementai"
                disabled={disabled}
                options={[
                  {
                    label: 'Nepažeista',
                    value: 'Nepažeista',
                  },
                  {
                    label: 'Pažeista',
                    value: 'Pažeista',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.lightingElements.comment" placeholder="Komentaras" />
            <br />
            <div>
              <RadioFormik
                name="quiz.interiorDetails.value"
                placeholder="Salono detalės"
                disabled={disabled}
                options={[
                  {
                    label: 'Nepažeista',
                    value: 'Nepažeista',
                  },
                  {
                    label: 'Pažeista',
                    value: 'Pažeista',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.interiorDetails.comment" placeholder="Komentaras" />
            <br />
            <div>
              <RadioFormik
                name="quiz.windows.value"
                placeholder="Stiklai"
                disabled={disabled}
                options={[
                  {
                    label: 'Nepažeista',
                    value: 'Nepažeista',
                  },
                  {
                    label: 'Pažeista',
                    value: 'Pažeista',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.windows.comment" placeholder="Komentaras" />
            <br />
            <div>
              <RadioFormik
                name="quiz.safetyElements.value"
                placeholder="Saugos elementai"
                disabled={disabled}
                options={[
                  {
                    label: 'Nepažeista',
                    value: 'Nepažeista',
                  },
                  {
                    label: 'Pažeista',
                    value: 'Pažeista',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.safetyElements.comment" placeholder="Komentaras" />

            <Divider orientation="left" orientationMargin="0">
              Važiuoklės patikra
            </Divider>
            <div>
              <RadioFormik
                name="quiz.tireCondition.value"
                placeholder="Padangų būklė"
                disabled={disabled}
                options={[
                  {
                    label: 'Tinkama',
                    value: 'Tinkama',
                  },
                  {
                    label: 'Netinkama',
                    value: 'Netinkama',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.tireCondition.comment" placeholder="Komentaras" />
            <br />
            <div>
              <RadioFormik
                name="quiz.steeringGearSystemCondition.value"
                placeholder="Vikšrinės pavaros sistemos būklė"
                disabled={disabled}
                options={[
                  {
                    label: 'Tinkama',
                    value: 'Tinkama',
                  },
                  {
                    label: 'Netinkama',
                    value: 'Netinkama',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.steeringGearSystemCondition.comment" placeholder="Komentaras" />
            <br />

            <Divider orientation="left" orientationMargin="0">
              Techninių skysčių nuotekis
            </Divider>
            <div>
              <RadioFormik
                name="quiz.technicalFluidLeaks.value"
                disabled={disabled}
                options={[
                  {
                    label: 'Nepastebėta',
                    value: 'Nepastebėta',
                  },
                  {
                    label: 'Pastebėta',
                    value: 'Pastebėta',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.technicalFluidLeaks.comment" placeholder="Komentaras" />

            <Divider orientation="left" orientationMargin="0">
              Kaušo peilio būklė
            </Divider>
            <div>
              <RadioFormik
                name="quiz.bucketBladeCondition.value"
                disabled={disabled}
                options={[
                  {
                    label: 'Gera',
                    value: 'Gera',
                  },
                  {
                    label: 'Bloga',
                    value: 'Bloga',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik disabled={disabled} name="quiz.bucketBladeCondition.comment" placeholder="Komentaras" />

            <Divider orientation="left" orientationMargin="0">
              Laisvumas technikos elementų sujungimo vietose
            </Divider>
            <div>
              <RadioFormik
                name="quiz.freedomOfJointsInMachineComponents.value"
                disabled={disabled}
                options={[
                  {
                    label: 'Nepastebėta',
                    value: 'Nepastebėta',
                  },
                  {
                    label: 'Pastebėta',
                    value: 'Pastebėta',
                  },
                ]}
              />
            </div>
            <br />
            <FieldFormik
              disabled={disabled}
              name="quiz.freedomOfJointsInMachineComponents.comment"
              placeholder="Komentaras"
            />

            <AllowedTo roles={[UserRoles.Mechanic, UserRoles.Admin, UserRoles.ServiceManager]}>
              <Button type="primary" size="large" htmlType="submit" loading={isLoadingFinish}>
                {t('tasks.endWork.save')}
              </Button>
            </AllowedTo>
          </Form>
        </Formik>
      )}
      <AllowedTo roles={[UserRoles.Admin, UserRoles.ServiceManager]}>
        {(taskStatus === TaskStatusEnum.Finished || taskStatus === TaskStatusEnum.Signed) && (
          <>
            <Divider orientation="left" orientationMargin="0">
              {t('tasks.viewTabs.sign')}
            </Divider>
            <Formik
              initialValues={signInitialValues}
              validationSchema={signValidationSchema}
              onSubmit={onSubmitSign}
              enableReinitialize
            >
              {({ values }) => (
                <Form className="not-card">
                  <Row gutter={[20, 0]}>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="date_start" placeholder={t('tasks.endWork.dateStart')} showTime />
                    </Col>
                    <Col xs={24} md={12}>
                      <DatePickerFormik name="date_end" placeholder={t('tasks.endWork.dateEnd')} showTime />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="work_duration_hours"
                        placeholder={t('tasks.endWork.workDurationHours')}
                        type="number"
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <FieldFormik
                        name="work_duration_minutes"
                        placeholder={t('tasks.endWork.workDurationMinutes')}
                        type="number"
                      />
                    </Col>
                    <Col span={24}>
                      <List
                        bordered
                        header={(
                          <Row gutter={[20, 0]}>
                            <Col span={6}>{t('details.columns.name')}</Col>
                            <Col span={6}>{t('details.columns.serialNumber')}</Col>
                            <Col span={4}>{t('details.columns.quantity')}</Col>
                            <Col span={4}>{t('details.columns.quantity')}</Col>
                            <Col span={4}>{t('details.columns.worker')}</Col>
                          </Row>
                        )}
                        dataSource={values.details}
                        renderItem={(item, index) => (
                          <List.Item>
                            <Row style={{ width: '100%' }} gutter={[20, 0]}>
                              <Col span={6}>{item.name}</Col>
                              <Col span={6}>{item.serial_number}</Col>
                              <Col span={4}>{item.quantity}</Col>
                              <Col span={4}>
                                <FieldFormik
                                  name={`details[${index}].spent_quantity`}
                                  placeholder="quantity"
                                  size="middle"
                                  noMarginBottom
                                  type="number"
                                />
                              </Col>
                              <Col span={4}>
                                <SelectFormik
                                  name={`details[${index}].user_id`}
                                  options={data.workers.map((worker) => ({ value: worker.id, label: `${worker.name} ${worker.surname}` }))}
                                />
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Button type="primary" htmlType="submit" loading={isLoadingSign}>
                    {t('tasks.endWork.save')}
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </AllowedTo>
    </div>
  )
}
