import { FC, ReactNode } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteProps } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import { UserRoles } from '@src/types/users'
import { Page404 } from '@src/Pages/404/404'
import { authApi } from '@src/store/services/auth-service'

type PrivateRouteProps = {
  children: ReactNode
  roles?: UserRoles[]
} & RouteProps

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, roles, ...rest }) => {
  const { data: user } = authApi.useGetMeQuery()

  return (
    <Route
      {...rest}
      render={(props): any => {
        if (!user) {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        if (roles && roles.indexOf(user.role) === -1) {
          return <Page404 />
        }

        return children
      }}
    />
  )
}
