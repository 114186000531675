import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnProps } from 'antd/lib/table'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { Popconfirm } from 'antd'
import moment from 'moment'
import { DeleteOutlined, FileDoneOutlined } from '@ant-design/icons'
import { PageTable } from '@src/components/Table/PageTable'
import useQuery from '@src/hooks/useQuery'
import { tasksApi } from '@src/store/services/tasks-service'
import { TaskStatusEnum, TaskType } from '@src/types/tasks'
import { isSelectionText } from '@src/lib/utils'

type Props = {
  carId: number
}

export const CarHistory = ({ carId }: Props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: {
      status: {
        $eq: TaskStatusEnum.Finished,
      },
      car_id: {
        $eq: carId,
      },
    },
  })
  const { data, isLoading } = tasksApi.useGetTasksListQuery(paramsFilters)
  const [deleteDetail, { isLoading: isLoadingDeleteDetail }] = tasksApi.useDeleteTaskMutation()
  const [taskInvoice, { isLoading: isLoadingInvoice }] = tasksApi.useTaskInvoiceMutation()

  const onCell = useCallback((record: TaskType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/mechanic/tasks-archive/view/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<TaskType>[]>(() => [
    {
      title: t('tasks.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('tasks.columns.workType'),
      dataIndex: 'work_type',
      key: 'work_type',
      render: (workType) => workType.name,
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.name'),
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      onCell,
    },
    {
      title: t('tasks.columns.client'),
      dataIndex: 'client',
      key: 'client',
      render: (client) => client?.name,
      onCell,
    },
    {
      title: t('tasks.columns.car'),
      dataIndex: 'car',
      key: 'car',
      render: (car) => `${car.make}/${car.model}(${car.serial_number})`,
      onCell,
    },
    {
      title: t('tasks.columns.plannedDateStart'),
      dataIndex: 'planned_date_start',
      key: 'planned_date_start',
      render: (plannedDateStart) => moment(plannedDateStart).format('YYYY-MM-DD HH:mm'),
      onCell,
    },
    {
      title: t('tasks.columns.plannedDateEnd'),
      dataIndex: 'planned_date_end',
      key: 'planned_date_end',
      render: (plannedDateEnd) => moment(plannedDateEnd).format('YYYY-MM-DD HH:mm'),
      onCell,
    },
    {
      title: t('tasks.columns.author'),
      dataIndex: 'author',
      key: 'author',
      render: (author) => `${author.name} ${author.surname}`,
      onCell,
    },
    {
      title: t('tasks.columns.workers'),
      dataIndex: 'workers',
      key: 'workers',
      // eslint-disable-next-line react/no-danger
      render: (workers) => <div dangerouslySetInnerHTML={{ __html: workers.map((item: any) => `${item.name} ${item.surname}`).join('<br/>') }} />,
      onCell,
    },
    {
      title: t('tasks.columns.description'),
      dataIndex: 'description',
      key: 'description',
      width: '200px',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className="table_actions">
          {record.status === TaskStatusEnum.New && (
            <Popconfirm
              placement="left"
              title={t('popconfirm.deleteTitle')}
              onConfirm={() => {
                deleteDetail(record.id)
              }}
              okText={t('popconfirm.okText')}
              cancelText={t('popconfirm.cancelText')}
              disabled={isLoadingDeleteDetail}
            >
              <button
                type="button"
                className={cn('table_actions_item', 'table_actions_item__delete')}
                disabled={isLoadingDeleteDetail}
              >
                <DeleteOutlined />
              </button>
            </Popconfirm>
          )}
          {record.status === TaskStatusEnum.Signed && (
            <button
              type="button"
              className={cn('table_actions_item')}
              onClick={() => taskInvoice(record.id)}
              disabled={isLoadingInvoice}
            >
              <FileDoneOutlined />
            </button>
          )}
        </div>
      ),
    },
  ], [t, onCell, paramsFilters.page, data?.meta.pageSize, isLoadingDeleteDetail, isLoadingInvoice, deleteDetail, taskInvoice])

  return (
    <PageTable
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
      isTableOnly
    />
  )
}
