import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { getCookie } from '@src/lib/cookie'
import enLang from './en.json'
import ltLang from './lt.json'
import ruLang from './ru.json'

const resources: any = {
  lt: {
    translation: ltLang,
  },
  en: {
    translation: enLang,
  },
  ru: {
    translation: ruLang,
  },
}

let userLang = getCookie('lang') || navigator.language?.substr(0, 2)?.toLowerCase() || 'en'

if (typeof resources[userLang] === 'undefined') {
  userLang = 'en'
}

document.documentElement.lang = userLang

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: userLang,
  interpolation: {
    escapeValue: false,
  },
})

export { i18n }
