import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ColumnProps } from 'antd/lib/table'
import { Popconfirm } from 'antd'
import cn from 'classnames'
import useQuery from '@src/hooks/useQuery'
import { PageTable } from '@src/components/Table/PageTable'
import { carCategoriesApi } from '@src/store/services/car-categories-service'
import { CarCategoryType } from '@src/types/carCategories'
import style from '@src/Pages/Cars/cars.module.scss'
import { DeleteOutlined } from '@ant-design/icons'
import { isSelectionText } from '@src/lib/utils'

export const CarCategories = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
  })
  const { data, isLoading } = carCategoriesApi.useGetCarCategoriesListQuery(paramsFilters)
  const [deleteCarCategory, { isLoading: isLoadingDeleteCarCategory }] = carCategoriesApi.useDeleteCarCategoryMutation()

  const onCell = useCallback((record: CarCategoryType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/car-categories/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<CarCategoryType>[]>(() => [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('carCategories.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteCarCategory(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDeleteCarCategory}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDeleteCarCategory}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteCarCategory, isLoadingDeleteCarCategory, onCell, paramsFilters.page, t])

  return (
    <div>
      <PageTable
        title={t('carCategories.title')!}
        createLink="/car-categories/create"
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          isLoading,
          columns,
        }}
      />
    </div>
  )
}
