import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { CreateInventoryRequest, EditInventoryRequest, InventoryType } from '@src/types/inventories'

export const inventoriesApi = createApi({
  reducerPath: 'inventoriesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Inventories'],
  endpoints: (build) => ({
    getInventoriesList: build.query<GetItemsType<InventoryType>, { page: number }>({
      query: (params) => ({
        url: '/inventories',
        params,
      }),
      providesTags: () => ['Inventories'],
    }),
    getOneInventory: build.query<InventoryType, number>({
      query: (id) => ({
        url: `/inventories/${id}`,
      }),
      providesTags: () => ['Inventories'],
    }),
    createInventory: build.mutation<InventoryType, CreateInventoryRequest>({
      query: (body) => ({
        url: '/inventories',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Inventories'],
    }),
    updateInventory: build.mutation<InventoryType, EditInventoryRequest>({
      query: ({ id, ...body }) => ({
        url: `/inventories/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Inventories'],
    }),
    deleteInventory: build.mutation<void, number>({
      query: (id) => ({
        url: `/inventories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Inventories'],
    }),
  }),
})
