import { FC, useMemo } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { tasksApi } from '@src/store/services/tasks-service'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { TaskCommentType } from '@src/types/tasks'
import { authApi } from '@src/store/services/auth-service'
import cn from 'classnames'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import { Image } from '@src/components/ImagesPreview/Image'
import style from './taskComments.module.scss'

type TaskCommentsProps = {
  taskId: number
  comments: TaskCommentType[]
  readonly?: boolean
}

export const TaskComments: FC<TaskCommentsProps> = ({ taskId, comments, readonly }) => {
  const { t } = useTranslation()
  const [addTaskComment, { isLoading }] = tasksApi.useAddTaskCommentMutation()
  const { data: user } = authApi.useGetMeQuery()

  const initialValues = useMemo(() => ({
    comment: '',
    images: [],
  }), [])

  const onSubmit = async (values: any, { resetForm }: any) => {
    const formData = new FormData()
    formData.append('comment', values.comment)
    if (values.images?.length) {
      values.images.forEach((image: any) => {
        formData.append('images[]', image.originFileObj)
      })
    }

    await addTaskComment({
      taskId,
      body: formData,
    })

    resetForm()
  }

  return (
    <div>
      {!readonly && (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form className="not-card">
              <FieldFormik
                name="comment"
                type="textarea"
                autoSize={{ minRows: 1, maxRows: 10 }}
                placeholder={t('tasks.comments.field')}
              />
              <UploadImages
                fileList={values.images}
                setFileList={(image) => { setFieldValue('images', image) }}
              />
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('tasks.comments.save')}
              </Button>
            </Form>
          )}
        </Formik>
      )}
      <div className={style.comments}>
        {comments.map((comment) => (
          <div key={comment.id} className={cn(style.comment, { [style.commentMe]: user?.id === comment.user_id })}>
            <div className={style.name}>
              {comment.user.name}
              {' '}
              {comment.user.surname}
            </div>
            <div>{comment.comment}</div>
            {!!comment.images?.length && (
              <div className={style.images}>
                {comment.images.map((item) => (
                  <Image
                    url={`${process.env.REACT_APP_API_URL}/${item.file}`}
                    thumb={`${process.env.REACT_APP_API_URL}/${item.file}`}
                  />
                ))}
              </div>
            )}
          </div>
        )).reverse()}
      </div>
    </div>
  )
}
