import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable'
import Preview from './Preview'
import { formatImages } from './utils'
import styles from './ImagesPreview.module.scss'

export type Image = {
  id: number
  image: string
  thumb: string
  order: number
}

type ImagesPreviewProps = {
  images: Image[]
  groupKey: string
  onReorderImages: (data: Image[]) => void
}

function reorderList(originalList: Image[], id: number, order: number) {
  const updatedItemIndex = originalList.findIndex((item) => item.id === id)

  const updatedItem = originalList[updatedItemIndex]
  const updatedArray = [...originalList]

  updatedArray.splice(updatedItemIndex, 1)
  updatedArray.splice(order - 1, 0, updatedItem)

  return updatedArray.map((item, index) => ({ ...item, order: index + 1 }))
}

function sortList(a: Image, b: Image) {
  return a.order - b.order
}

const ImagesPreview = ({ images, groupKey: key, onReorderImages }: ImagesPreviewProps) => {
  const [list, setList] = useState(images.map((el, idx) => ({ ...el, order: el.order || idx + 1 })))
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  })

  useEffect(() => {
    setList(images.map((el, idx) => ({ ...el, order: el.order || idx + 1 })))
  }, [images])

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active.id !== over?.id) {
        const item = list.find((i) => i.id === active.id)
        const order = list.find((i) => i.id === over?.id)?.order

        if (item && order) {
          const updatedList = reorderList(list, item.id, order)
          setList(updatedList)
          if (onReorderImages) onReorderImages(updatedList)
        }
      }
    },
    [list, onReorderImages],
  )

  const formattedImages = useMemo(() => formatImages(list, key), [list, key])

  return (
    <div className={styles.wrapper}>
      <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
        <SortableContext items={formattedImages.map((i) => i.id)} strategy={horizontalListSortingStrategy}>
          {formattedImages.sort(sortList).map((el) => (
            <Preview {...el} key={el.id} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  )
}

export default ImagesPreview
