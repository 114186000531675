import axios, { Method, ResponseType } from 'axios'
import { keysToCamelCase, keysToSnakeCase } from '@src/lib/utils'
import { store } from '@src/store/store'
import { logout } from '@src/store/ducks/app/thunks'

type CallApiType = {
  url: string
  data?: object
  params?: object
  headers?: object
  responseType?: ResponseType
  method: Method
}

const callApi = async ({
  url, method, data, params = {}, headers = {}, responseType,
}:CallApiType) => {
  const config = {
    url,
    method,
    params: keysToSnakeCase(params),
    // @ts-ignore
    data: headers['Content-Type'] ? data : keysToSnakeCase(data),
    responseType,
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
      ...axios.defaults.headers.common,
      'Content-Type': 'application/json',
      lang: document.documentElement.lang,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      ...headers,
    },
  }
  try {
    const response = await axios(config)

    if (responseType) {
      return response.data
    }

    return keysToCamelCase(response.data)
  } catch (err: any) {
    if (err?.response?.status === 401) {
      store.dispatch(logout())
    }
    return Promise.reject(err)
  }
}

export const api = {
  async get<T = any>(url: string, params?: object, headers?: object, responseType?: ResponseType): Promise<T> {
    return callApi({
      url, params, headers, responseType, method: 'GET',
    })
  },
  async post<T = any>(url: string, data?: object, params?: object, headers?: object): Promise<T> {
    return callApi({
      url, data, params, headers, method: 'POST',
    })
  },
  async put<T = any>(url: string, data?: object, params?: object, headers?: object): Promise<T> {
    return callApi({
      url, data, params, headers, method: 'PUT',
    })
  },
  async del<T = any>(url: string, params?: object, headers?: object): Promise<T> {
    return callApi({
      url, params, headers, method: 'DELETE',
    })
  },
}

export type ResponseMetaType = {
  currentPage: number
  lastPage: number
  pageSize: number
  totalCount: number
}

export type GetItemsType<I, C = {}> = {
  data: Array<I>
  meta: ResponseMetaType
} & C
