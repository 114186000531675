import qs from 'qs'
import lodash from 'lodash'

export const formatFilters = (params: Record<string, any>) => {
  const transformedArray = lodash.map(params.filters, (value, key) => {
    const parts = key.split(/(?<!\|)\./).map((part) => part.replace('|', ''))

    return lodash.set({}, parts, value)
  })

  return qs.stringify({
    ...params,
    filters: {
      $and: transformedArray,
    },
  })
}
