import React, { useCallback, useMemo, useState } from 'react'
import { ColumnProps } from 'antd/lib/table'
import { Agreement } from '@src/types/agreements'
import cn from 'classnames'
import style from '@src/Pages/Agreements/agreements.module.scss'
import {
  DeleteOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  IssuesCloseOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import { agreementsApi } from '@src/store/services/agreements-service'
import { CloseAgreementForm } from '@src/components/pages/agreements/CloseAgreementForm'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { isSelectionText } from '@src/lib/utils'

export const useAgreementsTable = ({ initParamsFilters, skip }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch<AppDispatch>()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = agreementsApi.useGetListAgreementsQuery(paramsFilters, { skip })
  const [deleteAgreement, { isLoading: isLoadingDelete }] = agreementsApi.useDeleteAgreementMutation()
  const [closeAgreementId, setCloseAgreementId] = useState<null | number>(null)

  const onCell = useCallback((record: Agreement) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreements/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<Agreement>[]>(() => [
    {
      title: t('agreements.columns.index'),
      dataIndex: 'index',
      key: 'index',
      width: 50,
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('agreements.columns.customer'),
      dataIndex: 'customer',
      key: 'customer',
      width: 200,
      render: (_, record) => record.customer.name,
      onCell,
    },
    {
      title: t('agreements.columns.makeModel'),
      dataIndex: 'makeModel',
      key: 'makeModel',
      width: 200,
      align: 'center',
      render: (_, record) => `${record.car.make}/${record.car.model}`,
      onCell,
    },
    {
      title: t('agreements.columns.serialNumber'),
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      width: 200,
      align: 'center',
      render: (_, record) => record.car.serial_number,
      onCell,
    },
    {
      title: t('agreements.columns.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      width: 120,
      align: 'center',
      onCell,
    },
    {
      title: t('agreements.columns.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      width: 120,
      align: 'center',
      onCell,
    },
    {
      title: t('agreements.columns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      align: 'center',
      render: (status) => (status ? (
        <div className={cn(style.status, style.status__close)}>
          {t('agreements.columns.statusClosed')}
        </div>
      ) : (
        <div className={cn(style.status, style.status__open)}>
          {t('agreements.columns.statusOpen')}
        </div>
      )),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <div className={style.actions}>
          <a
            // eslint-disable-next-line max-len
            href={`${process.env.REACT_APP_API_URL}/api/agreements/generate-open/${record.id}?access_token=${localStorage.getItem('token')}`}
            target="_blank"
            rel="noreferrer"
            className={style.actions_item}
          >
            <FileTextOutlined />
          </a>
          {record.status === 1 && (
            <a
              // eslint-disable-next-line max-len
              href={`${process.env.REACT_APP_API_URL}/api/agreements/generate-close/${record.id}?access_token=${localStorage.getItem('token')}`}
              target="_blank"
              rel="noreferrer"
              className={style.actions_item}
            >
              <FileDoneOutlined />
            </a>
          )}
          {record.status === 0 && (
            <button
              type="button"
              onClick={() => {
                dispatch(setSendEmail({
                  type: SendEmailType.Agreements,
                  companyId: record.customer.id,
                  id: record.id,
                }))
              }}
              className={style.actions_item}
            >
              <MailOutlined />
            </button>
          )}
          {record.status === 0 && (
            <button
              type="button"
              className={style.actions_item}
              onClick={() => { setCloseAgreementId(record.id) }}
            >
              <IssuesCloseOutlined />
            </button>
          )}
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteAgreement(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDelete}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDelete}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteAgreement, dispatch, isLoadingDelete, onCell, paramsFilters.page, t])

  const AgreementsComponents = useMemo(() => (
    <CloseAgreementForm agreementId={closeAgreementId} onClose={() => setCloseAgreementId(null)} />
  ), [closeAgreementId])

  return {
    columns,
    data,
    isLoading,
    paramsFilters,
    setParamsFilters,
    AgreementsComponents,
  }
}
