import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { Preloader } from '@src/components/Preloader/Preloader'
import { stocksApi } from '@src/store/services/stocks-service'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import * as Yup from 'yup'

export const StockAreasCreate = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams<{ id?: string, stock: string }>()
  const { data, isLoading } = stocksApi.useGetOneStockAreaQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = stocksApi.useCreateStockAreaMutation()
  const [update, { isLoading: isLoadingUpdate }] = stocksApi.useUpdateStockAreaMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: data?.name || '',
    address: data?.address || '',
    notes: data?.notes || '',
  }), [data])

  const onSubmit = async (values: any) => {
    let result
    if (params.id) {
      result = await update({
        ...values,
        stock_id: params.stock,
        id: params.id,
      })
    } else {
      result = await create({
        ...values,
        stock_id: params.stock,
      })
    }

    if ('data' in result) {
      history.goBack()
    }
  }

  return (
    <div>
      <h1 className="page_title">{params.id ? t('stockAreas.titleEdit') : t('stockAreas.titleCreate')}</h1>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          <Form>
            <Row gutter={[20, 0]}>
              <Col span={24}>
                <FieldFormik
                  name="name"
                  placeholder={t('stockAreas.columns.name') ?? ''}
                />
              </Col>
              <Col span={24}>
                <FieldFormik
                  name="address"
                  placeholder={t('stockAreas.columns.address') ?? ''}
                />
              </Col>
              <Col span={24}>
                <FieldFormik
                  name="notes"
                  placeholder={t('stockAreas.columns.notes') ?? ''}
                />
              </Col>
            </Row>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingUpdate}
            >
              {t('stockAreas.save')}
            </Button>
          </Form>
        </Formik>
      </Preloader>
    </div>
  )
}
