import { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Divider, Input, Modal, notification, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@src/store/store'
import { invoicesApi } from '@src/store/services/invoices-service'
import { sendEmailCompanyTransportationBills } from '@src/store/ducks/transportation/thunks'
import { PlusOutlined } from '@ant-design/icons'
import { debtsApi } from '@src/store/services/debts-service'
import {
  selectAppSendEmailCompanyId,
  selectAppSendEmailId,
  selectAppSendEmailIsAccountant,
  selectAppSendEmailType,
} from '@src/store/ducks/app/selectors'
import { SendEmailType } from '@src/types/mailLogs'
import { closeSendEmail } from '@src/store/ducks/app/reducer'
import { agreementsApi } from '@src/store/services/agreements-service'
import { transportationApi } from '@src/store/services/transportation-service'
import { accountingApi } from '@src/store/services/accounting-service'
import { BillsTypeEnum } from '@src/types/settings'
import { serviceInvoicesApi } from '@src/store/services/serviceInvoices-service'

const mapTypes: any = {
  [SendEmailType.AgreementBills]: BillsTypeEnum.AgreementBill,
  [SendEmailType.AgreementInvoices]: BillsTypeEnum.AgreementInvoices,
  [SendEmailType.TransportationBills]: BillsTypeEnum.TransportationBill,
  [SendEmailType.Invoices]: BillsTypeEnum.BusinessInvoices,
}

const accountantEmails = [
  {
    value: 'Svetlana.semaskiene@gmail.com',
    label: 'Svetlana.semaskiene@gmail.com',
  },
  {
    value: 'martynas@brc.lt',
    label: 'martynas@brc.lt',
  },
]

export const SendEmail: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const type = useSelector(selectAppSendEmailType)
  const companyId = useSelector(selectAppSendEmailCompanyId)
  const id = useSelector(selectAppSendEmailId)
  const isAccountant = useSelector(selectAppSendEmailIsAccountant)
  const formRef = useRef<any>()
  const { data: customerWorkers } = customerWorkersApi.useListQuery({ companyId }, { skip: isAccountant })
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false)
  const [sendEmail] = invoicesApi.useSendEmailMutation()
  const [sendDebtEmail] = debtsApi.useSendEmailMutation()
  const [sendEmailCustomerAgreements] = agreementsApi.useSendEmailCustomerAgreementsMutation()
  const [sendEmailCompanyAgreementInvoices] = agreementsApi.useSendEmailCompanyAgreementInvoicesMutation()
  const [sendEmailCompanyAgreementBills] = agreementsApi.useSendEmailCustomerAgreementBillsMutation()
  const [sendEmailAccountant] = accountingApi.useSendEmailAccountantMutation()
  const [sendCustomerServiceInvoice] = serviceInvoicesApi.useSendCustomerServiceInvoiceMutation()
  const [newEmail, setNewEmail] = useState('')
  const [newEmailList, setNewEmailList] = useState<string[]>([])

  useEffect(() => {
    if (type) {
      setNewEmail('')
      setNewEmailList([])
    }
  }, [type])

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      email: Yup.array().min(1).required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => {
    let email: string[] = []
    if (isAccountant) {
      email = [accountantEmails[0].value]
    } else if (customerWorkers?.length && customerWorkers[0].email) {
      email = [customerWorkers[0].email]
    }

    return {
      email,
    }
  }, [customerWorkers, isAccountant])

  const onSubmit = async (emailArr: string[] | string) => {
    setIsLoadingSendEmail(true)
    let result

    const email = typeof emailArr === 'string' ? emailArr : emailArr.join(',')

    if (isAccountant) {
      result = await sendEmailAccountant({ ids: [id], email, type: mapTypes[type!] })

      if ('data' in result) {
        dispatch(agreementsApi.util?.invalidateTags(['Agreements']))
        dispatch(transportationApi.util?.invalidateTags(['Transportation']))
        dispatch(closeSendEmail())
        notification.success({
          message: 'Email Status Notification',
          description: 'Agreement Bill has been successfully send to customer.',
        })
      }
    } else {
      switch (type) {
        case SendEmailType.AgreementBills:
          result = await sendEmailCompanyAgreementBills({ id, email })
          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: 'Email Status Notification',
              description: 'Agreement Bill has been successfully send to customer.',
            })
          }
          break
        case SendEmailType.AgreementInvoices:
          result = await sendEmailCompanyAgreementInvoices({ id, email })
          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: 'Email Status Notification',
              description: 'Agreement has been successfully send to customer.',
            })
          }
          break
        case SendEmailType.Agreements:
          result = await sendEmailCustomerAgreements({ id, email })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: 'Email Status Notification',
              description: 'Agreement has been successfully send to customer.',
            })
          }
          break
        case SendEmailType.Invoices:
          result = await sendEmail({ id, email })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: 'Email Status Notification',
              description: 'Agreement has been successfully send to customer.',
            })
          }
          break
        case SendEmailType.Debts:
          result = await sendDebtEmail({ id, email })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: 'Email Debt Notification',
              description: 'Debt has been successfully send to customer.',
            })
          }
          break
        case SendEmailType.TransportationBills:
          await dispatch(sendEmailCompanyTransportationBills(id, email))
          break
        case SendEmailType.ServiceInvoice:
          result = await sendCustomerServiceInvoice({ id, email })

          if ('data' in result) {
            dispatch(closeSendEmail())
            notification.success({
              message: 'Email Debt Notification',
              description: 'Debt has been successfully send to customer.',
            })
          }
          break
        default:
          break
      }
    }

    dispatch(invoicesApi.util?.invalidateTags(['Invoices']))
    dispatch(accountingApi.util?.invalidateTags(['Accounting']))

    setIsLoadingSendEmail(false)
  }

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value)
  }

  const addItem = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    setNewEmailList((prevState) => [...prevState, newEmail])
    setNewEmail('')
  }

  const options = useMemo(() => {
    const optionsResult: Array<{value: string, label: any}> = []

    const customerWorkersList = customerWorkers?.map((worker) => ({
      value: worker.email,
      label: (
        <div>
          <div>{worker.email}</div>
          <div>
            {worker.name}
            {', '}
            {worker.position}
          </div>
        </div>
      ),
    })) || []
    optionsResult.push(...customerWorkersList)

    if (isAccountant) {
      optionsResult.push(...accountantEmails)
    }

    const customList = newEmailList.map((item) => ({
      value: item,
      label: item,
    }))
    optionsResult.push(...customList)

    return optionsResult
  }, [customerWorkers, isAccountant, newEmailList])

  return (
    <Modal
      title={t('sendEmail.title')}
      open={!!type}
      onOk={() => formRef.current.submitForm()}
      confirmLoading={isLoadingSendEmail}
      onCancel={() => dispatch(closeSendEmail())}
    >
      <p>{t('sendEmail.description')}</p>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values.email)}
        validationSchema={validationSchema}
      >
        <Form>
          <SelectFormik
            name="email"
            isMultiple
            options={options}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Input
                    placeholder="Email"
                    value={newEmail}
                    onChange={onNameChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem} disabled={!newEmail}>
                    Add email
                  </Button>
                </Space>
              </>
            )}
          />
        </Form>
      </Formik>
    </Modal>
  )
}
