import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { usePrintableBlock } from '@src/hooks/usePrintableBlock'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { companiesApi } from '@src/store/services/companies-service'
import moment from 'moment'
import { useMemo } from 'react'
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import n2words from 'n2words'
import { customRound } from '@src/lib/converter'

export const InvoicesDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  usePrintableBlock('#printable-block')
  const { data: company } = companiesApi.useGetOneCompanyQuery(values.company_id, { skip: !values.company_id })

  const price = useMemo(() => values.services.reduce((accumulator: number, currentValue: any) => accumulator + currentValue.value * currentValue.amount, 0), [values])
  console.log()
  const pvm = useMemo(() => price * (values.with_pvm ? 0.21 : 0), [price, values.with_pvm])

  const total = useMemo(() => price + pvm, [pvm, price])

  return (
    <>
      <div className="invoices_document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {values.isCredit ? 'KREDITINĖ ' : ''}
          PVM SĄSKAITA-FAKTŪRA
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          Serija SEN Nr.
          {' '}
          {values.bill_number}
        </p>
        <table style={{ fontSize: 13, tableLayout: 'fixed', width: '100%' }}>
          <tr>
            <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
              Pardavėjas
              <br />
              <strong>UAB Senresa</strong>
              <br />
              Vilų g. 36A - 60, LT-93102, Neringa
              <br />
              Įmonės kodas 304865983
              <br />
              PVM mok.kodas LT100012516511
              <br />
              A/s LT597180300038467953
            </td>
            <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
              Pirkėjas
              <br />
              <strong>{company?.name}</strong>
              <br />
              {company?.address}
              <br />
              Įmonės kodas
              {' '}
              {company?.company_code}
              <br />
              PVM mok.kodas
              {' '}
              {company?.vat_number}
            </td>
          </tr>
        </table>
        <p className="text" style={{ textAlign: 'center' }}>
          Data:
          {' '}
          {moment(values.date_closed).format('YYYY-MM-DD')}
        </p>
        <p className="text">
          Apmokėti iki:
          {' '}
          {moment(values.pay_by).format('YYYY-MM-DD')}
        </p>
        <table className="image-table" style={{ width: '100%', fontSize: 13 }}>
          <tr>
            <td className="image-row" style={{ width: '5%', fontWeight: 700, textAlign: 'center' }}>Nr.</td>
            <td className="image-row" style={{ width: '33.5%', fontWeight: 700 }}>Pavadinimas</td>
            <td className="image-row" style={{ width: '15%', fontWeight: 700, textAlign: 'center' }}>Mato vnt.</td>
            <td className="image-row" style={{ width: '10%', fontWeight: 700, textAlign: 'center' }}>Kiekis</td>
            <td className="image-row" style={{ width: '17.5%', fontWeight: 700, textAlign: 'center' }}>Kaina EUR</td>
            <td className="image-row" style={{ width: '19%', fontWeight: 700, textAlign: 'center' }}>Suma EUR</td>
          </tr>
          {values.services.map((item: any, index: number) => (
            <tr>
              <td
                className="image-row"
                style={{ width: '5%', fontWeight: 400, textAlign: 'center' }}
              >
                {index + 1}
                .
              </td>
              <td className="image-row" style={{ width: '33.5%', fontWeight: 400 }}>{item.name}</td>
              <td
                className="image-row"
                style={{ width: '15%', fontWeight: 400, textAlign: 'center' }}
              >
                {item.unit ?? ''}
              </td>
              <td
                className="image-row"
                style={{ width: '10%', fontWeight: 400, textAlign: 'center' }}
              >
                {item.amount ?? ''}
              </td>
              <td
                className="image-row"
                style={{ width: '17.5%', fontWeight: 400, textAlign: 'center' }}
              >
                {!!values.isCredit && '-'}
                {item.value ?? ''}
              </td>
              <td
                className="image-row"
                style={{ width: '19%', fontWeight: 400, textAlign: 'center' }}
              >
                {!!values.isCredit && '-'}
                {item.value * item.amount ?? ''}
              </td>
            </tr>
          ))}
        </table>
        <table style={{ fontSize: 13, width: '100%' }}>
          <tr style={{ width: '100%' }}>
            <td style={{ width: '20%', color: '#ffffff' }}>.</td>
            <td style={{ width: '35%' }}>Iš viso:</td>
            <td style={{ width: '45%', textAlign: 'right' }}>
              {!!values.isCredit && '-'}
              {price.toFixed(2)}
              {' '}
              EUR
            </td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ width: '20%', color: '#ffffff' }}>.</td>
            <td style={{ width: '35%' }}>
              PVM
              {' '}
              {values.with_pvm ? '21' : '0'}
              %
            </td>
            <td style={{ width: '45%', textAlign: 'right' }}>
              {!!values.isCredit && '-'}
              {pvm.toFixed(2)}
              {' '}
              EUR
            </td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ width: '20%', color: '#ffffff' }}>.</td>
            <td style={{ width: '35%' }}>Bendra suma</td>
            <td style={{ width: '45%', textAlign: 'right' }}>
              {!!values.isCredit && '-'}
              {total.toFixed(2)}
              {' '}
              EUR
            </td>
          </tr>
        </table>
        <p className="text">
          Iš viso su PVM:
          {' '}
          {n2words(customRound(total), { lang: 'lt' })}
          {' '}
          EUR,
          {' '}
          {total.toFixed(2).split('.')[1]}
          ct.
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '17%' }}>Sąskaitą išrašė:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '83%', display: 'block', marginLeft: '17%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '20%' }}>Sąskaitą gavo:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '80%', display: 'block', marginLeft: '20%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
