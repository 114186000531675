import { FC, useCallback, useMemo, useState } from 'react'
import { useFormikContext } from 'formik'
import { TimePicker } from 'antd'
import { getObjectValue } from '@src/lib/utils'
import style from '@src/components/FieldFormik/FieldFormik.module.scss'
import cn from 'classnames'

type TimePickerFormikProps = {
  name: string
  placeholder?: string
  disabled?: boolean
}

const disabledHours = () => {
  const hours = [0, 1, 2, 3, 4, 5, 6, 21, 22, 23, 24]
  return hours
}

export const TimePickerFormik: FC<TimePickerFormikProps> = ({
  name, placeholder, disabled,
}) => {
  const {
    setFieldValue, errors, touched, values,
  } = useFormikContext<any>()
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const value = useMemo(() => getObjectValue(name.split('.'), values), [name, values])
  const error = useMemo(() => getObjectValue(name.split('.'), errors), [name, errors])
  const isTouched = useMemo(() => getObjectValue(name.split('.'), touched), [name, touched])
  const isError = useMemo(() => error && isTouched, [error, isTouched])

  const handleChange = useCallback((vt: any) => {
    setFieldValue(name, vt)
  }, [name, setFieldValue])

  return (
    <div className={style.wrapper}>
      <TimePicker
        onChange={handleChange}
        size="large"
        placeholder={placeholder}
        value={value}
        style={{ width: '100%' }}
        disabled={disabled}
        format="HH:mm"
        disabledTime={() => ({
          disabledHours,
        })}
        hideDisabledOptions
        onOpenChange={(open) => {
          setShowPlaceholder(!open)
        }}
      />
      {placeholder && showPlaceholder && (
        <div
          className={cn(style.placeholder, { [style.placeholder_active]: value })}
        >
          {placeholder}
        </div>
      )}
      {isError && <div className={style.error}>{error}</div>}
    </div>
  )
}
