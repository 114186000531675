export type StandardWorkType = {
  id: number
  name: string
  price_working_hour: number
  price_non_working_hour: number
  tax: string
  unit: UnitType
}

export const UNIT = {
  Hours: 'hours',
  Items: 'items',
} as const

export type UnitType = (typeof UNIT)[keyof typeof UNIT]

export type StandardWorkShortType = {
  id: number
  name: string
}
