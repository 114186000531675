import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { useCallback, useMemo } from 'react'
import * as Yup from 'yup'
import { Button } from 'antd'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { UserRoles } from '@src/types/users'
import { authApi } from '@src/store/services/auth-service'
import style from './login.module.scss'

export const Login = () => {
  const { t } = useTranslation()
  const { data: user, refetch } = authApi.useGetMeQuery()
  const [login, { isLoading }] = authApi.useLoginMutation()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      email: Yup.string().email().required(t('form.errors.required') ?? ''),
      password: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    email: '',
    password: '',
  }), [])

  const onSubmit = useCallback(async (data: any) => {
    const result = await login(data)

    if ('data' in result) {
      localStorage.setItem('token', result.data.token)
      refetch()
    }
  }, [login, refetch])

  if (user) {
    switch (user.role) {
      case UserRoles.Admin:
        return <Redirect to="/customers" />
      case UserRoles.Driver:
        return <Redirect to="/transportation" />
      default:
        return <Redirect to="/" />
    }
  }

  return (
    <div className={style.container}>
      <div className={style.gradient} />
      <div className={style.wrap}>
        <h1>{t('login.title')}</h1>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {() => (
            <Form>
              <FieldFormik name="email" placeholder={t('login.email') ?? ''} />
              <FieldFormik name="password" type="password" placeholder={t('login.password') ?? ''} />

              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('login.submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
