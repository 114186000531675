import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TransportationBillStatus } from '@src/types/transportationBills'
import { useHistory } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { TableFiltersType } from '@src/types/tableFilters'
import { companiesApi } from '@src/store/services/companies-service'
import { PageTable } from '@src/components/Table/PageTable'
import { useAgreementsTable } from '@src/components/pages/transportationBills/TransportationBills.hook'

export const TransportationBills = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { data: companiesList } = companiesApi.useGetSelectCompaniesQuery()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')

  const { data, columns, paramsFilters, setParamsFilters, isLoading } = useAgreementsTable({
    initParamsFilters: {
      page: pageQuery ? +pageQuery : 1,
      filters: filtersQuery ? JSON.parse(filtersQuery) : null,
    },
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'select',
      dataIndex: 'to',
      placeholder: t('transportation.columns.to'),
      width: '200px',
      options: companiesList?.map((item) => ({ value: item.id, label: item.name })) || [],
      showSearch: true,
    },
    {
      type: 'input',
      dataIndex: 'bill_number',
      placeholder: t('transportation.columns.billNumber'),
      width: '200px',
    },
    {
      type: 'rangePicker',
      dataIndex: 'date',
      placeholder: [t('filters.dateFrom'), t('filters.dateTo')],
    },
    {
      type: 'select',
      dataIndex: 'status',
      placeholder: t('transportation.columns.status'),
      width: '200px',
      options: [
        {
          value: TransportationBillStatus.notProcessed,
          label: t(`transportationBills.status.${TransportationBillStatus.notProcessed}`),
        },
        {
          value: TransportationBillStatus.processed,
          label: t(`transportationBills.status.${TransportationBillStatus.processed}`),
        },
        {
          value: TransportationBillStatus.archived,
          label: t(`transportationBills.status.${TransportationBillStatus.archived}`),
        },
      ],
    },
    {
      type: 'input',
      dataIndex: 'address_from',
      placeholder: t('transportationBills.columns.addressFrom'),
      width: '200px',
    },
    {
      type: 'input',
      dataIndex: 'address_to',
      placeholder: t('transportationBills.columns.addressTo'),
      width: '200px',
    },
    {
      type: 'select',
      dataIndex: 'isSentAccountant',
      placeholder: t('transportationBills.columns.sentAccountant'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
    {
      type: 'select',
      dataIndex: 'isSentCustomer',
      placeholder: t('transportationBills.columns.sentCustomer'),
      width: '200px',
      options: [
        {
          value: '0',
          label: t('sendStatus.0'),
        },
        {
          value: '1',
          label: t('sendStatus.1'),
        },
      ],
    },
  ], [t, companiesList])

  return (
    <div>
      <PageTable
        title={t('transportationBills.title')!}
        filtersList={filtersList}
        setParamsFilters={setParamsFilters}
        paramsFilters={paramsFilters}
        tableData={{
          ...data,
          isLoading,
          columns,
          components: {
            body: {
              // eslint-disable-next-line max-len
              row: (props: any) => <tr {...props} style={{ background: data?.data.find((item) => item.id === props['data-row-key'])?.company?.color }} />,
            },
          },
        }}
      />
    </div>
  )
}
