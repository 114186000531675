import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { TrucksShortType, TrucksType } from '@src/types/trucks'

export const trucksApi = createApi({
  reducerPath: 'trucksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Trucks'],
  endpoints: (build) => ({
    getTrucksList: build.query<GetItemsType<TrucksType>, { page: number }>({
      query: (params) => ({
        url: '/trucks',
        params,
      }),
      providesTags: () => ['Trucks'],
    }),
    getTrucksListForSelect: build.query<TrucksShortType[], void>({
      query: () => ({
        url: '/trucks/short',
      }),
      providesTags: () => ['Trucks'],
    }),
    deleteTruck: build.mutation<void, number>({
      query: (id) => ({
        url: `/trucks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Trucks'],
    }),
    exportTrucks: build.mutation<void, number[]>({
      query: (trucksIds) => ({
        url: '/trucks/excel-export',
        method: 'POST',
        responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        body: {
          trucksIds,
        },
      }),
    }),
    getOneTruck: build.query<TrucksType, number>({
      query: (id) => ({
        url: `/trucks/${id}`,
      }),
      providesTags: () => ['Trucks'],
    }),
    createTruck: build.mutation<TrucksType, any>({
      query: (body) => ({
        url: '/trucks',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Trucks'],
    }),
    editTruck: build.mutation<TrucksType, any>({
      query: ({ id, ...body }) => ({
        url: `/trucks/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Trucks'],
    }),
  }),
})
