import { Modal } from 'antd'
import { FC, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import * as Yup from 'yup'

type ConfirmEmailProps = {
  email: string
  callback: (email: string) => void
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
}

export const ConfirmEmail: FC<ConfirmEmailProps> = ({ isOpen, callback, isLoading, onClose, email }) => {
  const { t } = useTranslation()
  const formRef = useRef<any>()

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      email: Yup.string().email().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  return (
    <Modal
      title={t('sendEmail.title')}
      open={isOpen}
      onOk={() => formRef.current.submitForm()}
      confirmLoading={isLoading}
      onCancel={onClose}
    >
      <p>{t('sendEmail.description')}</p>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={{ email }}
        onSubmit={(values) => callback(values.email)}
        validationSchema={validationSchema}
      >
        <Form>
          <FieldFormik name="email" />
        </Form>
      </Formik>
    </Modal>
  )
}
