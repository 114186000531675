import { PageTable } from '@src/components/Table/PageTable'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useQuery from '@src/hooks/useQuery'
import { detailsApi } from '@src/store/services/details-service'
import { ColumnProps } from 'antd/lib/table'
import { NavLink, useHistory } from 'react-router-dom'
import { DetailType } from '@src/types/details'
import style from '@src/Pages/Cars/cars.module.scss'
import { Button, Popconfirm } from 'antd'
import cn from 'classnames'
import { CameraOutlined, DeleteOutlined } from '@ant-design/icons'
import { TableFiltersType } from '@src/types/tableFilters'
import { formatPrice } from '@src/lib/formatPrice'
import { isSelectionText } from '@src/lib/utils'

export const Details = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const pageQuery = useQuery('page')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: {},
  })
  const { data, isLoading } = detailsApi.useGetDetailsListQuery(paramsFilters)
  const [deleteDetail, { isLoading: isLoadingDeleteDetail }] = detailsApi.useDeleteDetailMutation()
  const [exportDetails, { isLoading: isLoadingExport }] = detailsApi.useExportDetailsMutation()

  const onCell = useCallback((record: DetailType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/details/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<DetailType>[]>(() => [
    {
      title: t('details.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('details.columns.name'),
      dataIndex: 'name',
      key: 'name',
      onCell,
    },
    {
      title: t('details.columns.serialNumber'),
      dataIndex: 'serial_number',
      key: 'serial_number',
      onCell,
    },
    {
      title: t('details.columns.count'),
      dataIndex: 'count',
      key: 'count',
      onCell,
    },
    {
      title: t('details.columns.unit'),
      dataIndex: 'unit',
      key: 'unit',
      onCell,
    },
    {
      title: t('details.columns.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price) => formatPrice(price),
      onCell,
    },
    {
      title: t('details.columns.vat'),
      dataIndex: 'vat',
      key: 'vat',
      render: (vat, record) => formatPrice((record.price * (vat + 100)) / 100),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      render: (_, record) => (
        <div className={style.actions}>
          <Popconfirm
            placement="left"
            title={t('popconfirm.deleteTitle')}
            onConfirm={() => { deleteDetail(record.id) }}
            okText={t('popconfirm.okText')}
            cancelText={t('popconfirm.cancelText')}
            disabled={isLoadingDeleteDetail}
          >
            <button
              type="button"
              className={cn(style.actions_item, style.actions_item__delete)}
              disabled={isLoadingDeleteDetail}
            >
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteDetail, isLoadingDeleteDetail, onCell, paramsFilters.page, t])

  const filtersList = useMemo<TableFiltersType>(() => [
    {
      type: 'input',
      dataIndex: 'name',
      placeholder: t('details.columns.name'),
      width: '300px',
      operator: '$contains',
    },
    {
      type: 'input',
      dataIndex: 'serialNumbers.serial_number',
      placeholder: t('details.columns.serialNumber'),
      width: '300px',
      operator: '$contains',
    },
  ], [t])

  return (
    <PageTable
      title={t('details.title')!}
      createLink="/details/create"
      filtersList={filtersList}
      filtersV2
      setParamsFilters={setParamsFilters}
      paramsFilters={paramsFilters}
      onExport={exportDetails}
      isLoadingExport={isLoadingExport}
      actions={[
        <NavLink to="/mechanic/get-detail">
          <Button
            type="primary"
            icon={<CameraOutlined />}
            size="large"
          >
            {t('details.scanner')}
          </Button>
        </NavLink>,
      ]}
      tableData={{
        ...data,
        isLoading,
        columns,
      }}
    />
  )
}
