import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card } from 'antd'
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { tasksApi } from '@src/store/services/tasks-service'
import { useWorkTime } from '@src/hooks/useWorkTime'
import style from './TaskView.module.scss'

type TaskWorkTimeProps = {
  taskId?: number
}

export const TaskWorkTime: FC<TaskWorkTimeProps> = ({ taskId }) => {
  const { t } = useTranslation()
  const [startWork, { isLoading: isLoadingStartWork }] = tasksApi.useStartWorkMutation()
  const [stopWork, { isLoading: isLoadingStopWork }] = tasksApi.useStopWorkMutation()
  const lastWorkTimeFormat = useWorkTime(taskId)

  return (
    <Card className={style.workCard}>
      <div className={style.workWrapper}>
        <div>
          {lastWorkTimeFormat.time}
        </div>
        {lastWorkTimeFormat.isWork && lastWorkTimeFormat.workId && (
          <Button
            icon={<PauseCircleOutlined />}
            size="large"
            type="primary"
            onClick={() => { stopWork(lastWorkTimeFormat.workId!) }}
            loading={isLoadingStopWork}
          >
            {t('tasks.stopWork')}
          </Button>
        )}
        {taskId && !lastWorkTimeFormat.isWork && (
          <Button
            icon={<PlayCircleOutlined />}
            size="large"
            type="primary"
            onClick={() => { startWork(taskId) }}
            loading={isLoadingStartWork}
          >
            {t('tasks.startWork')}
          </Button>
        )}
      </div>
    </Card>
  )
}
