import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { Button, Col, Row } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import * as Yup from 'yup'
import { debtsApi } from '@src/store/services/debts-service'
import { companiesApi } from '@src/store/services/companies-service'
import { setSendEmail } from '@src/store/ducks/app/reducer'
import { SendEmailType } from '@src/types/mailLogs'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@src/store/store'

export const DebtsCreate = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const { data, isLoading } = debtsApi.useGetOneQuery(params.id || '', { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = debtsApi.useCreateMutation()
  const [update, { isLoading: isLoadingUpdate }] = debtsApi.useUpdateMutation()
  const { data: companiesSelect } = companiesApi.useGetSelectCompaniesQuery()

  const initialValues = useMemo(() => ({
    sum: data?.sum || '',
    company_id: data?.company_id || '',
  }), [data])

  const onSubmit = async (values: any) => {
    const requestData = {
      ...values,
    }

    let result
    if (params.id) {
      result = await update({
        ...requestData,
        id: params.id,
      })
    } else {
      result = await create(requestData)
    }

    if ('data' in result) {
      if (values.sum) {
        dispatch(setSendEmail({
          type: SendEmailType.Debts,
          companyId: +result.data.company_id,
          id: +result.data.id,
        }))
      }
      history.goBack()
    }
  }

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      company_id: Yup.string().required(t('form.errors.required') ?? ''),
      sum: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  return (
    <div>
      <h1 className="page_title">{params.id ? t('debtsCreate.titleEdit') : t('debtsCreate.title')}</h1>
      <Preloader loading={isLoading}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          <Form>
            <Row gutter={[20, 0]}>
              <Col xs={24} md={12}>
                <SelectFormik
                  name="company_id"
                  showSearch
                  placeholder={t('debtsCreate.company') ?? ''}
                  options={companiesSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik
                  name="sum"
                  placeholder={t('debtsCreate.sum') ?? ''}
                />
              </Col>
            </Row>
            <Button
              style={{ marginTop: 20 }}
              htmlType="submit"
              type="primary"
              loading={isLoadingCreate || isLoadingUpdate}
            >
              {t('debtsCreate.submit')}
            </Button>
          </Form>
        </Formik>
      </Preloader>
    </div>
  )
}
