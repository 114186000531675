import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import React, { useCallback, useMemo, useState } from 'react'
import { transportationApi } from '@src/store/services/transportation-service'
import { TransportationType } from '@src/types/transportation'
import { UserRoles } from '@src/types/users'
import { ColumnProps } from 'antd/lib/table'
import style from '@src/Pages/Transportation/transportation.module.scss'
import { AlibabaOutlined, DeleteOutlined, FileTextOutlined } from '@ant-design/icons'
import { AllowedTo } from '@src/components/AllowedTo/AllowedTo'
import { Popconfirm } from 'antd'
import cn from 'classnames'
import { TransportationSign } from '@src/components/pages/transportation/TransportationSign'
import { isSelectionText } from '@src/lib/utils'

export const useTransportationTable = ({ initParamsFilters }: any) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [paramsFilters, setParamsFilters] = useState(initParamsFilters)
  const { data, isLoading } = transportationApi.useGetListTransportationsQuery(paramsFilters)
  const [signTransportationId, setSignTransportationId] = useState<null | number>(null)
  const [deleteTransportation, { isLoading: isLoadingDelete }] = transportationApi.useDeleteTransportationMutation()

  const onCell = useCallback((record: TransportationType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/transportation/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<TransportationType>[]>(() => [
    {
      title: t('transportation.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => ((index + 1) + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('transportation.columns.date'),
      dataIndex: 'date',
      key: 'date',
      onCell,
    },
    {
      title: t('transportation.columns.cargo'),
      dataIndex: 'cargo',
      key: 'cargo',
      render: (cargo) => JSON.parse(cargo || '[]').map(({ name }: { name: string }) => name).join(', '),
      onCell,
    },
    {
      title: t('transportation.columns.from'),
      dataIndex: 'from',
      key: 'from',
      render: (_, record) => record.from?.name,
      onCell,
    },
    {
      title: t('transportation.columns.to'),
      dataIndex: 'to',
      key: 'to',
      render: (_, record) => record.to?.name,
      onCell,
    },
    {
      title: t('transportation.columns.route'),
      dataIndex: 'address_from',
      key: 'address_from',
      align: 'center',
      render: (address_from, record) => (
        <>
          {address_from}
          <br />
          -
          <br />
          {record.address_to}
        </>
      ),
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <div className={style.actions}>
          {!record.signature && (
            <button
              type="button"
              className={style.actions_item}
              onClick={() => { setSignTransportationId(record.id) }}
            >
              <AlibabaOutlined />
            </button>
          )}
          <AllowedTo roles={[UserRoles.Admin, UserRoles.Driver]}>
            <a
              // eslint-disable-next-line max-len
              href={`${process.env.REACT_APP_API_URL}/api/transportations/generate/${record.id}?access_token=${localStorage.getItem('token')}`}
              target="_blank"
              rel="noreferrer"
              className={style.actions_item}
            >
              <FileTextOutlined />
            </a>
          </AllowedTo>
          <AllowedTo roles={[UserRoles.Admin]}>
            <Popconfirm
              placement="left"
              title={t('popconfirm.deleteTitle')}
              onConfirm={() => { deleteTransportation(record.id) }}
              okText={t('popconfirm.okText')}
              cancelText={t('popconfirm.cancelText')}
              disabled={isLoadingDelete}
            >
              <button
                type="button"
                className={cn(style.actions_item, style.actions_item__delete)}
                disabled={isLoadingDelete}
              >
                <DeleteOutlined />
              </button>
            </Popconfirm>
          </AllowedTo>
        </div>
      ),
    },
  ], [data?.meta.pageSize, deleteTransportation, isLoadingDelete, onCell, paramsFilters.page, t])

  const TransportationsComponents = useMemo(() => (
    <TransportationSign transportationId={signTransportationId} onClose={() => setSignTransportationId(null)} />
  ), [signTransportationId])

  return {
    paramsFilters,
    setParamsFilters,
    data,
    isLoading,
    columns,
    TransportationsComponents,
  }
}
