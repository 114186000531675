import { useEffect, useMemo, useState } from 'react'
import { calculateTimeUntil } from '@src/lib/calculateTimeUntil'
import { tasksApi } from '@src/store/services/tasks-service'

export const useWorkTime = (taskId?: number) => {
  const { data: lastWorkTime } = tasksApi.useLastWorkTimelogQuery(undefined, {
    pollingInterval: 30000,
  })
  const [result, setResult] = useState('')

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>

    if (!lastWorkTime || !lastWorkTime?.started || (taskId && taskId !== lastWorkTime?.task_id)) {
      setResult('')
    } else if (lastWorkTime.ended) {
      setResult(calculateTimeUntil(lastWorkTime.started, lastWorkTime.ended))
    } else {
      intervalId = setInterval(() => {
        setResult(calculateTimeUntil(lastWorkTime.started))
      }, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [lastWorkTime, taskId])

  const isWork = useMemo(() => {
    if (taskId && taskId !== lastWorkTime?.task_id) {
      return false
    }

    return !!(lastWorkTime?.started && !lastWorkTime?.ended)
  }, [lastWorkTime, taskId])

  return {
    time: result,
    isWork,
    workId: lastWorkTime?.id,
  }
}
