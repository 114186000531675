import { TransportationCompanyType, TransportationType } from '@src/types/transportation'

export enum TransportationBillStatus {
  notProcessed = 1,
  processed = 2,
  archived = 3,
}

export type TransportationBillType = {
  bill?: any
  bill_number?: any
  created_at: string
  id: number
  isCredit: number
  pay_by?: any
  date_closed?: any
  status: TransportationBillStatus
  transportation: TransportationType
  transportation_id: number
  updated_at: string
  company_id: number
  company?: TransportationCompanyType
  isSentAccountant: boolean
  isSentCustomer: boolean
  sum?: string
}
