import { ReactNode, useMemo, useState } from 'react'
import { Card, DatePicker, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { StatisticsRent } from '@src/components/Statistics/Rent/Rent'
import { StatisticsTransportation } from '@src/components/Statistics/Transportation/Transportation'

export const Statistics = () => {
  const { t } = useTranslation()
  const [dates, setDates] = useState<[moment.Moment, moment.Moment]>([
    moment().startOf('month').add(-2, 'month'), moment().add(1, 'month').startOf('month'),
  ])

  const contentList: Record<string, ReactNode> = {
    rent: <StatisticsRent dates={dates} />,
    transportation: <StatisticsTransportation dates={dates} />,
    service: <p>content2</p>,
  }

  const tabList = useMemo(() => [
    {
      key: 'rent',
      tab: t('statistics.tabs.rent'),
    },
    {
      key: 'transportation',
      tab: t('statistics.tabs.transportation'),
    },
    {
      key: 'service',
      tab: t('statistics.tabs.service'),
      disabled: true,
    },
  ], [t])

  const [activeTabKey, setActiveTabKey] = useState<string>(tabList[0].key)

  return (
    <div>
      <Card
        title={t('statistics.title')}
        style={{ width: '100%' }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={setActiveTabKey}
        tabProps={{
          size: 'middle',
        }}
      >
        <div>
          <DatePicker.MonthPicker
            value={dates[0] as any}
            onChange={(value: any) => setDates((prevState) => [value, prevState[1]])}
            allowClear={false}
          />
          <DatePicker.MonthPicker
            value={dates[1] as any}
            onChange={(value: any) => setDates((prevState) => [prevState[0], value])}
            allowClear={false}
          />
        </div>
        <Divider />
        {contentList[activeTabKey]}
      </Card>
    </div>
  )
}
