import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import useQuery from '@src/hooks/useQuery'
import { addQuery } from '@src/lib/route'
import { ColumnProps } from 'antd/lib/table'
import { Button, Card, Col, DatePicker, Divider, Popconfirm, Row, Table } from 'antd'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import cn from 'classnames'
import { monthlyPaymentsApi } from '@src/store/services/mounthly-paymenys-service'
import { MonthlyPaymentsType } from '@src/types/monthlyPayments'
import moment from 'moment/moment'
import { statisticsApi } from '@src/store/services/statistics-service'
import { Column, DualAxes, Pie } from '@ant-design/charts'
import { configColumn, configDualAxes, configPie } from '@src/components/Statistics/config'
import { Preloader } from '@src/components/Preloader/Preloader'
import { isSelectionText } from '@src/lib/utils'
import style from './monthlyPayments.module.scss'

type MonthlyPaymentsActionsProps = {
  record: MonthlyPaymentsType
}

const MonthlyPaymentsActions: FC<MonthlyPaymentsActionsProps> = ({ record }) => {
  const { t } = useTranslation()
  const [remove, { isLoading: isLoadingRemove }] = monthlyPaymentsApi.useRemoveMutation()

  return (
    <div className={style.actions}>
      <Popconfirm
        placement="left"
        title={t('popconfirm.deleteTitle')}
        onConfirm={() => {
          remove(record.id)
        }}
        okText={t('popconfirm.okText')}
        cancelText={t('popconfirm.cancelText')}
        disabled={isLoadingRemove}
      >
        <button
          type="button"
          className={cn(style.actions_item, style.actions_item__delete)}
          disabled={isLoadingRemove}
        >
          <DeleteOutlined />
        </button>
      </Popconfirm>
    </div>
  )
}

export const MonthlyPayments = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const pageQuery = useQuery('page')
  const filtersQuery = useQuery('filters')
  const [paramsFilters, setParamsFilters] = useState({
    page: pageQuery ? +pageQuery : 1,
    filters: filtersQuery ? JSON.parse(filtersQuery) : undefined,
  })
  const [dates, setDates] = useState<[moment.Moment, moment.Moment]>([
    moment().startOf('month').add(-2, 'month'), moment().add(1, 'month').startOf('month'),
  ])
  const { data, isLoading } = monthlyPaymentsApi.useGetListQuery({
    ...paramsFilters, filters: paramsFilters.filters && JSON.stringify(paramsFilters.filters),
  })
  const { data: statistics, isLoading: isLoadingStatistics } = statisticsApi.useGetTransportationQuery({
    from: dates[0].format('YYYY-MM-DD'), to: dates[1].format('YYYY-MM-DD'),
  })

  useEffect(() => {
    history.push({
      search: addQuery({ ...paramsFilters, filters: JSON.stringify(paramsFilters.filters) }),
    })
  }, [paramsFilters, history])

  const onCell = useCallback((record: MonthlyPaymentsType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/monthly-payments/edit/${record.id}`)
      }
    },
  }), [history])

  const columns = useMemo<ColumnProps<MonthlyPaymentsType>[]>(() => [
    {
      title: t('monthlyPayments.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (_, record, index) => (index + 1 + ((paramsFilters.page - 1) * (data?.meta.pageSize || 0))),
      onCell,
    },
    {
      title: t('monthlyPayments.columns.total'),
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => `${Object.entries(record).reduce((accumulator, [key, value]) => ((typeof value === 'number' && key !== 'id') ? accumulator + value : accumulator), 0)}€`,
      onCell,
    },
    {
      title: t('monthlyPayments.columns.period'),
      dataIndex: 'period',
      key: 'period',
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (_, record) => (
        <MonthlyPaymentsActions record={record} />
      ),
    },
  ], [data?.meta.pageSize, onCell, paramsFilters.page, t])

  const handleTableChange = useCallback((
    pagination: TablePaginationConfig,
  ) => {
    setParamsFilters((prevState: any) => ({
      ...prevState,
      page: pagination.current || 1,
    }))
  }, [])

  return (
    <div>
      <Card
        title={t('monthlyPayments.title')}
        extra={(
          <NavLink to={{
            pathname: '/monthly-payments/create',
            state: { backUrl: location.pathname + location.search },
          }}
          >
            <Button type="primary" icon={<PlusOutlined />} size="large">
              {t('monthlyPayments.create')}
            </Button>
          </NavLink>
)}
      >
        <div>
          <DatePicker.MonthPicker
            value={dates[0] as any}
            onChange={(value: any) => setDates((prevState) => [value, prevState[1]])}
            allowClear={false}
          />
          <DatePicker.MonthPicker
            value={dates[1] as any}
            onChange={(value: any) => setDates((prevState) => [prevState[0], value])}
            allowClear={false}
          />
        </div>
        <Divider />
        <Row gutter={[24, 24]}>
          <Col xl={24} xxl={12}>
            <Table<MonthlyPaymentsType>
              dataSource={data?.data}
              columns={columns}
              loading={isLoading}
              rowKey="id"
              onChange={handleTableChange}
              className={style.table}
              scroll={{ x: 'max-content' }}
              pagination={{
                total: data?.meta.totalCount,
                current: data?.meta.currentPage,
                pageSize: data?.meta.pageSize,
                showSizeChanger: false,
              }}
            />
          </Col>
          <Col xl={24} xxl={12}>
            <h3>{t('statistics.companyIncome')}</h3>
            <Preloader loading={isLoadingStatistics}>
              <Pie {...configPie} data={statistics?.pieChartCompanies || []} />
            </Preloader>
          </Col>
          <Col xl={24} xxl={12}>
            <h3>{t('statistics.profitSpendings')}</h3>
            <Preloader loading={isLoadingStatistics}>
              <DualAxes
                {...configDualAxes}
                data={statistics?.dualLineProfitSpendings ? [statistics?.dualLineProfitSpendings, statistics?.dualLineProfitSpendings] : []}
              />
            </Preloader>
          </Col>
          <Col xl={24} xxl={12}>
            <h3>{t('statistics.selectedMonth')}</h3>
            <Preloader loading={isLoadingStatistics}>
              <Column
                {...configColumn}
                xField="month"
                data={statistics?.lineChartSelectedMonth || []}
                seriesField="type"
                isGroup
              />
            </Preloader>
          </Col>
        </Row>
      </Card>
    </div>
  )
}
