import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetItemsType } from '@src/api/api'
import { User, UsersShort } from '@src/types/users'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Users'],
  endpoints: (build) => ({
    getListUsers: build.query<GetItemsType<User>, { page: number }>({
      query: (params) => ({
        url: '/users',
        params: {
          page: params.page,
        },
      }),
      providesTags: () => ['Users'],
    }),
    getOneUser: build.query<User, number>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      providesTags: () => ['Users'],
    }),
    createUser: build.mutation<User, any>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    editUser: build.mutation<User, any>({
      query: ({ id, ...body }) => ({
        url: `/users/${id}?_method=PUT`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUser: build.mutation<void, number>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    getDriversForSelect: build.query<UsersShort[], void>({
      query: () => ({
        url: '/drivers/short',
      }),
      providesTags: () => ['Users'],
    }),
    getWorkersForSelect: build.query<UsersShort[], void>({
      query: () => ({
        url: '/workers/short',
      }),
      providesTags: () => ['Users'],
    }),
    getUsersForSelect: build.query<UsersShort[], void | number>({
      query: (role) => ({
        url: '/users/short',
        params: {
          'filters[role][$eq]': role,
        },
      }),
      providesTags: () => ['Users'],
    }),
  }),
})
