import { FC, useCallback, useMemo } from 'react'
import { Button, Col, Drawer, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import style from '@src/Pages/CustomerCreate/customerCreate.module.scss'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { customerWorkersApi } from '@src/store/services/customerWorkers-service'

type CustomerWorkerCreateProps = {
  isOpen: boolean
  onClose: () => void
  companyId: number
  editId: number | null
}

export const CustomerWorkerCreate: FC<CustomerWorkerCreateProps> = ({ isOpen, onClose, companyId, editId }) => {
  const { t } = useTranslation()
  const [create, { isLoading: isLoadingCreate }] = customerWorkersApi.useCreateMutation()
  const [update, { isLoading: isLoadingUpdate }] = customerWorkersApi.useUpdateMutation()
  const { data } = customerWorkersApi.useGetOneQuery(editId || 0, { skip: !editId })

  const validationSchema = useMemo(() => (
    Yup.object().shape({
      name: Yup.string().required(t('form.errors.required') ?? ''),
      email: Yup.string().email().required(t('form.errors.required') ?? ''),
      phone: Yup.string().required(t('form.errors.required') ?? ''),
      position: Yup.string().required(t('form.errors.required') ?? ''),
    })
  ), [t])

  const initialValues = useMemo(() => ({
    name: data?.name || '',
    email: data?.email || '',
    phone: data?.phone || '',
    position: data?.position || '',
  }), [data])

  const onSubmit = useCallback(async (values: any) => {
    let result
    if (editId) {
      result = await update({ ...values, company_id: companyId, id: editId })
    } else {
      result = await create({ ...values, company_id: companyId })
    }

    if ('data' in result) {
      onClose()
    }
  }, [create, companyId, editId, onClose, update])

  return (
    <Drawer
      title={t('customerWorkersCreate.title')}
      open={isOpen}
      onClose={onClose}
      width={1000}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {() => (
          <Form className={style.form}>
            <Row gutter={[20, 0]}>
              <Col xs={24} md={12}>
                <FieldFormik name="name" placeholder={t('customerWorkersCreate.name') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="email" placeholder={t('customerWorkersCreate.email') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="phone" placeholder={t('customerWorkersCreate.phone') ?? ''} />
              </Col>
              <Col xs={24} md={12}>
                <FieldFormik name="position" placeholder={t('customerWorkersCreate.position') ?? ''} />
              </Col>
            </Row>

            <Button htmlType="submit" type="primary" loading={isLoadingCreate || isLoadingUpdate}>
              {t('customerWorkersCreate.save') || ''}
            </Button>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}
