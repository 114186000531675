import { useEffect } from 'react'

export const usePrintableBlock = (selector: string) => {
  useEffect(() => {
    const handleBeforePrint = () => {
      document.querySelectorAll('body *').forEach((el) => {
        el.classList.add('hide-for-print')
      })
      document.querySelectorAll(`${selector}, ${selector} *`).forEach((el) => {
        el.classList.remove('hide-for-print')
      })
      let parent = document.querySelector(selector)!.parentElement
      while (parent && parent !== document.body) {
        parent.classList.remove('hide-for-print')
        parent = parent.parentElement
      }
    }

    const handleAfterPrint = () => {
      document.querySelectorAll('.hide-for-print').forEach((el) => {
        el.classList.remove('hide-for-print')
      })
    }

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [selector])
}
