import { Company } from '@src/types/company'

export type CarImages = {
  [key: string]: Array<{
    id: number
    image: string
    thumb: string
    order: number
  }>
}

export enum CarStatus {
  Free = 0,
  Leased = 1,
  Repair = 2,
  Sold = 3
}

export type Car = {
  id: number
  make: string
  model: string
  year: string
  serial_number: string
  engine_hours: string
  comment: string
  key: number
  status: CarStatus
  images: CarImages
  owner_id: number
  created_at: number
  from_service: boolean
  paid_once: boolean
  owner: Company
  car_category_id?: number
  category: {
    name: string
}
}

export type CarForSelect = {
  id: number
  name: string
  engine_hours: string
  owner_id: number
}

export type StoreFileRequest = {
  body: any
  car_id: number
  agreement_id?: number
  status?: number
}
