import { useFormikContext } from 'formik'
import { usePrintableBlock } from '@src/hooks/usePrintableBlock'
import { Button } from 'antd'
import { PrinterOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { agreementsApi } from '@src/store/services/agreements-service'
import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import n2words from 'n2words'
import { customRound } from '@src/lib/converter'
import { AgreementTermTypeEnum } from '@src/types/agreements'

export const AgreementInvoicesDocument = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<any>()
  const { data: agreementInvoice } = agreementsApi.useGetOneAgreementInvoiceQuery(values.id)
  usePrintableBlock('#printable-block')
  const [leaseTerm, setLeaseTerm] = useState(0)
  const [termType, setTermType] = useState('')

  const netto = useMemo(() => (+values.price_month || 0).toFixed(2), [values])
  const pvm = useMemo(() => ((+values.price_month || 0) * 0.21).toFixed(2), [values])
  const total = useMemo(() => (+pvm + +netto).toFixed(2), [netto, pvm])

  const startDate = moment(values.date_start)
  const endDate = moment(values.date_end)
  const invoicePeriodDays = startDate.diff(endDate, 'days') + 1

  useEffect(() => {
    if (agreementInvoice) {
      setTermType(agreementInvoice.agreement.term_type)

      if (agreementInvoice?.agreement.term_type === AgreementTermTypeEnum.Months) {
        const daysInMonth = endDate.clone().endOf('month').date()
        const isFullMonth = invoicePeriodDays === daysInMonth
        if (isFullMonth) {
          setTermType(AgreementTermTypeEnum.Months)
        } else {
          setTermType(AgreementTermTypeEnum.Days)
        }

        let newLeaseTerm = 0
        if (isFullMonth) {
          newLeaseTerm = 1
        } else {
          const currentDay = startDate.clone()
          while (currentDay <= endDate) {
            if (currentDay.isBusinessDay()) {
              newLeaseTerm += 1
            }
            currentDay.add(1, 'days')
          }
        }
        setLeaseTerm(newLeaseTerm)
      } else if (agreementInvoice?.agreement.term_type === AgreementTermTypeEnum.Hours) {
        setTermType(AgreementTermTypeEnum.Days)
        let newLeaseTerm = 0
        const currentDay = startDate.clone()
        while (currentDay <= endDate) {
          if (currentDay.isBusinessDay()) {
            newLeaseTerm += 1
          }
          currentDay.add(1, 'days')
        }
        setLeaseTerm(newLeaseTerm)
      } else if (agreementInvoice?.agreement.term_type === AgreementTermTypeEnum.Days) {
        let newLeaseTerm = 0
        const currentDay = startDate.clone()
        while (currentDay <= endDate) {
          if (currentDay.isBusinessDay()) {
            newLeaseTerm += 1
          }
          currentDay.add(1, 'days')
        }
        setLeaseTerm(newLeaseTerm)
      }
    }
  }, [agreementInvoice, endDate, invoicePeriodDays, startDate])

  return (
    <>
      <div className="agreement_invoices_bills_document" id="printable-block">
        <p className="font-bold" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
          {!!values.isCredit && 'KREDITINĖ '}
          PVM SĄSKAITA-FAKTŪRA
        </p>
        <p className="text" style={{ textAlign: 'center' }}>
          Serija SEN Nr.
          {' '}
          {values.bill_number || `${moment(agreementInvoice?.agreement.created_at).format('YYYY-MM-DD')} / ${agreementInvoice?.agreement.id}`}
        </p>
        <table style={{ fontSize: 13, tableLayout: 'fixed', width: '100%' }}>
          <tr>
            <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
              Pardavėjas
              {' '}
              <br />
              <strong>{agreementInvoice?.agreement.owner.name}</strong>
              {' '}
              <br />
              {agreementInvoice?.agreement.owner.address}
              {' '}
              <br />
              Įmonės kodas
              {' '}
              {agreementInvoice?.agreement.owner.company_code}
              {' '}
              <br />
              PVM mok.kodas
              {' '}
              {agreementInvoice?.agreement.owner.vat_number}
              {' '}
              <br />
              A/s
              {' '}
              {agreementInvoice?.agreement.owner.account_number}
            </td>
            <td valign="top" style={{ width: '50%', minWidth: '50%', verticalAlign: 'top' }}>
              Pirkėjas
              {' '}
              <br />
              <strong>{agreementInvoice?.agreement.customer.name}</strong>
              {' '}
              <br />
              {agreementInvoice?.agreement.customer.address}
              {' '}
              <br />
              Įmonės kodas
              {' '}
              {agreementInvoice?.agreement.customer.company_code}
              {' '}
              <br />
              PVM mok.kodas
              {' '}
              {agreementInvoice?.agreement.customer.vat_number}
            </td>
          </tr>
        </table>
        <p className="text" style={{ textAlign: 'center' }}>
          Data:
          {' '}
          {moment(values.date_created).format('YYYY-MM-DD')}
        </p>
        <p className="text">
          Apmokėti iki:
          {' '}
          {values.pay_by ? moment(values.pay_by).format('YYYY-MM-DD') : moment(agreementInvoice?.agreement.date_end).add(12, 'day').format('YYYY-MM-DD')}
        </p>
        <table className="image-table" style={{ width: '100%', fontSize: 13 }}>
          <tr>
            <td className="image-row" style={{ width: '5%', fontWeight: 700, textAlign: 'center' }}>Nr.</td>
            <td className="image-row" style={{ width: '33.5%', fontWeight: 700 }}>Pavadinimas</td>
            <td className="image-row" style={{ width: '15%', fontWeight: 700, textAlign: 'center' }}>Mato vnt.</td>
            <td className="image-row" style={{ width: '10%', fontWeight: 700, textAlign: 'center' }}>Kiekis</td>
            <td className="image-row" style={{ width: '17.5%', fontWeight: 700, textAlign: 'center' }}>Kaina EUR</td>
            <td className="image-row" style={{ width: '19%', fontWeight: 700, textAlign: 'center' }}>Suma EUR</td>
          </tr>
          <tr>
            <td className="image-row" style={{ width: '5%', fontWeight: 400, textAlign: 'center' }}>1.</td>
            <td className="image-row" style={{ width: '33.5%', fontWeight: 400 }}>
              Nuoma
              {' '}
              {agreementInvoice?.agreement.car.make}
              {' '}
              {agreementInvoice?.agreement.car.model}
              <br />
              {agreementInvoice?.agreement.car.serial_number}
              <br />
              {values.text && (
                <>
                  {values.text}
                  <br />
                </>
              )}
              {moment(values.date_start).format('YYYY-MM-DD')}
              {' - '}
              {moment(values.date_end).format('YYYY-MM-DD')}
            </td>
            <td className="image-row" style={{ width: '15%', fontWeight: 400, textAlign: 'center' }}>
              {termType === AgreementTermTypeEnum.Days && 'Dieną'}
              {termType === AgreementTermTypeEnum.Hours && 'Valandą'}
              {termType === AgreementTermTypeEnum.Months && 'Menuo'}
            </td>
            <td className="image-row" style={{ width: '10%', fontWeight: 400, textAlign: 'center' }}>{leaseTerm}</td>
            <td className="image-row" style={{ width: '17.5%', fontWeight: 400, textAlign: 'center' }}>
              {(+(agreementInvoice?.agreement.minimum_rent || 0)).toFixed(2)}
            </td>
            <td className="image-row" style={{ width: '19%', fontWeight: 400, textAlign: 'center' }}>
              {!!values.isCredit && '-'}
              {netto}
            </td>
          </tr>
        </table>
        <table style={{ fontSize: 13, width: '100%' }}>
          <tr style={{ width: '100%' }}>
            <td style={{ width: '20%', color: '#ffffff' }}>.</td>
            <td style={{ width: '35%' }}>Iš viso:</td>
            <td style={{ width: '45%', textAlign: 'right' }}>
              {!!values.isCredit && '-'}
              {' '}
              {netto}
              {' '}
              EUR
            </td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ width: '20%', color: '#ffffff' }}>.</td>
            <td style={{ width: '35%' }}>PVM 21%</td>
            <td style={{ width: '45%', textAlign: 'right' }}>
              {!!values.isCredit && '-'}
              {' '}
              {pvm}
              {' '}
              EUR
            </td>
          </tr>
          <tr style={{ width: '100%' }}>
            <td style={{ width: '20%', color: '#ffffff' }}>.</td>
            <td style={{ width: '35%' }}>Bendra suma</td>
            <td style={{ width: '45%', textAlign: 'right' }}>
              {!!values.isCredit && '-'}
              {' '}
              {total}
              {' '}
              EUR
            </td>
          </tr>
        </table>
        <p className="text">
          Iš viso su PVM:
          {' '}
          {n2words(customRound(+total), { lang: 'lt' })}
          {' '}
          EUR,
          {' '}
          {total.split('.')[1]}
          ct.
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '17%' }}>Sąskaitą išrašė:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '83%', display: 'block', marginLeft: '17%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
        <p className="text">
          <span style={{ width: '100%', display: 'inline-block' }}>
            <span style={{ float: 'left', width: '20%' }}>Sąskaitą gavo:</span>
            <span style={{ borderBottom: 'solid 1px #000000', width: '80%', display: 'block', marginLeft: '20%' }}>
              <span style={{ color: '#ffffff', opacity: 0 }}>empty input</span>
            </span>
          </span>
          <br />
          <span className="text" style={{ textAlign: 'center', display: 'inline-block', width: '100%' }}>
            (vardas, pavardė, pareigos)
          </span>
        </p>
      </div>
      <div className="documentActions">
        <Button icon={<PrinterOutlined />} onClick={window.print}>{t('settings.print')}</Button>
      </div>
    </>
  )
}
