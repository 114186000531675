import { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Button, Col, Collapse, Drawer, Row, Table, Tag } from 'antd'
import { Form, Formik } from 'formik'
import { Preloader } from '@src/components/Preloader/Preloader'
import { FieldFormik } from '@src/components/FieldFormik/FieldFormik'
import { SelectFormik } from '@src/components/SelectFormik/SelectFormik'
import { CarsCreate } from '@src/Pages/CarsCreate/CarsCreate'
import { CustomerCreate } from '@src/Pages/CustomerCreate/CustomerCreate'
import { OwnerCreate } from '@src/Pages/OwnerCreate/OwnerCreate'
import { RadioFormik } from '@src/components/RadioFormik/RadioFormik'
import { DatePickerFormik } from '@src/components/DatePickerFormik/DatePickerFormik'
import { TimePickerFormik } from '@src/components/TimePickerFormik/TimePickerFormik'
import { UploadImages } from '@src/components/UploadImages/UploadImages'
import ImagesPreview, { Image } from '@src/components/ImagesPreview/ImagesPreview'
import { UploadFile } from 'antd/es/upload/interface'
import moment from 'moment/moment'
import { EyeOutlined, FileTextOutlined } from '@ant-design/icons'
import { CustomerInfo } from '@src/components/CustomerInfo/CustomerInfo'
import { AgreementInvoicesStatus, AgreementInvoicesType } from '@src/types/agreementInvoices'
import { ColumnProps } from 'antd/lib/table'
import { companiesApi } from '@src/store/services/companies-service'
import { CompanyType } from '@src/types/company'
import { agreementsApi } from '@src/store/services/agreements-service'
import { carsApi } from '@src/store/services/cars-service'
import { AgreementsCreateCard } from '@src/components/pages/agreements/AgreementsCreateCard'
import { isSelectionText } from '@src/lib/utils'
import style from './agreementsCreate.module.scss'

export const AgreementsCreate = () => {
  const formRef = useRef<any>()
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const history = useHistory()
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false)
  const [createType, setCreateType] = useState<'car' | 'customer' | 'owner' | null>(null)
  const { data: customersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Customer)
  const { data: ownersSelect } = companiesApi.useGetSelectCompaniesQuery(CompanyType.Owner)
  const [triggerCompanyRepresentative] = companiesApi.useLazyGetCompanyRepresentativeQuery()
  const { data: info, isLoading } = agreementsApi.useGetOneAgreementQuery(+params.id!, { skip: !params.id })
  const [create, { isLoading: isLoadingCreate }] = agreementsApi.useCreateAgreementMutation()
  const [edit, { isLoading: isLoadingEdit }] = agreementsApi.useEditAgreementMutation()
  const { data: carsSelect } = carsApi.useGetCarsForSelectQuery({ agreements_id: params.id ? +params.id : undefined })
  const [deleteFileAgreements] = agreementsApi.useDeleteFileMutation()
  const [storeImage, { isLoading: isLoadingStoreImage }] = carsApi.useStoreFileMutation()
  const [storeFileAttachment, { isLoading: isLoadingStoreFileAttachment }] = agreementsApi.useStoreFileAttachmentMutation()

  const updateRepresentative = useCallback(async (id:number, type: CompanyType) => {
    const result: any = await triggerCompanyRepresentative(id)

    if ('data' in result) {
      if (type === CompanyType.Owner) {
        formRef.current.setFieldValue('owner_representative', result.data.name)
        formRef.current.setFieldValue('owner_representative_position', result.data.position)
      } else {
        formRef.current.setFieldValue('customer_representative', result.data.name)
        formRef.current.setFieldValue('customer_representative_position', result.data.position)
      }
    }
  }, [triggerCompanyRepresentative])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        customer_representative: Yup.string().required(t('form.errors.required') ?? ''),
        customer_representative_position: Yup.string().required(t('form.errors.required') ?? ''),
        owner_representative: Yup.string().required(t('form.errors.required') ?? ''),
        owner_representative_position: Yup.string().required(t('form.errors.required') ?? ''),
        insurance: Yup.string().required(t('form.errors.required') ?? ''),
        minimum_rent: Yup.number().required(t('form.errors.required') ?? ''),
        payment_deadline: Yup.string().required(t('form.errors.required') ?? ''),
        lease_term: Yup.string().required(t('form.errors.required') ?? ''),
        max_engine_hours: Yup.string().required(t('form.errors.required') ?? ''),
        engine_hour_cost: Yup.string().required(t('form.errors.required') ?? ''),
        rent_place: Yup.string().required(t('form.errors.required') ?? ''),
        date_start: Yup.string().required(t('form.errors.required') ?? ''),
        car_id: Yup.string().required(t('form.errors.required') ?? ''),
        owner_id: Yup.string().required(t('form.errors.required') ?? ''),
        customer_id: Yup.string().required(t('form.errors.required') ?? ''),
        car_value: Yup.string().required(t('form.errors.required') ?? ''),
        engine_hours_start: Yup.string().required(t('form.errors.required') ?? ''),
      }),
    [t],
  )

  const initialValues = useMemo(
    () => ({
      car_id: info?.car_id ?? '',
      car_value: info?.car_value ?? '',
      owner_id: info?.owner_id ?? '',
      engine_hours_end: info?.engine_hours_end ?? '',
      engine_hours_start: info?.engine_hours_start ?? '',
      rent_place: info?.rent_place ?? '',
      date_start: info?.date_start ? moment(info?.date_start) : '',
      date_end: info?.date_end ? moment(info?.date_end) : '',
      date_created: info?.date_created ? moment(info?.date_created) : '',
      time_rent: info?.time_rent ? moment(info?.time_rent, 'HH:mm') : '',
      equipment: info?.equipment ? JSON.parse(info?.equipment) : ([] as any),
      services: info?.services ? JSON.parse(info?.services) : ([] as any),
      customer_id: info?.customer_id ?? '',
      customer_representative: info?.customer_representative ?? '',
      customer_representative_position: info?.customer_representative_position ?? '',
      owner_representative: info?.owner_representative ?? '',
      owner_representative_position: info?.owner_representative_position ?? '',
      insurance: info?.insurance ?? '',
      minimum_rent: info?.minimum_rent ?? '',
      payment_deadline: info?.payment_deadline ?? '',
      lease_term: info?.lease_term ?? '',
      max_engine_hours: info?.max_engine_hours ?? '',
      engine_hour_cost: info?.engine_hour_cost ?? '',
      status: info?.status ?? 0,
      is_tank_full: info?.is_tank_full ?? 0,
      term_type: info?.term_type ?? '',
      comment: info?.comment ?? '',
      comment_close: info?.comment_close ?? '',
      fileList: [] as UploadFile[],
      fileListAttachment: [],
      images: info?.images || {},
    }),
    [info],
  )

  const onSubmit = useCallback(
    async (values: any) => {
      const data = {
        ...values,
        date_start: values.date_start.format('YYYY-MM-DD'),
        date_end: values.date_end ? values.date_end.format('YYYY-MM-DD') : '',
        date_created: values.date_created ? values.date_created.format('YYYY-MM-DD') : '',
        time_rent: values.time_rent ? values.time_rent.format('HH:mm:ss') : '',
        equipment: JSON.stringify(values.equipment),
        services: JSON.stringify(values.services),
      }

      let result

      if (params.id) {
        result = await edit({
          id: +params.id,
          body: data,
        })
      } else {
        result = await create(data)
      }

      if ('data' in result) {
        if (values.fileList.length) {
          const filesPromise = data.fileList.map((item: any) => {
            const formData = new FormData()
            formData.append('image', item.originFileObj)

            return storeImage({
              body: formData,
              car_id: values.car_id,
              agreement_id: params.id ? +params.id : result!.data.id,
              status: values.status,
            })
          })
          await Promise.all(filesPromise)
        }

        if (data.fileListAttachment.length) {
          const filesPromise = data.fileListAttachment.map((item: any) => {
            const formData = new FormData()
            formData.append('attachment', item.originFileObj)

            return storeFileAttachment({
              body: formData,
              agreement_id: params.id ? +params.id : result!.data.id,
            })
          })
          await Promise.all(filesPromise)
        }

        history.goBack()
      }
    },
    [create, edit, history, params.id, storeFileAttachment, storeImage],
  )

  const onCloseCreate = useCallback(() => {
    setIsCreate(false)
    setCreateType(null)
  }, [])

  const onCloseInfo = useCallback(() => {
    setIsShowInfo(false)
  }, [])

  const onCell = useCallback((record: AgreementInvoicesType) => ({
    onClick: () => {
      if (!isSelectionText()) {
        history.push(`/agreement-invoices/edit/${record.id}`)
      }
    },
  }), [history])

  const columnsInvoices = useMemo<ColumnProps<AgreementInvoicesType>[]>(() => [
    {
      title: t('agreementInvoices.columns.index'),
      dataIndex: 'index',
      key: 'index',
      render: (i, record, index) => index + 1,
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateStart'),
      dataIndex: 'date_start',
      key: 'date_start',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.dateEnd'),
      dataIndex: 'date_end',
      key: 'date_end',
      onCell,
    },
    {
      title: t('agreementInvoices.columns.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        switch (status) {
          case AgreementInvoicesStatus.notProcessed:
            return <Tag color="blue">{t(`agreementInvoices.status.${AgreementInvoicesStatus.notProcessed}`)}</Tag>
          case AgreementInvoicesStatus.processed:
            return <Tag color="green">{t(`agreementInvoices.status.${AgreementInvoicesStatus.processed}`)}</Tag>
          case AgreementInvoicesStatus.archived:
            return <Tag color="red">{t(`agreementInvoices.status.${AgreementInvoicesStatus.archived}`)}</Tag>
          default:
            return ''
        }
      },
      onCell,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '100px',
      fixed: 'right',
      render: (actions, record) => (
        <div className={style.actions}>
          <a
            // eslint-disable-next-line max-len
            href={`${process.env.REACT_APP_API_URL}/api/agreements/generate-invoice/${record.id}?access_token=${localStorage.getItem('token')}`}
            target="_blank"
            rel="noreferrer"
            className={style.actions_item}
          >
            <FileTextOutlined />
          </a>
        </div>
      ),
    },
  ], [onCell, t])

  return (
    <div>
      <h1 className="page_title">{params.id ? t('agreementsCreate.titleEdit') : t('agreementsCreate.title')}</h1>
      <Preloader loading={isLoading}>
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Row gutter={[24, 24]}>
                <Col xl={24} xxl={12}>
                  <Form>
                    <Row gutter={[20, 0]}>
                      <Col span={24}>
                        <h2>{t('agreementsCreate.ownerInfoBlock')}</h2>
                      </Col>
                      <Col xs={24} md={10}>
                        <div style={{ display: 'flex' }}>
                          <SelectFormik
                            name="owner_id"
                            showSearch
                            placeholder={t('agreementsCreate.owner') ?? ''}
                            options={ownersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                            onChange={(value) => updateRepresentative(+value, CompanyType.Owner)}
                          />
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('owner')
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </Col>
                      <Col xs={24} md={7}>
                        <FieldFormik
                          name="owner_representative"
                          placeholder={t('agreementsCreate.ownerRepresentative') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <FieldFormik
                          name="owner_representative_position"
                          placeholder={t('agreementsCreate.ownerRepresentativePosition') ?? ''}
                        />
                      </Col>

                      <Col span={24}>
                        <h2>{t('agreementsCreate.customerInfoBlock')}</h2>
                      </Col>
                      <Col xs={24} md={10}>
                        <div style={{ display: 'flex' }}>
                          <SelectFormik
                            name="customer_id"
                            showSearch
                            placeholder={t('agreementsCreate.customer') ?? ''}
                            options={customersSelect?.map((item) => ({ label: item.name, value: item.id })) || []}
                            onChange={(value) => updateRepresentative(+value, CompanyType.Customer)}
                          />
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('customer')
                            }}
                          >
                            +
                          </Button>
                          <Button
                            icon={<EyeOutlined />}
                            size="large"
                            style={{ flexShrink: 0 }}
                            onClick={() => {
                              setIsShowInfo(true)
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={24} md={7}>
                        <FieldFormik
                          name="customer_representative"
                          placeholder={t('agreementsCreate.customerRepresentative') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={7}>
                        <FieldFormik
                          name="customer_representative_position"
                          placeholder={t('agreementsCreate.customerRepresentativePosition') ?? ''}
                        />
                      </Col>

                      <Col span={24}>
                        <h2>{t('agreementsCreate.carInfoBlock')}</h2>
                      </Col>
                      <Col xs={24} md={12}>
                        <div style={{ display: 'flex' }}>
                          <SelectFormik
                            width="calc(100% - 40px)"
                            name="car_id"
                            showSearch
                            onChange={(value, current) => {
                              if (current.ownerId) {
                                setFieldValue('owner_id', current.ownerId)
                                setFieldValue('engine_hours_start', current.engineHours)
                                updateRepresentative(+current.ownerId, CompanyType.Owner)
                              }
                            }}
                            placeholder={t('agreementsCreate.car') ?? ''}
                            options={carsSelect?.map((item) => ({
                              label: item.name, value: item.id, ownerId: item.owner_id, engineHours: item.engine_hours,
                            })) || []}
                          />
                          <Button
                            size="large"
                            onClick={() => {
                              setIsCreate(true)
                              setCreateType('car')
                            }}
                          >
                            +
                          </Button>
                        </div>
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="car_value" placeholder={t('agreementsCreate.carValue') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="engine_hours_start" placeholder={t('agreementsCreate.engineHoursStart') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="engine_hours_end" placeholder={t('agreementsCreate.engineHoursEnd') ?? ''} />
                      </Col>
                      {values.equipment.map((item: any, i: any) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={i}>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`equipment[${i}].name`}
                              placeholder={t('agreementsCreate.customEquipmentName') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`equipment[${i}].value`}
                              placeholder={t('agreementsCreate.customEquipmentValue') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={4}>
                            <Button
                              size="large"
                              onClick={() => {
                                setFieldValue('equipment', values.equipment.filter((equipment: any, filterI: number) => filterI !== i))
                              }}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Fragment>
                      ))}
                      <Col xs={24} md={12}>
                        <div style={{ marginBottom: 15 }}>
                          <span style={{ marginRight: 15 }}>{t('agreementsCreate.isTankFull')}</span>
                          <RadioFormik
                            options={[
                              { value: 1, label: t('popconfirm.okText') },
                              { value: 0, label: t('popconfirm.cancelText') },
                            ]}
                            name="is_tank_full"
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <Button
                          onClick={() => {
                            setFieldValue('equipment', [...values.equipment, { name: '', value: '' }])
                          }}
                        >
                          {t('agreementsCreate.addEquipment')}
                        </Button>
                      </Col>
                      <div style={{ display: 'flex', height: '24px', width: '100%' }} />
                      {values.services.map((item: any, i: any) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={i}>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`services[${i}].name`}
                              placeholder={t('agreementsCreate.customEquipmentName') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={10}>
                            <FieldFormik
                              name={`services[${i}].value`}
                              placeholder={t('agreementsCreate.customEquipmentValue') ?? ''}
                            />
                          </Col>
                          <Col xs={24} md={4}>
                            <Button
                              size="large"
                              onClick={() => {
                                setFieldValue('services', values.services.filter((service: any, filterI: number) => filterI !== i))
                              }}
                            >
                              Delete
                            </Button>
                          </Col>
                        </Fragment>
                      ))}

                      <Col span={24}>
                        <Button
                          onClick={() => {
                            setFieldValue('services', [...values.services, { name: '', value: '' }])
                          }}
                        >
                          {t('agreementsCreate.addServices')}
                        </Button>
                      </Col>

                      <Col span={24}>
                        <h2>{t('agreementsCreate.agreementInfoBlock')}</h2>
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="insurance" placeholder={t('agreementsCreate.insurance') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="payment_deadline" placeholder={t('agreementsCreate.paymentDeadline') ?? ''} />
                      </Col>
                      <Col xs={24} md={9}>
                        <FieldFormik
                          name="minimum_rent"
                          type="number"
                          placeholder={t('agreementsCreate.minimumRent') ?? ''}
                        />
                      </Col>

                      <Col xs={24} md={9}>
                        <FieldFormik
                          name="lease_term"
                          type="number"
                          placeholder={t('agreementsCreate.leaseTerm') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={6} style={{ marginBottom: 16 }}>
                        <RadioFormik
                          options={[
                            { value: 'd.', label: t('agreementsCreate.leaseTermD') },
                            { value: 'mėn', label: t('agreementsCreate.leaseTermM') },
                            { value: 'val', label: t('agreementsCreate.leaseTermH') },
                          ]}
                          name="term_type"
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik
                          name="max_engine_hours"
                          type="number"
                          placeholder={t('agreementsCreate.maxEngineHours') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik
                          name="engine_hour_cost"
                          type="number"
                          placeholder={t('agreementsCreate.engineHourCost') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <DatePickerFormik name="date_start" placeholder={t('agreementsCreate.dateStart') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <DatePickerFormik name="date_end" placeholder={t('agreementsCreate.dateEnd') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <FieldFormik name="rent_place" placeholder={t('agreementsCreate.rentPlace') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <TimePickerFormik name="time_rent" placeholder={t('agreementsCreate.timeRent') ?? ''} />
                      </Col>
                      <Col xs={24} md={12}>
                        <DatePickerFormik name="date_created" placeholder={t('agreementsCreate.dateCreated') ?? ''} />
                      </Col>
                      <Col xs={24} md={24}>
                        <FieldFormik
                          type="textarea"
                          name="comment"
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          placeholder={t('transportationBills.columns.comment') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={24}>
                        <FieldFormik
                          type="textarea"
                          name="comment_close"
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          placeholder={t('transportationBills.columns.commentClose') ?? ''}
                        />
                      </Col>
                      <Col xs={24} md={12}>
                        <div style={{ marginBottom: 15 }}>
                          <span style={{ marginRight: 15 }}>{t('agreementsCreate.status')}</span>
                          <RadioFormik
                            options={[
                              { value: 0, label: t('agreementsCreate.statusOpen') },
                              { value: 1, label: t('agreementsCreate.statusClosed') },
                            ]}
                            name="status"
                          />
                        </div>
                      </Col>
                    </Row>

                    <h3>{t('agreementsCreate.imagesTitle')}</h3>
                    <div className={style.upload}>
                      <UploadImages
                        fileList={values.fileList}
                        setFileList={(data) => {
                          setFieldValue('fileList', data)
                        }}
                      />
                    </div>

                    {info?.images && (
                    <Collapse accordion className={style.upload}>
                      {Object.keys(info.images).map((key) => (
                        <Collapse.Panel header={key} key={key}>
                          <ImagesPreview
                            images={info.images[key]}
                            groupKey={key}
                            onReorderImages={(data: Image[]) => {
                              setFieldValue('images', { ...info.images, [key]: data })
                            }}
                          />
                        </Collapse.Panel>
                      ))}
                    </Collapse>
                    )}

                    <h3>{t('agreementsCreate.filesTitle')}</h3>
                    <div className={style.upload}>
                      <UploadImages
                        fileList={values.fileListAttachment}
                        setFileList={(data) => {
                          setFieldValue('fileListAttachment', data)
                        }}
                      />
                    </div>
                    {info?.attachments && (
                    <Row>
                      <Col xs={24} md={12}>
                        <div className={style.upload}>
                          <UploadImages
                            isUploadButton={false}
                            fileList={info.attachments.map((el) => ({
                              url: `${process.env.REACT_APP_API_URL}/${el.file}`,
                              id: el.id,
                              name: el.filename,
                            }))}
                            onRemove={(data) => {
                              deleteFileAgreements(data.id)
                            }}
                            isPictureCard={false}
                          />
                        </div>
                      </Col>
                    </Row>
                    )}

                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoadingCreate || isLoadingEdit || isLoadingStoreImage || isLoadingStoreFileAttachment}
                    >
                      {params.id ? t('agreementsCreate.save') : t('agreementsCreate.submit')}
                    </Button>
                  </Form>
                </Col>
                <Col xl={24} xxl={12}>
                  <AgreementsCreateCard agreementId={params.id ? +params.id : undefined} />
                </Col>
              </Row>
            )}
          </Formik>
          {!!info?.invoices.length && (
            <Table<AgreementInvoicesType>
              dataSource={info.invoices}
              columns={columnsInvoices}
              className={style.table}
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          )}

        </>
      </Preloader>
      <Drawer
        title={
          (createType === 'car' && t('carCreate.title'))
          || (createType === 'customer' && t('customerCreate.title'))
          || (createType === 'owner' && t('ownerCreate.title'))
        }
        open={isCreate}
        onClose={onCloseCreate}
        width={1000}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {createType === 'car' && <CarsCreate isModal successCallbackModal={onCloseCreate} />}
        {createType === 'customer' && <CustomerCreate isModal successCallbackModal={onCloseCreate} />}
        {createType === 'owner' && <OwnerCreate isModal successCallbackModal={onCloseCreate} />}
      </Drawer>
      <CustomerInfo isOpen={isShowInfo} onClose={onCloseInfo} customerId={formRef.current?.values.customer_id} />
    </div>
  )
}
