import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetStatisticsRequest, RentStatisticsResponse, TransportationStatisticsResponse } from '@src/types/statistics'

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      headers.set('Accept', 'application/json, text/plain, */*')

      return headers
    },
  }),
  refetchOnMountOrArgChange: 60,
  tagTypes: ['Statistics'],
  endpoints: (build) => ({
    getRent: build.query<RentStatisticsResponse, GetStatisticsRequest>({
      query: (params) => ({
        url: '/statistics/rent',
        params,
      }),
    }),
    getTransportation: build.query<TransportationStatisticsResponse, GetStatisticsRequest>({
      query: (params) => ({
        url: '/statistics/transportations',
        params,
      }),
    }),
  }),
})
