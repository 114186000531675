import { FC } from 'react'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { Pie, Column } from '@ant-design/charts'
import { statisticsApi } from '@src/store/services/statistics-service'
import { Preloader } from '@src/components/Preloader/Preloader'
import { configColumn, configPie } from '@src/components/Statistics/config'

type StatisticsRentProps = {
  dates: [moment.Moment, moment.Moment]
}

export const StatisticsRent: FC<StatisticsRentProps> = ({ dates }) => {
  const { t } = useTranslation()
  const { data, isLoading } = statisticsApi.useGetRentQuery({
    from: dates[0].format('YYYY-MM-DD'), to: dates[1].format('YYYY-MM-DD'),
  })

  return (
    <div>
      <Preloader loading={isLoading}>
        <>
          <h3>{t('statistics.income')}</h3>
          <Column {...configColumn} xField="month" data={data?.line || []} />
          <h3>{t('statistics.carsIncome')}</h3>
          <Column {...configColumn} data={data?.carsPie || []} />
          <h3>{t('statistics.companyIncome')}</h3>
          <Pie {...configPie} data={data?.companyPie || []} />
        </>
      </Preloader>
    </div>
  )
}
