import { FC, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import cn from 'classnames'
import { useField, useFormikContext } from 'formik'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import style from './signatureFormik.module.scss'

type SignatureFormikProps = {
  name: string
  placeholder?: string
}

export const SignatureFormik: FC<SignatureFormikProps> = ({ name, placeholder }) => {
  const signatureRef = useRef<any>()
  const { t } = useTranslation()
  const [, meta] = useField(name)
  const { setFieldValue } = useFormikContext<any>()

  const clearSignature = () => {
    signatureRef.current.clear()
    setFieldValue(name, '')
  }

  return (
    <div className={style.signatureCanvasContainer}>
      <div>
        <div>{placeholder}</div>
        <SignatureCanvas
          penColor="#000"
          ref={signatureRef}
          onEnd={() => {
            setFieldValue(name, signatureRef.current.getTrimmedCanvas().toDataURL('image/png'))
          }}
          canvasProps={{
            width: 500,
            height: 200,
            className: cn(style.signatureCanvas, meta.error && meta.touched && style.signatureCanvasError),
          }}
        />
        {meta.error && meta.touched && (
        <div className={style.signatureError}>{meta.error}</div>
        )}
      </div>
      <Button type="dashed" onClick={clearSignature} style={{ marginTop: 10 }}>
        {t('transportationCreate.clear')}
      </Button>
    </div>
  )
}
