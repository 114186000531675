import React, { FC, ReactNode, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card } from 'antd'
import { AgreementsDocument } from '@src/components/pages/agreements/AgreementsDocument'
import { AgreementsPauses } from '@src/components/pages/agreements/pauses/AgreementsPauses'
import { PlusOutlined } from '@ant-design/icons'

type AgreementsCreateCardProps = {
  agreementId?: number
}

export const AgreementsCreateCard: FC<AgreementsCreateCardProps> = ({ agreementId }) => {
  const { t } = useTranslation()
  const [activeTabKey, setActiveTabKey] = useState<string>('document')
  const pausesRef = useRef<any>()

  const contentList: Record<string, ReactNode> = useMemo(() => ({
    document: (
      <AgreementsDocument />
    ),
    pause: (
      <AgreementsPauses ref={pausesRef} agreementId={agreementId!} />
    ),
  }), [agreementId])

  return (
    <Card
      activeTabKey={activeTabKey}
      onTabChange={setActiveTabKey}
      tabList={[
        {
          key: 'document',
          label: t('agreementsCreate.card.document'),
        },
        {
          key: 'pause',
          label: t('agreementsCreate.card.pause'),
          disabled: !agreementId,
        },
      ]}
      tabBarExtraContent={activeTabKey === 'pause' && (
        <Button type="primary" icon={<PlusOutlined />} onClick={() => pausesRef.current!.setIsOpenCreate(true)}>
          {t('pageTable.create')}
        </Button>
      )}
      tabProps={{
        size: 'middle',
      }}
    >
      {contentList[activeTabKey]}
    </Card>
  )
}
