import type { Image } from './ImagesPreview'

export function formatImages(originalArray: Image[], key: string) {
  return originalArray.map((el) => ({
    ...el,
    url: `${process.env.REACT_APP_API_URL}/${el.image || el.thumb}`,
    thumb: `${process.env.REACT_APP_API_URL}/${el.thumb || el.image}`,
    id: el.id,
    key,
  }))
}
